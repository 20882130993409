import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ValidationButton } from "../ValidationButton";
import { ShoppingCart } from "../ShoppingCart";
import { Opt, OptPdf } from "../OptionBox";
import UserProfile from "../UserProfile/UserProfile";
import { TopBar } from "../TopBar";
import { isMobile } from "react-device-detect";
import Calendar from "../Calendar/Calendar";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { useParams } from "react-router-dom";

import Carousel, {
  autoplayPlugin,
  arrowsPlugin,
  slidesToShowPlugin,
} from "@brainhubeu/react-carousel";
import Icon from "react-fa";
import "@brainhubeu/react-carousel/lib/style.css";
import {
  Button,
  Col,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Container,
} from "reactstrap";

import "react-dates/initialize";
import {
  DateRangePicker,
  SingleDatePicker,
  DayPickerRangeController,
} from "react-dates";
import {
  VERTICAL_ORIENTATION,
  HORIZONTAL_ORIENTATION,
} from "react-dates/constants";
import "react-dates/lib/css/_datepicker.css";
import "./react_dates_overrides.css";

require("dotenv").config();

moment.locale("fr");

export const CustomStay = () => {
  const [isActive, setIsActive] = useState(false);
  const [host, setHost] = useState([]);
  const [hostProducts, setHostProducts] = useState([]);
  const [cartBis, setCartBis] = useState();
  const [reset, setReset] = useState(false);
  const [user, setUser] = useState(JSON.parse(UserProfile.getUser()));
  const [roomNumber, setRoomNumber] = useState(1);
  const [typeCalendar, setTypeCalendar] = useState("");
  const [enter, setEnter] = useState(0);
  const [itemIn, setItemIn] = useState(false);
  const { shortcut } = useParams();
  const [drawerState, setDrawerState] = useState(false);
  const [reservation, setReservation] = useState("UKN");
  const room = [];
  const [numberRoomToDisplayed, setNumberRoomToDisplayed] = useState(() => {
    // value update on user number room selection. Default value = 1;
    if (roomNumber === 0) return 1;
    return roomNumber;
  });
  const [change, setChange] = useState();
  const [roomPossibility, setRoomPossibility] = useState([]);
  const [roomDisplay, setRoomDisplay] = useState([]);
  const [valueArrivals, setValueArrivals] = useState(null);
  const [valueDeparture, setValueDeparture] = useState(null);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [personalisate, setPersonalisateClicked] = useState(false); // state to know if the user as fill and validate stay infos

  const [modal, setModal] = useState(false);
  const [focusedInput, setFocusedInput] = useState();

  const toggle = () => setModal(!modal);

  const setStayDates = (arrival, departure) => {
    if (arrival !== null)
      setValueArrivals({
        date: moment(arrival).utc().toISOString(),
        format: moment(arrival).format("DD/MM/YY"),
      });
    if (departure !== null)
      setValueDeparture({
        date: moment(departure).utc().toISOString(),
        format: moment(departure).format("DD/MM/YY"),
      });
  };

  const createCartElem = (
    id,
    name,
    data,
    selectedItem,
    roomNu,
    quant,
    date
  ) => {
    // function to add the first item in the cart. So set up cart info.
    let tmp = cartBis;
    tmp.items.push({
      product: {
        label: data.label,
        description: data.description,
        type: data.type,
        // availabilityBeforeStay: 0,
        multiplePrice: data.multiplePrice,
        durationDay: data.durationDay,
        durationHour: data.durationHour,
        durationMin: data.durationMin,
        calendar: data.calendar,
        price: data.price,
        quantity: data.quantity,
        originalProductId: data._id,
        subCategoryName: data.subCategoryName,
        categoryName: name,
      },
      quantity: quant,
      selectedItem: selectedItem.selectedItems,
      date: date,
      roomNumber: roomNu,
    });
    setCartBis(tmp);
    setDrawerState(!drawerState);
  };

  const makeRequest = (method, url) => {
    return new Promise(function (resolve, reject) {
      let xhr = new XMLHttpRequest();
      xhr.open(method, url);
      xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) {
          resolve(xhr.response);
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText,
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText,
        });
      };
      xhr.send();
    });
  };

  const getHosterInformation = async () => {
    let result = await makeRequest(
      "GET",
      process.env.REACT_APP_API_URL + `/hoster?shortcut=${shortcut}`
    );
    setHost(JSON.parse(result));
  };

  const updateCartElem = (id, name, data, optSelect, roomNu, quant, date) => {
    // add and update cart items
    let tmp = cartBis;
    for (let i = 0; i !== tmp.items.length; i++) {
      if (
        tmp.items[i].product.originalProductId === id &&
        tmp.items[i].product.categoryName === name &&
        roomNu === tmp.items[i].roomNumber &&
        !date
      ) {
        tmp.items[i].quantity = quant;
        tmp.items[i].selectedItem = optSelect.selectedItems;
        tmp.items[i].categoryName = name;
        tmp.items[i].roomNumber = roomNu;
        setCartBis(tmp);
        setDrawerState(!drawerState);
        if (tmp.items.length > 0) setItemIn(true);
        else setItemIn(false);
        return;
      }
    }

    createCartElem(id, name, data, optSelect, roomNu, quant, date);
  };

  const deleteCartElem = (id, name, nb) => {
    // to delete cart item
    let tmp = cartBis;
    for (let i = 0; i !== tmp.items.length; i++) {
      if (
        tmp.items[i].product.originalProductId === id &&
        tmp.items[i].product.categoryName === name &&
        tmp.items[i].roomNumber === nb
      ) {
        tmp.items.splice(i, 1);
        setCartBis(tmp);
        setDrawerState(!drawerState);
        if (tmp.items.length === 0) {
          setItemIn(false);
        }
        return;
      }
    }
  };

  const isInCart = (id, roomNumb, name, label) => {
    // to know if an items is in the cart
    if (cartBis && cartBis.items) {
      if (cartBis.items.length > 0) {
        setItemIn(true);
      }
      for (let i = 0; i !== cartBis.items.length; i++) {
        if (roomNumb !== 0) {
          if (
            cartBis.items[i].roomNumber === roomNumb &&
            id === cartBis.items[i].product.originalProductId &&
            name === cartBis.items[i].product.categoryName
          )
            return true;
        } else {
          if (
            cartBis.items[i].product.originalProductId &&
            name === cartBis.items[i].product.categoryName &&
            cartBis.items[i].product.label === label
          )
            return true;
        }
      }
    }
    setItemIn(false);
    return false;
  };

  const isRoomInCart = (roomNumb) => {
    // same as precedent function but for room
    for (let j = 0; j !== cartBis.items.length; j++) {
      let services = cartBis.items[j].product;
      if (
        cartBis.items[j].roomNumber > 0 &&
        cartBis.items[j].roomNumber === roomNumb
      ) {
        return services.categoryName;
      }
    }
    return "";
  };

  const checkCart = () => {
    // check if there's a cart and stay info to authorize option selection
    if (
      (reservation.length > 0 &&
        roomNumber > 0 &&
        valueArrivals &&
        valueDeparture) ||
      (reservation.length > 0 &&
        roomNumber > 0 &&
        valueArrivals &&
        valueDeparture &&
        localStorage.getItem("cartId"))
    ) {
      setPersonalisateClicked(true);
      document.getElementById("stayValidation").firstChild.style.cursor =
        "not-allowed";
      document.getElementById("stayValidation").firstChild.disabled = true;
      document.getElementById("stayValidation").firstChild.style.opacity =
        "0.3";
      return;
    }
  };

  const checkAndValidateInfos = () => {
    // validate stay info and authorize option selection
    setReset(!reset);
    if (
      reservation.length > 0 &&
      roomNumber > 0 &&
      valueArrivals &&
      valueDeparture
    ) {
      UserProfile.setStayInfoNumber(reservation.toString());
      UserProfile.setStayInfoRoom(roomNumber.toString());
      setPersonalisateClicked(true);
      setNumberRoomToDisplayed(roomNumber);
      setChange(!change);
      setReset(!reset);
      setCartBis({
        items: [],
        hoster: host._id,
        beginDateOfStay: valueArrivals.date,
        endDateOfStay: valueDeparture.date,
        bookingRef: reservation,
        nbRoom: roomNumber,
      });
      document.getElementById("stayValidation").firstChild.style.cursor =
        "not-allowed";
      document.getElementById("stayValidation").firstChild.disabled = true;
      document.getElementById("stayValidation").firstChild.style.opacity =
        "0.3";

      const startHere = document.getElementById("start_here");
      if (startHere && isMobile) {
        document.getElementById("start_here").scrollIntoView({
          behavior: "smooth",
        });
      }
      return;
    }
  };

  const EnableButton = () => {
    // setPersonalisateClicked(false);
    document.getElementById("stayValidation").firstChild.style.cursor =
      "pointer";
    document.getElementById("stayValidation").firstChild.disabled = false;
    document.getElementById("stayValidation").firstChild.style.opacity = "1";
  };

  let deleteProductsRoom = (roomNumb) => {
    let list = cartBis;
    for (let i = 0; i !== list.items.length; i++) {
      if (list.items[i].roomNumber === roomNumb) {
        list.items.splice(i, 1);
        i--;
      }
    }
  };

  useEffect(() => {
    // load hoster info
    try {
      getHosterInformation();
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    // manage redirection from "Mes sejour" => "Getwelcome".
    const Id = UserProfile.getUserCartId();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const bookRef = urlParams.get("bookingRef");
    const arr = urlParams.get("arrival");
    const dep = urlParams.get("departure");
    const token = urlParams.get("token");
    const roomsNumber = urlParams.get("room");

    if (bookRef) {
      setCartBis({
        items: [],
        hoster: host._id,
        beginDateOfStay: arr,
        endDateOfStay: dep,
        bookingRef: bookRef,
        nbRoom: roomsNumber,
      });
      let profile;
      if (token) {
        profile = jwt_decode(token);
        UserProfile.setUser(
          JSON.stringify({
            firstName: profile.given_name,
            mail: profile.email,
            token,
          })
        );
        setUser({
          firstName: profile.given_name,
          mail: profile.email,
          token,
        });
      }
      setRoomNumber(parseInt(roomsNumber));
      setReservation(bookRef);
      setNumberRoomToDisplayed(parseInt(roomsNumber));
      setValueArrivals({ date: arr, format: getDate(arr) });
      setValueDeparture({ date: dep, format: getDate(dep) });
      // setPersonalisateClicked(true);
      checkAndValidateInfos();
      return;
    }

    if (Id !== null) {
      var xmlHttp = new XMLHttpRequest();
      xmlHttp.open(
        "GET",
        process.env.REACT_APP_API_URL + `/shopping-cart/${Id}`,
        false
      );
      xmlHttp.send(null);
      if (!xmlHttp.responseText) {
        setCartBis({
          items: [],
          hoster: host._id,
          beginDateOfStay: "2021-07-14T08:30:25.315Z",
          endDateOfStay: "2021-07-14T08:30:25.315Z",
          bookingRef: "",
          nbRoom: "",
        });
        return;
      } else if (JSON.parse(xmlHttp.responseText).hoster === host._id) {
        setCartBis(JSON.parse(xmlHttp.responseText));
        if (JSON.parse(xmlHttp.responseText).items.length > 0) setItemIn(true);
        setRoomNumber(parseInt(JSON.parse(xmlHttp.responseText).nbRoom));
        setReservation(JSON.parse(xmlHttp.responseText).bookingRef);
        setNumberRoomToDisplayed(
          parseInt(JSON.parse(xmlHttp.responseText).nbRoom)
        );
        setValueArrivals({
          date: JSON.parse(xmlHttp.responseText).beginDateOfStay,
          format: getDate(JSON.parse(xmlHttp.responseText).beginDateOfStay),
        });
        setValueDeparture({
          date: JSON.parse(xmlHttp.responseText).endDateOfStay,
          format: getDate(JSON.parse(xmlHttp.responseText).endDateOfStay),
        });
        setPersonalisateClicked(true);
        return;
      } else {
        setCartBis({
          items: [],
          hoster: host._id,
          beginDateOfStay: "2021-07-14T08:30:25.315Z",
          endDateOfStay: "2021-07-14T08:30:25.315Z",
          bookingRef: "",
          nbRoom: "",
        });
        return;
      }
    } else {
      setCartBis({
        items: [],
        hoster: host._id,
        beginDateOfStay: "2021-07-14T08:30:25.315Z",
        endDateOfStay: "2021-07-14T08:30:25.315Z",
        bookingRef: "",
        nbRoom: "",
      });
    }
  }, [host]);

  useEffect(() => {
    if (enter === 0) {
      if (valueArrivals) {
        let carttmp = cartBis;
        carttmp.beginDateOfStay = valueArrivals.date;
      }
      if (valueArrivals && valueDeparture) {
        let carttmp = cartBis;

        carttmp.beginDateOfStay = valueArrivals.date;
        carttmp.endDateOfStay = valueDeparture.date;

        setCartBis(carttmp);
      }
      if (
        reservation.length > 0 &&
        roomNumber > 0 &&
        valueArrivals &&
        valueDeparture
      ) {
        setPersonalisateClicked(true);
      }
      setEnter(enter + 1);
    }
  }, [valueArrivals && valueDeparture, reservation, roomNumber]);

  const fetchData = () => {
    // get host product
    var xmlHttp = new XMLHttpRequest();
    console.log(
      "URL",
      process.env.REACT_APP_API_URL + `/product?hosterId=${host._id}`
    );
    xmlHttp.open(
      "GET",
      process.env.REACT_APP_API_URL + `/product?hosterId=${host._id}`,
      false
    ); // false for synchronous request
    xmlHttp.send(null);
    setHostProducts(JSON.parse(xmlHttp.responseText));
  };
  useEffect(() => {}, [host]);

  const getRoom = () => {
    for (let i = 1; i !== 7; i++) {
      room.push(
        <Room
          onClick={() => {
            setIsActive(false);
            setRoomNumber(i);
          }}
        >
          {i}
          {i > 1 ? " chambres" : " chambre"} {i === 6 ? " et +" : null}
        </Room>
      );
    }
  };
  useEffect(() => {
    if (hostProducts && hostProducts.data) {
      hostProducts.data.map((itemData) => {
        if (itemData.type === "room") {
          let tmp = [];
          tmp = roomPossibility;
          tmp.push(itemData);
          setRoomPossibility(tmp);
        }
        return true;
      });
    }
  }, [hostProducts]);

  useEffect(() => {
    if (numberRoomToDisplayed > 0) {
      let tmp = [];
      for (let i = 0; i !== numberRoomToDisplayed; i++) {
        tmp.push(roomPossibility[0]);
      }
      setRoomDisplay(tmp);
    }
  }, [numberRoomToDisplayed, hostProducts]);

  const changeRoom = (type, index) => {
    // manage type of room selected
    let tmp = roomDisplay;
    let newType;
    for (let i = 0; i !== roomPossibility.length; i++) {
      if (roomPossibility[i].categoryLabel === type) newType = i;
    }
    tmp[index - 1] = roomPossibility[newType];
    setRoomDisplay(tmp);
  };

  useEffect(() => {
    fetchData();
  }, [host]);
  getRoom();
  const saveCart = () => {};
  const getDate = (date) => {
    const ordered = new Date(date).toLocaleDateString("fr", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
    return ordered;
  };

  const isFromStartOfStay = (optDateLimit) => {
    if (!valueArrivals) return true;
    var init = moment().add(optDateLimit, "h");
    var arr = moment(valueArrivals.date);

    if (arr.isBefore(init) === true) {
      return false;
    }
    if (arr.diff(init, "days") >= 0) {
      return true;
    } else {
      if (arr.diff(init, "days") >= 0) {
        return true;
      } else return false;
    }
  };

  const isFromEndOfStay = (optDateLimit) => {
    if (!valueDeparture) return true;
    var init = moment().add(optDateLimit, "h");
    var dep = moment(valueDeparture.date);
    var now = moment();

    if (init.format("DD/MM/YY") !== moment(now).format("DD/MM/YY")) {
      if (dep.diff(init, "days") >= 0) {
        return true;
      }
    } else {
      if (dep.diff(now, "days") >= 0) {
        return true;
      }
    }
    return false;
  };

  const compareAllDate = (subItem) => {
    for (let i = 0; i !== subItem.services.length; i++) {
      var DuringStay = subItem.services[i].showDuringStay;
      if (DuringStay === true) {
        if (isFromEndOfStay(subItem.services[i].deliveryTimeLimit) === true)
          return true;
      }
      if (DuringStay === false) {
        if (isFromStartOfStay(subItem.services[i].deliveryTimeLimit) === true)
          return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (valueArrivals && valueDeparture) {
      setDatePickerOpen(false);
      EnableButton();
    }
  }, [valueArrivals, valueDeparture]);

  const getCartSize = () => {
    if (cartBis && cartBis.items && cartBis.items.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (getCartSize()) {
      setItemIn(true);
    }
  }, [cartBis, change]);

  const TopCarousel = (props) => {
    if (props.hostdata !== undefined && !Array.isArray(props.hostdata)) {
      const result = props.hostdata.slideshow.map((x) => {
        return (
          <HostFigureCarousel
            key={x}
            style={{
              backgroundImage: `url(${x})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            {/* <HostImageCarousel alt={x} src={x} key={x} id={x} /> */}
          </HostFigureCarousel>
        );
      });
      return (
        <Carousel
          plugins={[
            "infinite",
            {
              resolve: autoplayPlugin,
              options: {
                interval: 9000,
              },
            },
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: 3,
              },
            },
          ]}
          animationSpeed={4000}
        >
          {result}
        </Carousel>
      );
    }
    return null;
  };
  const TopCarouselMobile = (props) => {
    if (props.hostdata !== undefined && !Array.isArray(props.hostdata)) {
      const result = props.hostdata.slideshow.map((x) => {
        return (
          <HostFigureCarouselMobile
            key={x}
            style={{
              backgroundImage: `url(${x})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            {/* <HostImageCarousel alt={x} src={x} key={x} id={x} /> */}
          </HostFigureCarouselMobile>
        );
      });
      return (
        <Carousel
          hostdata={host}
          className="Carousel"
          style={{ display: "flex", justifyContent: "space-evenly" }}
          plugins={[
            "infinite",
            {
              resolve: autoplayPlugin,
              options: {
                interval: 5000,
              },
            },
            {
              resolve: arrowsPlugin,
              options: {
                arrowLeft: (
                  <button>
                    <Icon name="angle-double-left" />
                  </button>
                ),
                arrowLeftDisabled: (
                  <button>
                    <Icon name="angle-left" />
                  </button>
                ),
                arrowRight: (
                  <button>
                    <Icon name="angle-double-right" />
                  </button>
                ),
                arrowRightDisabled: (
                  <button>
                    <Icon name="angle-right" />
                  </button>
                ),
                addArrowClickHandler: true,
              },
            },
          ]}
          animationSpeed={4000}
          style={{ width: "600px" }}
        >
          {result}
        </Carousel>
      );
    }
    return null;
  };

  // delete the cartId from localStorage if cart is empty
  useEffect(() => {
    if (cartBis && cartBis.items.length === 0) {
      localStorage.removeItem("cartId");
    }
  }, [change]);

  return (
    <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
      {isMobile && shortcut === "parisjadore" ? null : isMobile &&
        shortcut === "secret-de-paris" ? null : isMobile ? (
        <TopBar
          change={change}
          setChange={setChange}
          user={user ? user : null}
          getCartSize={getCartSize}
          deleteCartElem={deleteCartElem}
          cartBis={cartBis}
          itemIn={itemIn}
          drawerState={drawerState}
        />
      ) : null}
      <CustomContainer slug={shortcut}>
        {isMobile ? (
          <HostInfosContainerMobile className="container-fluid" slug={shortcut}>
            <HostContainer className="row">
              <HostInfos>
                <div className="col-7">
                  <HostName slug={shortcut}>{host.name}</HostName>
                  <HostAdress slug={shortcut}>
                    <svg
                      slug={shortcut}
                      xmlns="http://www.w3.org/2000/svg"
                      width="10px"
                      height="10px"
                      viewBox="0 0 9.516 11.408"
                      style={{ marginRight: "5px" }}
                    >
                      <g
                        id="Icon_feather-map-pin"
                        data-name="Icon feather-map-pin"
                        transform="translate(-4 -1)"
                      >
                        <path
                          id="Tracé_30"
                          data-name="Tracé 30"
                          d="M13.016,5.758c0,3.312-4.258,6.15-4.258,6.15S4.5,9.069,4.5,5.758a4.258,4.258,0,1,1,8.516,0Z"
                          fill="none"
                          stroke={
                            shortcut === "secret-de-paris"
                              ? "#6a4962"
                              : shortcut === "parisjadore"
                              ? "#d0b13d"
                              : "#16a5a3"
                          }
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                        <path
                          id="Tracé_31"
                          data-name="Tracé 31"
                          d="M16.339,11.919A1.419,1.419,0,1,1,14.919,10.5a1.419,1.419,0,0,1,1.419,1.419Z"
                          transform="translate(-6.161 -6.161)"
                          fill="none"
                          stroke={
                            shortcut === "secret-de-paris"
                              ? "#6a4962"
                              : shortcut === "parisjadore"
                              ? "#d0b13d"
                              : "#16a5a3"
                          }
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                      </g>
                    </svg>
                    {host.address}
                  </HostAdress>
                  <HostPhone slug={shortcut}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10px"
                      height="10px"
                      viewBox="0 0 9.766 9.784"
                      style={{ marginRight: "5px" }}
                    >
                      <path
                        id="Icon_feather-phone"
                        data-name="Icon feather-phone"
                        d="M11.933,9.575V10.9a.881.881,0,0,1-.961.881,8.722,8.722,0,0,1-3.8-1.353A8.594,8.594,0,0,1,4.524,7.782,8.722,8.722,0,0,1,3.171,3.961.881.881,0,0,1,4.048,3H5.371a.881.881,0,0,1,.881.758A5.659,5.659,0,0,0,6.561,5a.881.881,0,0,1-.2.93l-.56.56A7.051,7.051,0,0,0,8.447,9.13l.56-.56a.881.881,0,0,1,.93-.2,5.659,5.659,0,0,0,1.238.309A.881.881,0,0,1,11.933,9.575Z"
                        transform="translate(-2.668 -2.5)"
                        fill="none"
                        stroke={
                          shortcut === "secret-de-paris"
                            ? "#6a4962"
                            : shortcut === "parisjadore"
                            ? "#d0b13d"
                            : "#16a5a3"
                        }
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </svg>
                    {host.genericPhone}
                  </HostPhone>
                  <HostEmail slug={shortcut}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10px"
                      height="10px"
                      viewBox="0 0 10.158 8.012"
                      style={{ marginRight: "5px" }}
                    >
                      <g
                        id="Icon_feather-mail"
                        data-name="Icon feather-mail"
                        transform="translate(-2.304 -5.5)"
                      >
                        <path
                          id="Tracé_32"
                          data-name="Tracé 32"
                          d="M3.877,6h7.012a.879.879,0,0,1,.877.877v5.259a.879.879,0,0,1-.877.877H3.877A.879.879,0,0,1,3,12.136V6.877A.879.879,0,0,1,3.877,6Z"
                          fill="none"
                          stroke={
                            shortcut === "secret-de-paris"
                              ? "#6a4962"
                              : shortcut === "parisjadore"
                              ? "#d0b13d"
                              : "#16a5a3"
                          }
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                        <path
                          id="Tracé_33"
                          data-name="Tracé 33"
                          d="M11.765,9,7.383,12.068,3,9"
                          transform="translate(0 -2.123)"
                          fill="none"
                          stroke={
                            shortcut === "secret-de-paris"
                              ? "#6a4962"
                              : shortcut === "parisjadore"
                              ? "#d0b13d"
                              : "#16a5a3"
                          }
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                      </g>
                    </svg>
                    {host.genericEmail}
                  </HostEmail>
                  <HostWebsite slug={shortcut} href={host.website}>
                    Voir le site internet
                  </HostWebsite>
                  <HostReseaux>
                    {host.instagram ? (
                      <LinkA href={host.instagram}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14.278"
                          height="14.278"
                          viewBox="0 0 14.278 14.278"
                        >
                          <g
                            id="Icon_feather-instagram"
                            data-name="Icon feather-instagram"
                            transform="translate(-2.5 -2.5)"
                          >
                            <path
                              id="Tracé_34"
                              data-name="Tracé 34"
                              d="M6.319,3h6.639a3.319,3.319,0,0,1,3.319,3.319v6.639a3.319,3.319,0,0,1-3.319,3.319H6.319A3.319,3.319,0,0,1,3,12.958V6.319A3.319,3.319,0,0,1,6.319,3Z"
                              fill="none"
                              stroke={
                                shortcut === "secret-de-paris"
                                  ? "#6a4962"
                                  : shortcut === "parisjadore"
                                  ? "#d0b13d"
                                  : "#16a5a3"
                              }
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1"
                            />
                            <path
                              id="Tracé_35"
                              data-name="Tracé 35"
                              d="M17.347,14.2a2.656,2.656,0,1,1-2.237-2.237A2.656,2.656,0,0,1,17.347,14.2Z"
                              transform="translate(-5.053 -4.98)"
                              fill="none"
                              stroke={
                                shortcut === "secret-de-paris"
                                  ? "#6a4962"
                                  : shortcut === "parisjadore"
                                  ? "#d0b13d"
                                  : "#16a5a3"
                              }
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1"
                            />
                            <path
                              id="Tracé_36"
                              data-name="Tracé 36"
                              d="M26.25,9.75h0"
                              transform="translate(-12.96 -3.763)"
                              fill="none"
                              stroke={
                                shortcut === "secret-de-paris"
                                  ? "#6a4962"
                                  : shortcut === "parisjadore"
                                  ? "#d0b13d"
                                  : "#16a5a3"
                              }
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1"
                            />
                          </g>
                        </svg>
                      </LinkA>
                    ) : null}

                    {host.facebook ? (
                      <LinkA href={host.facebook}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8.303"
                          height="14.277"
                          viewBox="0 0 8.303 14.277"
                        >
                          <path
                            id="Icon_feather-facebook"
                            data-name="Icon feather-facebook"
                            d="M17.8,3H15.811a3.319,3.319,0,0,0-3.319,3.319V8.311H10.5v2.655h1.992v5.311h2.655V10.966h1.992L17.8,8.311H15.147V6.319a.664.664,0,0,1,.664-.664H17.8Z"
                            transform="translate(-10 -2.5)"
                            fill="none"
                            stroke={
                              shortcut === "secret-de-paris"
                                ? "#6a4962"
                                : shortcut === "parisjadore"
                                ? "#d0b13d"
                                : "#16a5a3"
                            }
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                          />
                        </svg>
                      </LinkA>
                    ) : null}

                    {host.twitter ? (
                      <LinkA href={host.twitter}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.278"
                          height="14.418"
                          viewBox="0 0 17.278 14.418"
                        >
                          <path
                            id="Icon_feather-twitter"
                            data-name="Icon feather-twitter"
                            d="M17.778,4.492a8.065,8.065,0,0,1-2.323,1.132,3.315,3.315,0,0,0-5.816,2.22v.74A7.888,7.888,0,0,1,2.98,5.232s-2.96,6.659,3.7,9.619A8.613,8.613,0,0,1,1.5,16.331c6.659,3.7,14.8,0,14.8-8.509a3.33,3.33,0,0,0-.059-.614A5.712,5.712,0,0,0,17.778,4.492Z"
                            transform="translate(-1 -3.931)"
                            fill="none"
                            stroke={
                              shortcut === "secret-de-paris"
                                ? "#6a4962"
                                : shortcut === "parisjadore"
                                ? "#d0b13d"
                                : "#16a5a3"
                            }
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                          />
                        </svg>
                      </LinkA>
                    ) : null}

                    {host.tripadvisor ? (
                      <LinkA href={host.tripadvisor}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.635"
                          height="9.855"
                          viewBox="0 0 16.635 9.855"
                        >
                          <path
                            id="Icon_simple-tripadvisor"
                            data-name="Icon simple-tripadvisor"
                            d="M16.1,10.552a5.415,5.415,0,0,1,.814-1.675H14.133A10.118,10.118,0,0,0,8.579,7.335,10.308,10.308,0,0,0,2.886,8.9H.279a5.343,5.343,0,0,1,.811,1.654,4.162,4.162,0,0,0,6.6,5.067l.884,1.328L9.471,15.6A4.058,4.058,0,0,0,11,16.808a4.165,4.165,0,0,0,5.647-5.23,4.035,4.035,0,0,0-.561-1.029Zm-2.2,5.595a3.3,3.3,0,0,1-3.922-1.3,3.174,3.174,0,0,1-.349-.673,3.329,3.329,0,1,1,4.282,1.968H13.9ZM7.2,14.876a3.324,3.324,0,1,1,.572-1.86,3.008,3.008,0,0,1-.033.332A3.331,3.331,0,0,1,7.2,14.887Zm-4.867-1.9a2.058,2.058,0,1,0,2.061-2.054,2.062,2.062,0,0,0-2.061,2.054Zm8.351,0a2.056,2.056,0,1,0,2.057-2.054,2.058,2.058,0,0,0-2.057,2.054h0Zm-7.639,0a1.347,1.347,0,1,1-.01,0Zm8.34,0a1.346,1.346,0,1,1-.01,0ZM8.576,8.063a9.721,9.721,0,0,1,4.035.805,4.168,4.168,0,0,0-1.321.25,4.191,4.191,0,0,0-2.714,3.6A4.175,4.175,0,0,0,4.5,8.849a10.26,10.26,0,0,1,4.046-.786Z"
                            transform="translate(-0.279 -7.335)"
                            fill={
                              shortcut === "secret-de-paris"
                                ? "#6a4962"
                                : shortcut === "parisjadore"
                                ? "#d0b13d"
                                : "#16a5a3"
                            }
                          />
                        </svg>
                      </LinkA>
                    ) : null}
                  </HostReseaux>
                </div>
                <div className="col-5" id="MainPhotoArea">
                  <HostFigureLogo>
                    <HostLogo src={host.mainPhoto} />
                  </HostFigureLogo>
                </div>
              </HostInfos>
              <HostInfos className="row">
                <HostDescription>{host.description}</HostDescription>
              </HostInfos>
            </HostContainer>
          </HostInfosContainerMobile>
        ) : null}
        {isMobile ? <TopCarouselMobile hostdata={host} /> : null}

        <SelectionContainer onLoad={checkCart}>
          <HostOnFlexibles slug={shortcut}>
            <TextMobile slug={shortcut}>
              {shortcut === "parisjadore"
                ? "Découvrez ci-dessous les cartes du Restaurant J'adore !"
                : shortcut === "secret-de-paris"
                ? "Découvrez l'ensemble des prestations disponibles pendant votre séjour."
                : `Le ${host.name} est sur GetWelcom ! Découvrez l'ensemble des prestations disponibles pendant votre séjour et réservez en ligne !"}`}
            </TextMobile>
            <Text slug={shortcut}>
              {shortcut === "parisjadore"
                ? "Découvrez ci-dessous les cartes du Restaurant J'adore !"
                : shortcut === "secret-de-paris"
                ? "Découvrez l'ensemble des prestations disponibles pendant votre séjour."
                : `Le ${host.name} est sur GetWelcom ! Découvrez l'ensemble des prestations disponibles pendant votre séjour et réservez en ligne !"}`}
            </Text>
          </HostOnFlexibles>
          <StayInfosContainer
            className="container-fluid"
            onlyRoomDirectory={
              shortcut === "secret-de-paris" || shortcut === "parisjadore"
            }
          >
            <InfosBox>
              <TitleInfos>
                Rentrez vos DATES DE SÉJOUR et découvrez nos Prestations !
              </TitleInfos>
              <Rules>
                Pour découvrir notre sélection de services disponibles pendant
                votre séjour, veuillez préciser votre date d'arrivée et de
                départ. Pour personnaliser votre séjour, vous devez avoir une
                réservation dans notre hébergement.
              </Rules>
              <Container>
                <Row className="justify-content-center align-items-center mt-4">
                  <Col className="d-inline-flex col-sm-auto">
                    <DateRangePicker
                      startDate={
                        valueArrivals ? moment(valueArrivals.date) : null
                      } // momentPropTypes.momentObj or null,
                      startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                      endDate={
                        valueDeparture ? moment(valueDeparture.date) : null
                      } // momentPropTypes.momentObj or null,
                      endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                      onDatesChange={({ startDate, endDate }) =>
                        setStayDates(startDate, endDate)
                      } // PropTypes.func.isRequired,
                      focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                      onFocusChange={(focusedInput) =>
                        setFocusedInput(focusedInput)
                      } // PropTypes.func.isRequired,
                      numberOfMonths={1}
                      orientation={HORIZONTAL_ORIENTATION}
                      startDatePlaceholderText={"Arrivée"}
                      endDatePlaceholderText={"Départ"}
                      hideKeyboardShortcutsPanel
                      showDefaultInputIcon
                      isOutsideRange={() => false}
                    />
                  </Col>
                  <Col className="d-inline-flex col-sm-auto">
                    <ButtonBox
                      className="col-auto text-nowrap"
                      id="stayValidation"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <ValidationButton
                        Title="Découvrir nos prestations !"
                        ButtonSize={{ width: "100%", height: "42px" }}
                        onClick={checkAndValidateInfos}
                        shortcut={shortcut}
                      />
                    </ButtonBox>
                  </Col>
                </Row>
              </Container>
              {/*
              <UnderBox className="row">
                <DateBox className="col-auto text-nowrap" style={{display: "flex", alignItems: "center"}}  >
                    <NameBox>
                    <Icone>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 27 30">
                        <path id="Icon_material-date-range" fill="black" data-name="Icon material-date-range" d="M13.5,16.5h-3v3h3Zm6,0h-3v3h3Zm6,0h-3v3h3ZM28.5,6H27V3H24V6H12V3H9V6H7.5A2.986,2.986,0,0,0,4.515,9L4.5,30a3,3,0,0,0,3,3h21a3.009,3.009,0,0,0,3-3V9A3.009,3.009,0,0,0,28.5,6Zm0,24H7.5V13.5h21Z" transform="translate(-4.5 -3)"/>
                    </svg></Icone>

                    Arrivée
                    </NameBox>
                    <SelectedDate
                    onClick={() => {
                      setDatePickerOpen(!datePickerOpen);
                      setTypeCalendar("arrival");
                    }}
                  >
                    {valueArrivals ? valueArrivals.format : "Quand ?"}
                  </SelectedDate>

                </DateBox>
                <DateBox  className="col-auto text-nowrap" style={{display: "flex", alignItems: "center"}} >
                  <NameBox>
                    <Icone> <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 27 30">
                        <path id="Icon_material-date-range" fill="black" data-name="Icon material-date-range" d="M13.5,16.5h-3v3h3Zm6,0h-3v3h3Zm6,0h-3v3h3ZM28.5,6H27V3H24V6H12V3H9V6H7.5A2.986,2.986,0,0,0,4.515,9L4.5,30a3,3,0,0,0,3,3h21a3.009,3.009,0,0,0,3-3V9A3.009,3.009,0,0,0,28.5,6Zm0,24H7.5V13.5h21Z" transform="translate(-4.5 -3)"/>
                    </svg>
                      </Icone>
                    Départ
                  </NameBox>
                  <SelectedDate
                    onClick={() => {
                      setDatePickerOpen(!datePickerOpen);
                      setTypeCalendar("departure");
                    }}
                  >
                    {valueDeparture ? valueDeparture.format : "Quand ?"}
                  </SelectedDate>
                </DateBox>*
                <ButtonBox  className="col-auto text-nowrap" id="stayValidation" style={{display: "flex", alignItems: "center"}} >
                  <ValidationButton
                    Title="Réserver mes prestations !"
                    ButtonSize={{ width: "100%", height: "35px" }}
                    onClick={checkAndValidateInfos}
                    shortcut={shortcut}
                  />
                </ButtonBox>
              </UnderBox>
                  */}
            </InfosBox>
          </StayInfosContainer>
          <Starter id="start_here"></Starter>
          <DetailContainer>
            <OptionSelectionContainer>
              {
                <div>
                  {roomDisplay &&
                    roomDisplay.map((room, i) => {
                      // display rooms options
                      if (room)
                        return (
                          <Opt
                            personalisate={personalisate}
                            compareAllDate={compareAllDate}
                            isFromStartOfStay={isFromStartOfStay}
                            isFromEndOfStay={isFromEndOfStay}
                            key={room.id}
                            data={room}
                            cart={cartBis}
                            roomDisplayed={i + 1}
                            roomTypes={roomPossibility}
                            isInCart={isInCart}
                            isRoomInCart={isRoomInCart}
                            deleteProductsRoom={deleteProductsRoom}
                            changeRoom={changeRoom}
                            createCartElem={createCartElem}
                            updateCartElem={updateCartElem}
                            deleteCartElem={deleteCartElem}
                            valueArrival={valueArrivals}
                            valueDeparture={valueDeparture}
                            reset={change}
                            shortcut={shortcut}
                          />
                        );
                      return null;
                    })}
                  {hostProducts.data &&
                    hostProducts.data.map((itemData) => {
                      if (shortcut === "parisjadore") {
                        return (
                          <OptPdf
                            key={itemData.id}
                            data={itemData}
                            host={host}
                            shortcut={shortcut}
                          />
                        );
                      }
                      // display other options
                      if (itemData.subcategories.length > 0) {
                        if (itemData.type !== "room") {
                          return (
                            <Opt
                              personalisate={personalisate}
                              compareAllDate={compareAllDate}
                              isFromStartOfStay={isFromStartOfStay}
                              isFromEndOfStay={isFromEndOfStay}
                              key={itemData.id}
                              data={itemData}
                              cart={cartBis}
                              hostProducts={hostProducts.data}
                              isInCart={isInCart}
                              isRoomInCart={isRoomInCart}
                              deleteProductsRoom={deleteProductsRoom}
                              createCartElem={createCartElem}
                              updateCartElem={updateCartElem}
                              deleteCartElem={deleteCartElem}
                              valueArrival={valueArrivals}
                              valueDeparture={valueDeparture}
                              reset={change}
                              shortcut={shortcut}
                            />
                          );
                        }
                      }
                      return null;
                    })}
                </div>
              }
            </OptionSelectionContainer>
            {!isMobile ? (
              <ShoppingCart
                key={drawerState}
                cartBis={cartBis}
                deleteCartElem={deleteCartElem}
                setChange={() => setChange(!change)}
                shortcut={shortcut}
              />
            ) : null}
          </DetailContainer>
        </SelectionContainer>
        {cartBis && cartBis.items.length !== 0 ? (
          <MobileValidationBox
            onlyRoomDirectory={
              shortcut === "secret-de-paris" || shortcut === "parisjadore"
            }
          >
            <ValidationButton
              Title="Valider ma commande"
              ButtonSize={{ width: "80%", height: "35px" }}
              goTo="connexion"
              cart={cartBis}
              save={saveCart}
              shortcut={shortcut}
            />
          </MobileValidationBox>
        ) : null}
      </CustomContainer>
    </div>
  );
};

const CalendarBox = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 20;
  @media screen and (max-device-width: 554px) {
    left: 6vw;
    top: 3vh;
  }
`;

const CalendarRow = styled.div``;

const RoomBox = styled.div`
  display: flex;
  width: 20%;
  flex-direction: row;
  color: #707070;
  font: normal normal 400 13px Poppins;
  @media screen and (max-device-width: 1440px) {
    width: 22%;
    font: normal normal 600 14px Poppins;
  }
  letter-spacing: 0.1px;
  margin-left: -20px;
  margin-top: 0px;

  @media screen and (max-device-width: 1200px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-left: -38px;
    margin-top: 20px;
    margin-bottom: -20px;
  }
  @media screen and (max-device-width: 480px) {
    width: 100%;
    margin-top: 23px;
    justify-content: flex-start;
    align-items: stretch;
    font-size: 14px;
    margin-left: 0;
    padding-left: 0px;
  }
  @media screen and (max-device-width: 375px) {
    width: 100%;
    margin-top: 23px;
    justify-content: flex-start;
    align-items: stretch;
    font-size: 14px;
    margin-left: 0;
  }
  @media screen and (max-device-width: 320px) {
    width: 100%;
    margin-top: 23px;
    justify-content: flex-start;
    align-items: stretch;
    font-size: 14px;
    margin-left: 0;
  }

  @media screen and (max-device-width: 554px) {
    font: normal normal 400 13px Poppins;
    justify-content: space-between;
  }
`;
const Rules = styled.div`
  display: flex;
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font: normal normal 300 12px Poppins;
  letter-spacing: 0px;
  color: #707070;
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5) {
    font-size: 11px;
  }
  @media screen and (max-device-width: 480px) {
    display: none;
  }
`;

const TextMobile = styled.div`
  display: none;
  color: ${(props) =>
    props.slug === "secret-de-paris" || props.slug === "parisjadore"
      ? "white"
      : "black"};

  @media screen and (max-device-width: 554px) {
    display: flex;
    text-align: center;
    font: normal normal 400 9px Poppins;
  }
`;

const Text = styled.div`
  display: flex;
  text-align: center;
  color: ${(props) =>
    props.slug === "secret-de-paris" || props.slug === "parisjadore"
      ? "white"
      : "black"};

  @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5) {
    font-size: 13px;
  }
  @media screen and (max-device-width: 480px) {
    display: none;
  }
`;

const ButtonBox = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  @media screen and (max-device-width: 1200px) {
    display: flex;
    width: 70%;
    // justify-content: center;
    margin-top: 50.24px;
  }
  @media screen and (max-device-width: 554px) {
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    margin-top: 30px;
    justify-content: center;
  }
`;

const Reservation = styled.div`
  display: flex;
  width: 20%;
  flex-direction: row;
  color: #707070;
  font: normal normal 400 13px Poppins;
  letter-spacing: 0.1px;
  margin-left: -20px;
  @media screen and (max-device-width: 1440px) {
    font: normal normal 600 14px Poppins;
  }

  @media screen and (max-device-width: 1200px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-left: -45px;
    margin-top: 20px;
  }
  @media screen and (max-device-width: 480px) {
    width: 100%;
    margin-top: 23px;
    justify-content: flex-start;
    align-items: stretch;
    font-size: 14px;
    margin-left: 0;
    padding-left: 0px;
  }
  @media screen and (max-device-width: 375px) {
    width: 100%;
    margin-top: 23px;
    justify-content: flex-start;
    align-items: stretch;
    font-size: 14px;
    margin-left: 0;
  }

  @media screen and (max-device-width: 320px) {
    width: 100%;
    margin-top: 23px;
    justify-content: flex-start;
    align-items: stretch;
    font-size: 14px;
    margin-left: 0;
  }

  @media screen and (max-device-width: 554px) {
    font: normal normal 400 13px Poppins;
    justify-content: space-between;
  }
`;
const SelectedDate = styled.div`
  display: flex;
  margin-left: 25px;
  font: normal italic 300 13px/30px Poppins;
  margin-top: 3px;
  color: #16a5a3;

  @media screen and (max-device-width: 1440px) {
    font: normal normal 600 12px Poppins;
  }
  @media screen and (max-device-width: 1200px) {
    font: normal normal 300 14px Poppins;
  }
  @media screen and (max-device-width: 554px) {
    font: normal normal 400 13px Poppins;
  }
`;

const MobileValidationBox = styled.div`
  display: none;
  @media screen and (max-device-width: 1200px) {
    display: ${(props) => (props.onlyRoomDirectory ? "none" : "flex")};
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 36.82px;
    padding-bottom: 20px;
  }
`;
const ReservationBox = styled.input`
  display: flex;
  width: 67%;
  height: 18px;
  // margin-top: ${(props) => (props.borderBottom ? "8px" : "16px")};
  border: none;
  border-bottom: ${(props) => props.borderBottom};
  background-color: transparent;
  resize: none;
  color: #707070;
  outline: none;
  overflow: hidden;
  font: normal normal 300 12px/30px Poppins;
  text-align: center;
  @media screen and (max-device-width: 1440px) {
    font: normal normal 600 12px Poppins;
  }
  @media screen and (max-device-width: 1200px) {
    width: 27%;
    font-size: 16px;
    margin-left: 85px;
  }
  @media screen and (max-device-width: 554px) {
    font: normal normal 400 13px Poppins;

    width: 40vw;
    margin-left: 34px;
    height: 22px;
    margin-top: 0px;
  }
`;
const NumberRoomSelected = styled.div`
  width: 55px;
  margin-top: 4px;
  font-weight: normal;
  font: normal normal 300 13px/30px Poppins;

  @media screen and (max-device-width: 1440px) {
    font: normal normal 600 12px Poppins;
    margin-top: -10px;
  }

  @media screen and (max-device-width: 1200px) {
    font: normal normal 300 14px/30px Poppins;
  }

  @media screen and (max-device-width: 554px) {
    font: normal normal 400 13px Poppins;
    margin-top: 0px;
    text-align: right;
  }
`;

const Room = styled.button`
  display: flex;
  width: 148px;
  height: 17px;
  color: #20a6a3;
  border: none;
  background-color: transparent;
  font-weight: normal;
  margin-left: 10px;
  :hover {
    font-weight: bold;
  }
`;
const RoomMenu = styled.div`
  position: absolute;
  width: 175px;
  height: 127px;
  background-color: white;
  margin-left: 35px;
  z-index: 9;
  margin-top: 40px;
  border: 1px solid #20a6a3;
  border-radius: 12px;
  flex-direction: column;

  @media screen and (max-device-width: 1200px) {
    margin-top: 160px;
  }
  @media screen and (max-device-width: 480px) {
    margin-top: 150px;
    margin-left: 134px;
  }
`;
const ArrowImg = styled.img`
  width: 6.63px;
  height: 3.68px;
  z-index: 12;
`;
const Arrow = styled.button`
  display: flex;
  width: 6.43px;
  height: 3.68px;
  border: none;
  justify-content: center;
  margin-left: 15px;
  margin-top: 10.62px;
  z-index: 10;
  background-color: transparent;
  :hover {
    background-color: lightgray;
  }
  @media screen and (max-device-width: 1200px) {
    margin-left: 25%;
  }
  @media screen and (max-device-width: 480px) {
    // margin-left: 15px;
  }
  @media screen and (max-device-width: 400px) {
    margin-left: 15px;
  }
`;
const ArrowMenu = styled.button`
  display: flex;
  position: absolute;
  width: 6.43px;
  height: 3.68px;
  border: none;
  justify-content: center;
  margin-left: 90%;
  z-index: 11;
  margin-top: 5px;
  :hover {
    background-color: lightgray;
  }
  @media screen and (max-device-width: 1200px) {
    margin-left: 90%;
  }
  @media screen and (max-device-width: 480px) {
    margin-left: 90%;
  }
`;
const UnSelectBox = styled.div`
  display: flex;
  height: 1px;
  background-color: white;
  margin-left: 35px;
  margin-top: 9px;
  @media screen and (max-device-width: 1440px) {
    margin-top: 10px;
  }
  @media screen and (max-device-width: 1200px) {
    width: 200px;
  }
  @media screen and (max-device-width: 480px) {
    width: 42vw;
    display: none;
  }
`;
const HostContainer = styled.div`
  display: flex;
  width: 100%;
  z-index: 144 !important;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0;
  padding: 0px;

  @media screen and (max-device-width: 1200px) {
    margin-top: 32.85px;
    width: 90%;
  }
  @media screen and (max-device-width: 554px) {
    width: 90%;
    display: grid;
    margin-top: 12.85px;
  }
`;
const HostLogo = styled.img`
  display: flex;
  height: 100%;

  @media screen and (max-device-width: 554px) {
    width: auto;
    height: 100%;
  }
`;

const HostFigureLogo = styled.figure`
  height: 131px;
  margin: auto;
  width: auto;
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5) {
    height: 75px;
  }
  @media screen and (max-device-width: 554px) {
    height: 115px;
    width: 115px;
    overflow: hidden;
    padding: 0px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 1px 1px 0.3em 0.1em grey;
    text-align: center;
  }
`;
const HostOnFlexibles = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  background: ${(props) =>
    props.slug === "secret-de-paris"
      ? "#6a4962 no-repeat padding-box"
      : props.slug === "parisjadore"
      ? "#d0b13d no-repeat padding-box"
      : "#16a5a31f 9% 0% no-repeat padding-box"};
  box-shadow: 4px 4px 10px #00000029;
  border-radius: 12px;
  margin-top: 28.78px;
  margin-left: 0;
  font: normal normal 400 16px Poppins;
  color: #707070;
  @media screen and (max-device-width: 1200px) {
    width: 100%;
    height: 48.56px;
    font: normal normal 600 10px Poppins;
    margin-top: 160px;
  }
  @media screen and (max-device-width: 480px) {
    width: 100%;
    height: 48.56px;
    font: normal normal 600 8px/12px Poppins;
    margin-top: 13.59px;
  }
`;
const HostAdress = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.slug === "secret-de-paris" || props.slug === "parisjadore"
      ? "white"
      : "black"};

  font-size: 14px;
  font-weight: 400;

  @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5) {
    font-size: 12px;
  }
  @media screen and (max-device-width: 554px) {
    font-size: 11px;
  }
  @media screen and (max-device-width: 350px) {
  }
`;
const HostPhone = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  color: ${(props) =>
    props.slug === "secret-de-paris" || props.slug === "parisjadore"
      ? "white"
      : "black"};
  font-size: 14px;
  font-weight: 400;
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5) {
    font-size: 12px;
  }
  @media screen and (max-device-width: 554px) {
    font-size: 11px;
  }
`;
const HostEmail = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  color: ${(props) =>
    props.slug === "secret-de-paris" || props.slug === "parisjadore"
      ? "white"
      : "black"};
  font-size: 14px;
  font-weight: 400;

  @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5) {
    font-size: 12px;
  }
  @media screen and (max-device-width: 554px) {
    font-size: 11px;
  }
`;
const HostWebsite = styled.a`
  display: flex;
  text-decoration: none;
  color: ${(props) =>
    props.slug === "secret-de-paris"
      ? "#6a4962"
      : props.slug === "parisjadore"
      ? "#d0b13d"
      : "#16a5a3"};
  font-weight: 500;
  align-items: center;
  margin-top: 15px;
  font-size: 14px;

  @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5) {
    font-size: 12px;
  }
  @media screen and (max-device-width: 554px) {
    font-size: 11px;
  }
`;
const HostReseaux = styled.div`
  display: flex;
`;

const LinkA = styled.a`
  margin: 15px;
  color: ${(props) =>
    props.slug === "secret-de-paris"
      ? "#6a4962 !important"
      : props.slug === "parisjadore"
      ? "#d0b13d !important"
      : "#16a5a3 !important"}; ;
`;

const HostDescription = styled.p`
  display: flex;
  font-size: 14px;
  font: normal italic 400 14px Poppins;
  height: 17ch;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 45px 0px 45px;

  @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5) {
    font-size: 11px;
  }

  @media screen and (max-device-width: 554px) {
    font-size: 11px;
    padding: 0px;
    width: 100%;
    text-align: center;
    height: auto;
    margin: auto;
  }
`;
const HostName = styled.h4`
  display: flex;
  text-transform: uppercase;
  font-weight: 500;
  color: ${(props) =>
    props.slug === "secret-de-paris" || props.slug === "parisjadore"
      ? "white"
      : "black"};
  font-size: 18px;
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5) {
    font-size: 16px;
  }
`;

const Icone = styled.div`
  margin-right: 5px;
`;
const DateBox = styled.div`
  display: flex;
  flex-direction: row;
  color: #707070;
  font: normal normal 400 13px Poppins;
  margin-left: -7px;
  @media screen and (max-device-width: 1440px) {
    font: normal normal 600 14px Poppins;
  }

  @media screen and (max-device-width: 1200px) {
    justify-content: center;
    margin-left: 0;
    align-items: center;
  }
  @media screen and (max-device-width: 554px) {
    justify-content: space-between;
    font-size: 14px;
    // margin: 0px;
    padding-left: 0px;
    width: 100%;
    margin-top: 23px;
    font: normal normal 400 13px Poppins;
  }
`;

const NameBox = styled.div`
  display: flex;
  // @media screen and (max-device-width: 554px){
  // }
`;

const UnderBox = styled.div`
  display: flex;
  margin-top: 19.78px;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  width: 100%;

  @media (max-device-width: 768px) {
    flex-direction: column;
    height: 300px;
    max-height: 250px;
    width: 100%;
    justify-content: space-between;
  }
  @media (max-width: 767px) and (min-width: 300px) {
    flex-direction: column;
    min-height: 275px;
    width: 100%;
    justify-content: space-between;
    // margin-left: 5px;
  }
  @media screen and (max-device-width: 554px) {
    margin: 0px;
    padding: 0px;
    margin-top: 23px;
  }
`;

const DetailContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-device-width: 1200px) {
    flex-direction: column;
  }
`;
const Starter = styled.div`
  width: 0px;
`;
const InfosBox = styled.div`
  display: flex;

  width: 1420px;
  height: 137.5px;
  flex-direction: column;
  @media screen and (max-device-width: 1200px) {
    width: 100%;
    margin-left: 0;
  }
`;

const TitleInfos = styled.div`
  color: #707070;
  width: 100%;
  font: normal normal 500 18px/35px Poppins;

  @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5) {
    font-size: 14px;
  }
  @media screen and (max-device-width: 1200px) {
    font: normal normal bold 16px Poppins;
  }
  @media screen and (max-device-width: 554px) {
    font: normal normal bold 14px/21px Poppins;
    position: relative;
    top: 15px;
    text-align: center;
  }
`;

const OptionSelectionContainer = styled.div`
  display: flex;
  height: 100%;
  width: 65%;
`;

const SelectionContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 74.5%;

  @media screen and (max-device-width: 554px) {
    width: 100%;
  }

  @media screen and (max-device-width: 1200px) {
    width: 90vw;
  }
`;
const StayInfosContainer = styled.div`
  display: ${(props) => (props.onlyRoomDirectory ? "none" : "flex")};
  // background-color: #ededed;
  background: white 9% 0% no-repeat padding-box;
  border-radius: 18px;
  margin-top: 17px;
  padding: 20px;

  @media screen and (max-device-width: 1200px) {
    height: 50vw;
    width: 100%;
    justify-content: center;
  }
  @media screen and (max-device-width: 480px) {
    height: 230px;
  }
  @media (max-width: 575.98px) {
    width: 100%;
    padding: 10px;
  }
`;
const CustomContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  position: relative;

  @media screen and (max-device-width: 768px) {
    margin-top: 20%;
  }
  @media (max-width: 575.98px) {
    margin-top: ${(props) =>
      props.slug === "parisjadore"
        ? "0px"
        : props.slug === "secret-de-paris"
        ? "0px"
        : "52px"};
    font-size: 12px;
    margin-bottom: ${(props) =>
      props.slug === "parisjadore"
        ? "25px"
        : props.slug === "secret-de-paris"
        ? "25px"
        : "0px"};
  }

  @media screen and (max-device-width: 350px) {
    margin-top: 34px;
  }
`;

const HostInfosContainer = styled.div`
  display: block;
  height: 370px;
  width: 100%;
  // background-color: #ededed;
  // justify-content: center;
  overflow: hidden;
  margin: 0px;
  padding: 0px;

  @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5) {
    height: 270px;
  }

  @media screen and (max-device-width: 1200px) {
    height: 100px;
    background-color: #f7f7f7;
  }
  @media screen and (max-device-width: 554px) {
    padding: 0px;
    margin: 0;
    height: fit-content;
    display: none;
  }
`;
const HostInfosContainerMobile = styled.div`
  display: flex;
  height: 370px;
  width: 100%;
  justify-content: center;
  background-color: ${(props) =>
    props.slug === "secret-de-paris" || props.slug === "parisjadore"
      ? "black"
      : "#ededed"};
  color: ${(props) =>
    props.slug === "secret-de-paris" || props.slug === "parisjadore"
      ? "white !important"
      : "black"};

  @media screen and (max-device-width: 554px) {
    height: auto;
    margin: 0px;
    margin-bottom: 15px;
    padding: 0px;
    padding-bottom: 15px;
  }
  @media screen and (max-device-width: 350px) {
    padding: 0px;
    margin: 0px;
    height: auto;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
`;

const HostInfos = styled.div`
  display: flex;
  flex-direction: column;
  width: 74.5%;
  padding: 15px;
  justify-content: center;
  align-items: center;

  @media screen and (max-device-width: 554px) {
    padding: 0px;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin: auto;
  }
`;

const HeaderHostInfos = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 370px;
  padding: 15px;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.85);
  box-shadow: 1px 1px 1em 0.2em rgba(255, 255, 255, 0.85);

  @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5) {
    height: 270px;
  }

  @media screen and (max-device-width: 554px) {
    padding: 0px;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin: auto;
  }
`;

const HostFigureCarousel = styled.figure`
  width: 100%;
  margin: 0px;
  padding: 0px;
  height: 370px;
  line-height: 370px;
  display: flex;
  align-items: center;

  @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5) {
    height: 467px;
    line-height: 270px;
  }

  @media screen and (max-device-width: 554px) {
    height: 180px;
  }
`;

const HostFigureCarouselMobile = styled.figure`
  width: 100%;
  margin: 0px;
  padding: 0px;
  height: 370px;
  line-height: 370px;
  display: flex;
  align-items: center;

  @media screen and (max-device-width: 554px) {
    height: 180px;
  }
`;

const HostImageCarousel = styled.img`
  height: auto;
  width: 100%;
  vertical-align: middle;
`;

const svg = styled.a`
  color: #16a5a3;
`;
