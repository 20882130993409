import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import UserProfile from "../UserProfile/UserProfile";
import history from '../history'
import { CardManager } from '../CardManager';
import { ValidationButton } from '../ValidationButton';
import { RegisterForm  } from '../RegisterForm';
import { LoginForm } from '../LoginForm'
import axios from 'axios';
import { TopBar } from '../TopBar'
import moment from 'moment';
import {useParams} from 'react-router-dom'
import {
    getUA,
  } from "react-device-detect";

moment.locale("fr")
export const Connexion = ()=> {
    const [inscriptionOpen, setInscriptionOpen] = useState(false);
    const [loginOpen, setLoginOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState("");
    const cartId = UserProfile.getUserCartId();
    const [cart, setCart] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [change, setChange] = useState();
    const [ip, setIP] = useState('');
    const [done, setDone] = useState(false);
    const [category, setCategory] = useState([]);
    const [sortCart, setSortCart] = useState();
    const {shortcut} = useParams()
    const [host, setHost] = useState(null);

    //creating function to load ip address from the API
    const getData = async () => {
      const res = await axios.get('https://geolocation-db.com/json/')
      setIP(res.data.IPv4)
    }

    useEffect(() => {
        getData();
    })
    

    useEffect(() => {
        const config = {
            method: 'GET',
            url:  process.env.REACT_APP_API_URL + `/shopping-cart/${cartId}`,
        }
        axios(config).then(responsePut => {
                setCart(responsePut.data);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        try {
        var xmlHttp = new XMLHttpRequest();
            xmlHttp.open( "GET", process.env.REACT_APP_API_URL + `/hoster?shortcut=${shortcut}`, false );
            xmlHttp.send( null );
            setHost(JSON.parse(xmlHttp.responseText));
        } catch (e) {
            console.log(e)
        }
    }, [])


  
    const [user, setUser] = useState(null);
    let totalPrice = 0;
        if (cart) {
            cart.items.map((services) => {
                if (services.selectedItem && services.selectedItem.price) {
                return totalPrice +=  (parseFloat(services.selectedItem.price) * services.quantity);
                }
                return totalPrice +=  (parseFloat(services.product.price) * services.quantity);
            })
        }
    const setNumber= (price) => {
            price = price.toString();
            price = price.replace('.', ',')
            return price
        }

        const getLabel = (service) => {
            if (service.selectedItem && service.selectedItem.label )
                return (service.selectedItem.label + (service.quantity > 1 ? ` (x${service.quantity})`: ""))
            else return (service.product.label + (service.quantity > 1 ? ` (x${service.quantity})`: ""));
        }
        const getPrice = (service) => {
            if (service.selectedItem.price && service.selectedItem.price === 0)
                return "Gratuit"
            if (service.selectedItem.price)
                return parseFloat(service.selectedItem.price).toFixed(2) * service.quantity + "€"
            if (service.product.price === 0)
                return "Gratuit"
            else
                return service.product.price.toFixed(2) * service.quantity + "€"
            
            
        }
        const goBack = () => {
            history.push(`/${shortcut}`);
            return
        }

        const getDate = (date) => {
            const ordered = new Date(date).toLocaleDateString('fr', {
                day: '2-digit',
                month: '2-digit',
              });
            return (ordered)
        }

    const Payment = async () => {
        if (selectedCard || totalPrice === 0) {
            let select = null;
            if (selectedCard !== null) {
                select = selectedCard;
            }
            var config = {
                method: 'POST',
                url: process.env.REACT_APP_API_URL + '/checkout/payment',
                headers: {
                    'Authorization': 'Bearer ' + user.token,
                },
                data: {
                    "shoppingCartId": cartId,
                    "cardId": select,
                    "endUserIpAddress": ip,
                    "endUserBrowserInfo": {
                        "acceptHeader": "text/html, application/xhtml+xml, application/xml;q=0.9, /;q=0.8",
                        "javaEnabled": true,
                        "language": "FR-FR",
                        "colorDepth": 4,
                        "screenHeight": window.screen.height,
                        "screenWidth": window.screen.width,
                        "timeZoneOffset": (new Date()).getTimezoneOffset().toString(),
                        "userAgent": getUA.toString(),
                        "javascriptEnabled": true
                    },
                    "secureModeReturnURL": process.env.REACT_APP_RETURN_URL + `/${shortcut}/connexion`
                }
            };
            return axios(config).then(async function (res) {
                return res
            })
            .catch(function (error) {
                console.log(error.response)
                return error
            });
        } else {
            setError("Veuillez sélectionner une carte.")
            return "error";
        }
    }

    function secureRedirection(url) {
        var win = window.open(url, '_self');
        win.focus();
      }
    
      useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        //const params = Object.fromEntries(urlSearchParams.entries()); // Not compatible for old browser
        const urlEntries = urlSearchParams.entries();
        const params = Array.from(urlEntries).reduce((obj, [ key, value ]) => { return { ...obj, [key]: value };}, {});
        if (params && params.check && params.transactionId && user && user.token && !done) {
            setDone(true)
            const config = {
                method: 'GET',
                url:  process.env.REACT_APP_API_URL + `/checkout/payment-status?transactionId=${params.transactionId}&shoppingCartId=${cartId}&check=${params.check}`,
                headers: {
                    'Authorization': 'Bearer ' + user.token,
                },
            }
            axios(config).then(responsePut => {
                if (responsePut.data.status === "SUCCEEDED") {
                    history.push(`/${shortcut}/success`)
                    UserProfile.setLastOrder(responsePut.data.orderId);
                    localStorage.removeItem("cartId")
                }
            });
        }
    }, [user])

    const handlePayment = () => {
        Payment().then(
            res => {
                if (res === "error")
                    return; 
                if (res && (res.data.resultMessage === "Success" || res.data.status === "SUCCEEDED")) {
                    history.push(`/${shortcut}/success`)
                    UserProfile.setLastOrder(res.data.orderId);
                    localStorage.removeItem("cartId")
                } else if (res.data.secureModeNeeded && res.data.status !== "FAILED") {
                    secureRedirection(res.data.secureModeRedirectURL)
                } else {
                    setError("Paiement refusé. Veuillez séléctionner un autre moyen de paiement ou recommencer l'opération")
                }
            }
        )
    }

    const deleteElem = (idElem) => {
        const config = {
            method: 'DELETE',
            url:  process.env.REACT_APP_API_URL + `/shopping-cart/${cartId}/items/${idElem}`,
        }
        axios(config)
            .then(function (responsePut) {
                setCart(responsePut.data);
            }).catch(function (errorPut) {
                console.log(errorPut)
            })
    }
    useEffect(() => {
        setUser(JSON.parse(UserProfile.getUser()));
       
    }, [inscriptionOpen, loginOpen, change]);
    const getSlot = (date) => {
        return moment(date).format("DD/MM/YY [-] HH[H]mm")
    }
    const isInCat = (cat, nb) => {
        if (!category)
            return false;
        for (let i = 0; i!==category.length; i++) {
            if (cat === category[i].cat && nb === category[i].number ) {
                return true;
            }
        }
        return false
    }

    useEffect(() => {
        if (!cart || !cart.items)
            return;
        const items = cart.items;
        for (let i = 0; i!==items.length; i++) {
            if (!isInCat(items[i].product.categoryName, items[i].roomNumber)) {
                let tmp = category;
                if (!tmp)
                    tmp = []
                tmp.push({cat: items[i].product.categoryName,number: items[i].roomNumber})
                setCategory(tmp);
            }
        }
    }, [cart])

    useEffect(() => {
        if (category) {
            let tmpcart = [];
            for (let i = 0; i!==category.length; i++) {
                tmpcart.push({"cat": category[i], "data" : cart.items.filter(item => item.product.categoryName === category[i].cat && item.roomNumber === category[i].number)})
            }
            setSortCart(tmpcart);
        }
    }, [category, cart])

    if (loading)
        return (null);
        
    return (
        <div style={{display: "flex", width: "100%", flexDirection: "column", alignItems: "center"}}>
        <TopBar change={change} setChange={setChange}/>
        <Container>
        <ShoppingCartContainer>
            <GoBackContainer onClick={goBack}>
                <Logo src="/Icones/Arrow - black.png" />
                <div style={{marginLeft: "5px", font: "normal normal medium 14px/21px Poppins"}}>Continuer ma séléction</div>
            </GoBackContainer>
            <InfoStay>
                Votre commande pour votre séjour au {host.name} du {getDate(cart.beginDateOfStay)} au {getDate(cart.endDateOfStay)}
            </InfoStay>
        {
        cart && cart.items && cart.items.length > 0 ?
         <Labels>
             {
                sortCart.map((servicess) => {
                                    return servicess.data.map((services, index) => {
                                        return (
                                        <LabelContainer key={index}>
                                            <LabelCat> {services.product && services.roomNumber !== 0 && index === 0 ? `Chambre ${services.roomNumber} (${services.product.categoryName})` : null}</LabelCat>
                                            <LabelCat> {services.product && services.roomNumber === 0 && index === 0 ? services.product.categoryName : null}</LabelCat>
                                            {   
                                                <Label>
                                                    <LabelDiv>
                                                    <DeleteButton onClick={() => {deleteElem(services._id)}} src="/Icones/IMG-0137.PNG" />
                                                        <div style={{marginLeft: "19.16px"}}>{getLabel(services)} </div>
                                                    </LabelDiv>
                                                <div>
                                                {
                                                    getPrice(services)
                                                }
                                                </div>
                                                </Label>
                                            }
                                                {services.date ? <Slot>{getSlot(services.date)}</Slot> : null}
                                    
                                        </LabelContainer>
                                        )
                                    
                                    })
                                })}
            <TotalContainer>
                <Separation />
                <TotalBox>
                    <div>Total</div>
                    <div>
                        {
                            setNumber(totalPrice.toFixed(2)) + " €"
                        }
                    </div>
                </TotalBox>
            </TotalContainer>
         </Labels>
         : <EmptyCart>Votre panier est vide<Icone src="Icones/Basket.png"/></EmptyCart>
        }
        {!user ? <ConnexionInfo>
            Vous n’êtes pas connecté(e) à GetWelcom, veuillez créer un compte pour garder une preuve d’achat et pouvoir obtenir un remboursement en cas d’annulation de la commande.
        </ConnexionInfo> : <ConnexionInfo> En validant ma commande, j’accepte les CGV de l’établissement ainsi que la politique de remboursement de GetWelcom. Je m’engage également à régler le montant total affiché, lequel induit les frais de services de la plateforme. </ConnexionInfo>
        }

    <UnderCart>
        {!user ? <ButtonBox>
            <ValidationButton Title="Me connecter" ButtonSize={{width: "48%", height:"30px"}}  goTo="login" onClick={setLoginOpen} cart={cart} color="#20A7A4"/>
            <ValidationButton Title="Créer un compte" ButtonSize={{width: "48%", height:"30px"}}  goTo="register" onClick={setInscriptionOpen} cart={cart}/>
        </ButtonBox>: <CardManager setSelectedCard={setSelectedCard} selectedCard={selectedCard} userId={user.id} setError={setError}/>
        }

        <RegisterForm cartId={cartId} inscriptionOpen={inscriptionOpen} setInscriptionOpen={setInscriptionOpen} setChange={setChange} change={change}/>
        { loginOpen ? <LoginForm setLoginOpen={setLoginOpen} setChange={setChange} change={change}/> : null}
    </UnderCart>
    <ErrorMessage>
        {error ? error : null}
    </ErrorMessage>
    {user ? <div style={{display: "flex", justifyContent: "center", marginTop: "20px"}}>
        <ValidationButton Title="Valider ma commande" ButtonSize={{width: "70%", height:"30px"}}  goTo="payment" onClick={handlePayment} cart={cart} shortcut={shortcut}/>
    </div> : null
    }
    </ShoppingCartContainer>
    </Container>
    </div>
    )
}

const Slot = styled.div`
    display: flex;
    margin-left: 50px;
    font: normal normal 200 17px/30px Poppins;
    letter-spacing: 0px;
    color: #1E2224;
    opacity: 1;
    @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
        font-size: 14px;   
    }
`
const ErrorMessage = styled.div`
    display: flex;
    font: normal normal 600 17px/26px Poppins;
    color: red;
    margin-top: 30px;
    @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
        font-size: 14px;   
    }
`

const DeleteButton = styled.img`
    display: flex;
    width: 10px;
    height: 12px;
    :hover {
        cursor: pointer;
    }
    @media screen and (max-device-width:480px){
        width: 8px;
        height: 9px;
    }

`
const LabelDiv = styled.div`
    display: flex;
    align-items: center;
`
const InfoStay = styled.div`
    display: flex;
    width: 100%;
background: #B3DFE0 0% 0% no-repeat padding-box;
border-radius: 12px;
opacity: 1;
margin-top: 23.26px;
font: normal normal 600 16px/25px Poppins;
letter-spacing: 0px;
color: #1D1D1D;
opacity: 1;
justify-content: center;
align-items: center;

@media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
    font-size: 14px;   
}
@media screen and (max-device-width:480px){
    font: normal normal 600 12px/18px Poppins;
    margin-top: 15px;
    }
    padding-left: 10px;

`
const Logo = styled.img`
    display: flex;
    width: 12px;
height: 7px;
transform: matrix(0, 1, -1, 0, 0, 0);
opacity: 1;
@media screen and (max-device-width:480px){
        width: 10px;
        height: 5.5px;
    }
`
const LabelContainer = styled.div`
    display: flex;
    width: 92%;
    flex-direction: column;
    //padding-left: 50.5px;
    @media screen and (max-device-width:480px){
        width: 80%;
    }

`
const ConnexionInfo = styled.div`
    display: flex;
    width: 100%;
    margin-top: 29.95px;
    font: normal normal 300 10px/16px Poppins;
    letter-spacing: 0.9px;
    color: #1E2224;
    @media screen and (max-device-width:480px){
        margin-left: 8px;
    }
`
const ButtonBox = styled.div`
    display: flex;
    width: 85%;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-device-width:480px){
        width: 100%;
        height: 80px;
    }
    
`
const UnderCart = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
`
const Container = styled.div`
    display: flex;
    width: 30%;
    justify-content: center;
    margin-top: 103px;
    margin-bottom: 200px;
    @media screen and (max-device-width:480px){
        margin-top: 50px;
        margin-bottom: 100px;
        width: 90%;
        align-items: center;
        justify-content: center;
    }
    
`
const Icone = styled.img`
    width: 20px;
    height: 20px;
    padding-right: 10px;
    margin-left: 11.83px;
`;

const TotalBox = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 9px;
    padding-bottom: 22.32px;
    @media screen and (max-device-width:480px){
        font: normal normal 600 12px/30px Poppins;
    }

`
const Separation = styled.div`
    display: flex;

    width: 100%;
    height: 1px;
    background-color: #16a5a3;
`
const TotalContainer = styled.div`
    display: flex;
    width: 94%;
    flex-direction: column;
    align-items: center;
    margin-top: 19px;
    padding-left: 22.81px;

    padding-bottom: 27px;
    @media screen and (max-device-width:480px){
        width: 85%;

    }
`
const LabelCat = styled.div`
    display: flex;
    width: 100%;
    margin-left: 50.5px;
    margin-bottom: 5px;
    @media screen and (max-device-width:480px){
        font: normal normal normal 14px Poppins;
        margin-left: 45px;

    }
`
const Label = styled.div`
    display: flex;
    width: 100%;
    color: black;
    margin-left: 19px;
    font: normal normal medium 17px/30px Poppins;
    justify-content: space-between;
    @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
        font-size: 14px;   
    }
    @media screen and (max-device-width:480px){
        font: normal normal normal 12px Poppins;
    }


`
const Labels = styled.div `
    display: flex;
    width: 100%;
    height: fit-content;
    margin-top: 19px;
    background: white;
    color: #20A8A4;
    border: 1px solid lightgrey;
    border-radius: 18px;
    justify-content: center;
    box-shadow: 4px 4px 10px #00000029;
    opacity: 1;
    font: normal normal 600 17px/26px Poppins;
    flex-direction: column;
    @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
        font-size: 14px;   
    }
`
const EmptyCart = styled.div`
    display: flex;

    width: 100%;
    height: 105px;
    margin-top: 19px;
    align-items: center;
    background: white;
    justify-content: center;
    color: #20A8A4;
    border: 1px solid lightgrey;
    border-radius: 18px;
    box-shadow: 4px 4px 10px #00000029;
    opacity: 1;
    font: normal normal 600 17px/26px Poppins;
    @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
        font-size: 14px;   
    }
`
const GoBackContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 24.85px;
    @media screen and (max-device-width:480px){
        margin-top: 0px;
        font: normal normal normal 12px/16px Poppins;
    }
    :hover {
        cursor: pointer;
    }
`
const ShoppingCartContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
`
