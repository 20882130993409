import styled from "styled-components";
import React from "react";
import { Switch } from "react-router-dom";

import { Router, Route } from "react-router";
import history from "./history";
import { Home } from "./Home";
import { Footer } from "./Footer";
import { Connexion } from "./Connexion";
import { Success } from "./Success";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTMID,
};

TagManager.initialize(tagManagerArgs);

function Page() {
  return (
    <MainContainer>
      {
        <Switch>
          <Route exact path="/:shortcut" component={Home} />
          <Route exact path="/:shortcut/connexion" component={Connexion} />
          <Route exact path="/:shortcut/success" component={Success} />
        </Switch>
      }
    </MainContainer>
  );
}

const App = () => {

  // function themecolor() {
  //   var elements = document.getElementsByTagName("*"),
  //     elen = elements.length;
  //   for (var i = 0; i < elen; i++) {
  //     var myChildred = elements[i].childNodes;
  //     var len2 = myChildred.length;
  //     for (var jj = 0; jj < len2; jj++) {
  //       if (myChildred[jj].nodeType === 3) {
  //         // console.log(myChildred[jj].nodeValue);
  //         myChildred[jj].nodeValue = myChildred[jj].nodeValue.replace(
  //           "#16a5a3",
  //           "#6a4962"
  //         );
  //         myChildred[jj].nodeValue = myChildred[jj].nodeValue.replace(
  //           "#707070",
  //           "white"
  //         );
          
  //       }
  //     }
  //   }
  // }

  function Redirection(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }
  window.onload = function () {
    setTimeout(function () {
      document.getElementById("loading").style.display = "none";
      
    }, 1000);
  };

  return (
    <div>
      <Container /*onLoad={themecolor}*/>
        <link
          href="https://fonts.googleapis.com/css?family=Poppins"
          rel="stylesheet"
        />
        <Router history={history}>
          <Page />
        </Router>
      </Container>
    </div>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;

  @media screen and (max-device-width: 480px) {
    width: 100vw;
  }
`;

const Container = styled.header`
  display: flex;

  width: 100%;
  height: 120%;
  flex-flow: column;
  align-items: center;
  @media screen and (max-device-width: 1024px) {
    height: 200%;
  }
  @media screen and (max-device-width: 480px) {
    width: 100%;
    height: 200%;
  }
`;

export default App;
