import React, { useState, useEffect, Fragment } from 'react'
import styled from 'styled-components'
import Modal from 'react-modal';
import Agenda from '../Agenda';
import moment from 'moment';

import './modal.css'
import DisplayPdf from '../DisplayPdf';


Modal.setAppElement(document.getElementById('root'));


const OptionSelection = ({ isInCart, subData, name, typeSelected, roomNumber, personalisate, change, deleteCartElem, updateCartElem, valueArrival, valueDeparture, shortcut }) => {
    const [select, setSelect] = useState(false);
    const [inCart, setInCart] = useState(isInCart(subData._id, roomNumber, name, subData.label));
    const [quantity, setQuantity] = useState(1);
    const [isActive, setIsActive] = useState(false);
    const [isTypeActive, setIsTypeActive] = useState(false);
    const [type, setType] = useState("");
    const [modalIsOpen, setIsOpen] = useState(false);
    const [optSelect, setOptSelect] = useState({ "quantity": null, "selectedItems": { "label": null, "price": null }, "date": null })
    const [slot, setSlot] = useState()
    const [agendaActive, setAgendaActive] = useState(false);
    const [multiple, setMultiple] = useState(false)

    useEffect(() => { // block scroll when agenda is open
        if (agendaActive) {
            document.body.style.overflow = "hidden"
            document.body.style.position = "relative";
            document.body.style.height = "100%";
        } else {
            document.body.style.overflow = "scroll"

        }
    }, [agendaActive])

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    useEffect(() => {
        setInCart(isInCart(subData._id, roomNumber, name, subData.label));
        setSelect(isInCart(subData._id, roomNumber, name, subData.label))
    }, [change])

    const updateObj = (quant, date, items) => { // for multiple selection
        let tmp = optSelect;
        if (quant)
            tmp.quantity = quant;
        if (date)
            tmp.date = date;
        if (items)
            tmp.selectedItems = items;
        setOptSelect(tmp);
    }

    useEffect(() => {
        if (inCart) {
            setSelect(true)
        }
    }, [inCart])


    useEffect(() => { // when options with agenda are selected each slot have to be push in cart
        if (slot && slot.length >= 1) {
            setSelect(true);
            for (let i = 0; i !== slot.length; i++) {
                updateCartElem(subData._id, name, subData, optSelect, roomNumber, slot[i].quantity, slot[i].date)
            }
        }
    }, [slot])

    const submitAgenda = () => {
        setAgendaActive(false);
    }

    const getQuantity = () => { // for quantity of "normal" opt
        let tmp = []
        for (let i = 0; i !== subData.quantity; i++) {
            tmp.push(
                <Quantity onClick={() => {
                    if (typeSelected && subData.type !== "agenda") {
                        updateObj(i + 1, null, null);
                        setQuantity(i + 1);
                        updateCartElem(subData._id, name, subData, optSelect, roomNumber, i + 1);
                        setIsActive(false)
                    }
                }
                }>
                    {i + 1}
                </Quantity>)
        }
        return tmp;
    }
    const getQuantyType = () => { // for quantity of "multiple choise" opt
        let tmp = []
        if (subData.multiplePrice) {
            return subData.multiplePrice.map((content) => {
                if (content.label === type && content.quantity > 1) {
                    for (let i = 0; i !== parseInt(content.quantity); i++) {
                        tmp.push(
                            <Quantity onClick={() => {
                                if (typeSelected && subData.type !== "agenda") {
                                    updateObj(i + 1, null, { "label": content.label, "price": content.price });
                                    updateCartElem(subData._id, name, subData, optSelect, roomNumber, i + 1);
                                    setIsActive(!isActive)
                                }
                            }
                            }>
                                {i + 1}
                            </Quantity>
                        )
                    }
                    return tmp;
                }
            })
        }
    }

    const getType = () => { // multiple choice selector
        let tmp = []
        subData.multiplePrice.map((content) => {
            tmp.push(
                <Type onClick={() => {
                    if (typeSelected) {
                        setType(content.label);
                        if (content.quantity > 1)
                            setMultiple(true)
                        else {
                            updateObj(1, null, { "label": content.label, "price": content.price });
                            setMultiple(false)
                            updateCartElem(subData._id, name, subData, optSelect, roomNumber, 1);
                        }
                        setIsActive(!isActive)
                    }
                }
                }>
                    {content.label} {setNumber(content.price) + "€"}
                </Type>)
        })
        return tmp;
    }
    const setNumber = (price) => {
        price = price.toString();
        price = price.replace('.', ',')
        return price
    }


    useEffect(() => {
        setSelect(false);
        setQuantity(1);
    }, [name])


    const getMinPrice = () => {
        let min = parseFloat(subData.multiplePrice[0].price);

        for (let i = 1; i < subData.multiplePrice.length; i++) {
            if (parseFloat(subData.multiplePrice[i].price) < min) {
                min = parseFloat(subData.multiplePrice[i].price);
            }
        }
        return min
    }
    return (
        <div>
            <OptionSelect>
                <Label slug={shortcut}>
                    <LabelPick onlyRoomDirectory={(shortcut === "secret-de-paris" || shortcut === "parisjadore")} onClick={() => {
                        if (typeSelected && personalisate) {
                            if (subData.type === "agenda")
                                setAgendaActive(true)
                            if (subData.type && subData.type !== "agenda")
                                setSelect(true)
                            setIsOpen(true)
                        }
                        if (!typeSelected || !personalisate) {
                            return;
                        }
                        if (select || inCart) {
                            deleteCartElem(subData._id, name, roomNumber);
                            setIsOpen(false)
                            setSelect(false)
                            setAgendaActive(false)
                            setInCart(false)
                        }
                        else {
                            if ((subData.quantity === 1 || subData.quantity === 0) && !subData.multiplePrice && subData.type !== "agenda") {
                                setIsOpen(false)
                                updateCartElem(subData._id, name, subData, optSelect, roomNumber, quantity);

                            }
                            else {
                                if (!subData.multiplePrice)
                                    setIsActive(true);
                                else setIsTypeActive(true);
                            }
                        }
                    }}>

                        {
                            select || inCart ? <img alt="" style={{ display: "flex", width: "15px", height: "15px" }} src="Icones/Checkbox active .png" /> : null
                        }


                    </LabelPick>
                    {subData.label}
                </Label>
                <Price slug={shortcut}>
                    {
                        subData.price === 0 && !subData.multiplePrice ?
                            "Gratuit" : subData.multiplePrice ? "min. " + setNumber(getMinPrice().toFixed(2)) : setNumber(subData.price.toFixed(2)) + " €"
                    }
                    <ShowInfo src={!modalIsOpen ? "Icones/information.svg" : "Icones/close.svg"} onClick={() => { modalIsOpen ? closeModal() : openModal() }} size={modalIsOpen} />
                </Price>
            </OptionSelect>
            {
                subData.type === "agenda" && typeSelected && agendaActive ?
                    <Agenda className="modal" labelId={subData.calendar} serviceId={subData._id} category={name} label={subData.label} arrival={moment(valueArrival.date).format("YYYY-MM-DD")} departure={moment(valueDeparture.date).format("YYYY-MM-DD")} deliveryLimit={subData.deliveryTimeLimit} orderMaxNum={10} OrderLabels={subData.label} slot={slot} setSlot={setSlot} submitAgenda={submitAgenda} /> : null
            }
            {
                (subData.quantity > 1 && select && subData.type !== "agenda") || (inCart && subData.type !== "agenda" && subData.quantity > 1) ? (
                    <QuantityBox onClick={() => {
                        if (typeSelected && !isActive)
                            setIsActive(true)
                    }}>
                        Quantité
                        <QuantityArrow onClick={() => {
                        }}>
                            <QuantityArrowImg src={isActive ? "/Icones/Fleche black haut.png" : " Icones/Arrow - black.png"} />
                        </QuantityArrow>
                        {isActive ? (
                            <QuantityContainer>
                                <QuantitySelect>
                                    {getQuantity()}
                                </QuantitySelect>
                            </QuantityContainer>
                        ) : null}
                    </QuantityBox>
                ) : null
            }
            {subData.multiplePrice && select ? (
                <QuantityBox onClick={() => {
                    if (typeSelected)
                        setIsTypeActive(!isTypeActive)
                }}>
                    Type
                    <QuantityArrow onClick={() => {
                        if (typeSelected)
                            setIsTypeActive(!isTypeActive)
                    }}>
                        <QuantityArrowImg src={isTypeActive ? "Icones/Fleche black haut.png" : " Icones/Arrow - black.png"} />
                    </QuantityArrow>
                    {isTypeActive ? (
                        <TypeContainer>
                            <TypeSelect>
                                {getType()}
                            </TypeSelect>
                        </TypeContainer>
                    ) : null}
                </QuantityBox>
            ) : null
            }
            {subData.multiplePrice && select && !isTypeActive && multiple ? (
                <QuantityBox style={{ marginTop: "5px" }} onClick={() => {
                    if (typeSelected)
                        setIsActive(!isActive)
                }}>
                    Quantité
                    <QuantityArrow onClick={() => {
                        if (typeSelected)
                            setIsActive(!isActive)
                    }}>
                        <QuantityArrowImg src={isActive ? "Icones/Fleche black haut.png" : " Icones/Arrow - black.png"} />
                    </QuantityArrow>
                    {isActive ? (
                        <QuantityContainer>
                            <QuantitySelect>
                                {getQuantyType()}
                            </QuantitySelect>
                        </QuantityContainer>
                    ) : null}
                </QuantityBox>
            ) : null
            }
            {modalIsOpen ?
                subData.photo ? <InfoPopover> <InfoProductPhoto style={{ backgroundImage: `url(${subData.photo})` }} alt="" /><InfoProductDescription slug={shortcut}>{subData.description}</InfoProductDescription></InfoPopover> : <InfoPopover><InfoProductDescription slug={shortcut}>{subData.description}</InfoProductDescription></InfoPopover> : null}
        </div>
    )
}



export const OptPdf = ({ data, host, shortcut }) => {
    const [pdfModalIsOpen, setPdfModalIsOpen] = useState(false);

    const toggleModal = () => {
        setPdfModalIsOpen(!pdfModalIsOpen);
    };

    useEffect(() => {
        if (pdfModalIsOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "scroll";
        }
    }, [pdfModalIsOpen]);
    
    return (
        <Fragment>
            <Option isActive={false} slug={shortcut}>
                <OptionHeader onClick={toggleModal} >
                        <OptionName slug={shortcut}>
                            { data.categoryLabel }
                        </OptionName>
                </OptionHeader>
            </Option>
            {pdfModalIsOpen && (
            <DisplayPdf
                open={pdfModalIsOpen}
                file={data.categoryDescription}
                onClose={() => setPdfModalIsOpen(false)}
                toggle={toggleModal}
                footer={<div>{host.name}</div>}
                title={data.categoryLabel}
            />
            )}
        </Fragment>
    );
}

export const Opt = ({ personalisate, data, cart, roomDisplayed, roomTypes, isInCart, isFromStartOfStay, isFromEndOfStay, compareAllDate, isRoomInCart, deleteProductsRoom, deleteCartElem, changeRoom, updateCartElem, valueArrival, valueDeparture, reset, shortcut }) => {
    const [isActive, setIsActive] = useState(false);
    const [typeActive, setTypeActive] = useState(false);
    const [indexTypeSelected, setIndexTypeSelected] = useState(0);
    const [typeSelected, setTypeSelected] = useState("");
    const [change, setChange] = useState(false);

    const onTypeChange = (type, i) => { // delete rooms product on types change
        deleteProductsRoom(roomDisplayed);
        changeRoom(type, roomDisplayed)
        setIndexTypeSelected(i)
        setTypeSelected(type);
        setTypeActive(!typeActive)
        setChange(!change)
    }
    useEffect(() => { // when rooms type is selected => display good option
        if (typeSelected === "" && data.type === "room") {
            const isIn = isRoomInCart(roomDisplayed);
            setTypeSelected(isIn)

            roomTypes && roomTypes.map((room, i) => {
                if (room.categoryLabel === isRoomInCart(roomDisplayed))
                    setIndexTypeSelected(i);
            })
            setTypeActive(false)
        }
    }, [typeSelected])

    useEffect(() => {
        setChange(!change)
    }, [reset])

    let subcategoriesToDisplay = 0

    // setting the number of subcategoriesToDisplay to determine if a category title have to be displayed
    const setSubcategoriesToDisplay = () => {
        data.subcategories.map(element => {
            element.services.map(elt => {
                if (elt.showDuringStay === true) {
                    if (isFromEndOfStay(elt.deliveryTimeLimit)) {
                        subcategoriesToDisplay = subcategoriesToDisplay + 1
                    }
                }
                if (elt.showDuringStay === false) {
                    if (isFromStartOfStay(elt.deliveryTimeLimit)) {
                        subcategoriesToDisplay = subcategoriesToDisplay + 1
                    }
                }
                return null;
            })
        });
    }
    setSubcategoriesToDisplay()


    if (personalisate === false) {
        return (
            <Option isActive={isActive} personalisate={personalisate} slug={shortcut}>
                <OptionHeader onClick={() => { setIsActive(!isActive) }}>
                    
                        <OptionName slug={shortcut}>
                            {data.type === "room" ? ("Votre chambre "/* + roomDisplayed*/) : data.categoryLabel}
                        </OptionName>
                        <Arrow>
                            <ArrowImg slug={shortcut} src={isActive ? shortcut === 'secret-de-paris' || shortcut === 'parisjadore' ?  'Icones/chevron-up.svg': "Icones/Fleche black haut.png" : shortcut === 'secret-de-paris' || shortcut === 'parisjadore' ? 'Icones/chevron-down.svg' : " Icones/Arrow - black.png"}></ArrowImg>
                        </Arrow>
                </OptionHeader>
                {data.type === "room" && isActive ?
                    (
                        <Fragment>
                            <TypeSelection>
                                <SelectionTitle>
                                    Selectionnez votre type de chambre
                                </SelectionTitle>
                                <FormSelection onClick={() => { setTypeActive(!typeActive) }}>
                                    <RoomSelected style={{ marginLeft: (typeSelected ? "30px" : "5px") }}>
                                        {typeSelected ? (!typeActive ? (<div style={{ color: "#16a5a3" }}>{typeSelected}</div>) : null) : (!typeActive ? "Type de chambre réservé" : null)}
                                        {typeActive ? (
                                            <RoomMenu>
                                                <ArrowRoom style={{ marginLeft: "89%", marginTop: "7px" }}>
                                                    <ArrowImgRoom src={typeActive ? "Icones/Fleche black haut.png" : " Icones/Arrow - black.png"} />
                                                </ArrowRoom>
                                                {
                                                    roomTypes.map((room, i) => {
                                                        return (
                                                            <Room onClick={() => { onTypeChange(room.categoryLabel, i) }}>
                                                                {room.categoryLabel}
                                                            </Room>
                                                        )
                                                    })
                                                }
                                            </RoomMenu>
                                        ) : null
                                        }
                                    </RoomSelected>
                                    {typeActive ? null :
                                        <ArrowRoom onClick={() => { setTypeActive(!typeActive) }}>
                                            <ArrowImgRoom src={typeActive ? "/Icones/Fleche black haut.png" : " Icones/Arrow - black.png"} />
                                        </ArrowRoom>
                                    }
                                </FormSelection>
                            </TypeSelection>
                            {(data.type === "room" && !typeSelected) ?
                                <SelectInfo>Pensez à sélectionner votre type de chambre pour réserver vos prestations !</SelectInfo>
                                : null}
                        </Fragment>
                    )
                    : null
                }
                    { data.type !== "room" ?
                        <CategoryDescription>
                            <CategoryDescriptionText slug={shortcut} isActive={isActive}>
                                <CategoryDescriptionTextConcat isActive={isActive}>
                                    { (data.type !== "room") ? data.categoryDescription : (typeSelected === '' ? null : roomTypes[indexTypeSelected].categoryDescription) }
                                </CategoryDescriptionTextConcat>
                            </CategoryDescriptionText>
                        </CategoryDescription> : null
                    }
                {isActive ? <Subcategories>
                    {
                        (data.type === "room" && !typeSelected) || data.type !== 'room' ?
                            data.subcategories.map((subItem) => {
                                if (compareAllDate(subItem) === false) {

                                    return null
                                }
                                return (
                                    <Subcategory>
                                        <SubcategoryName slug={shortcut}>
                                            {
                                                subItem.subcategoryLabel
                                            }
                                        </SubcategoryName>
                                        <SubcategoryOption>
                                            {
                                                subItem.services.map(service => {
                                                    if (service.showDuringStay === true) {
                                                        if (isFromEndOfStay(service.deliveryTimeLimit)) {
                                                            return (
                                                                <OptionSelection shortcut={shortcut} isInCart={isInCart} cart={cart} subData={service} name={data.categoryLabel} typeSelected={data.type === "room" ? "" : "other   "} roomNumber={data.type === "room" ? roomDisplayed : 0} personalisate={personalisate} updateCartElem={updateCartElem} deleteCartElem={deleteCartElem} valueArrival={valueArrival} valueDeparture={valueDeparture} change={change} />
                                                            )
                                                        }
                                                    }
                                                    if (service.showDuringStay === false) {
                                                        if (isFromStartOfStay(service.deliveryTimeLimit)) {
                                                            return (
                                                                <OptionSelection shortcut={shortcut} isInCart={isInCart} cart={cart} subData={service} name={data.categoryLabel} typeSelected={data.type === "room" ? "" : "other   "} roomNumber={data.type === "room" ? roomDisplayed : 0} personalisate={personalisate} updateCartElem={updateCartElem} deleteCartElem={deleteCartElem} valueArrival={valueArrival} valueDeparture={valueDeparture} change={change} />
                                                            )
                                                        }
                                                    }
                                                    return null;

                                                })
                                            }
                                        </SubcategoryOption>
                                    </Subcategory>
                                )
                            }
                            ) : (roomTypes[indexTypeSelected].subcategories.map((subItem) => {
                                if (compareAllDate(subItem) === false)
                                    return null
                                return (
                                    <Fragment>
                                        <Subcategory>
                                            <SubcategoryName>
                                                {
                                                    subItem.subcategoryLabel
                                                }
                                            </SubcategoryName>
                                            <SubcategoryOption>
                                                {
                                                    subItem.services.map(service => {
                                                        if (service.showDuringStay === true) {
                                                            if (isFromEndOfStay(service.deliveryTimeLimit)) {
                                                                return (
                                                                    <OptionSelection shortcut={shortcut} isInCart={isInCart} subData={service} name={roomTypes[indexTypeSelected].categoryLabel} typeSelected="typeSelected" roomNumber={roomDisplayed} personalisate={personalisate} updateCartElem={updateCartElem} deleteCartElem={deleteCartElem} valueArrival={valueArrival} valueDeparture={valueDeparture} />
                                                                )
                                                            }
                                                        }
                                                        if (service.showDuringStay === false) {
                                                            if (isFromStartOfStay(service.deliveryTimeLimit)) {
                                                                return (
                                                                    <OptionSelection shortcut={shortcut} isInCart={isInCart} subData={service} name={roomTypes[indexTypeSelected].categoryLabel} typeSelected="typeSelected" roomNumber={roomDisplayed} personalisate={personalisate} updateCartElem={updateCartElem} deleteCartElem={deleteCartElem} valueArrival={valueArrival} valueDeparture={valueDeparture} />
                                                                )
                                                            }
                                                        }
                                                        return null;
                                                    })
                                                }
                                            </SubcategoryOption>
                                        </Subcategory>
                                    </Fragment>
                                )
                            }))
                    }
                </Subcategories> : null}
            </Option>
        );
    }
    else {
        if (subcategoriesToDisplay > 0) {
            return (
                <Option isActive={isActive} personalisate={personalisate}>
                    <OptionHeader onClick={() => { setIsActive(!isActive) }}>
                        <OptionName slug={shortcut}>
                            {data.type === "room" ? ("Votre chambre "/* + roomDisplayed*/) : data.categoryLabel}
                        </OptionName>
                        <Arrow>

                            <ArrowImg src={isActive ? "Icones/Fleche black haut.png" : " Icones/Arrow - black.png"}></ArrowImg>
                        </Arrow>
                    </OptionHeader>
                    {
                        data.type === "room" && isActive ?
                            (
                                <Fragment>
                                    <TypeSelection>
                                        <SelectionTitle>
                                            Selectionnez votre type de chambre
                                        </SelectionTitle>
                                        <FormSelection onClick={() => { setTypeActive(!typeActive) }}>
                                            <RoomSelected style={{ marginLeft: (typeSelected ? "30px" : "5px") }}>
                                                {typeSelected ? (!typeActive ? (<div style={{ color: "#16a5a3" }}>{typeSelected}</div>) : null) : (!typeActive ? "Type de chambre réservé" : null)}
                                                {typeActive ? (
                                                    <RoomMenu>
                                                        <ArrowRoom style={{ marginLeft: "89%", marginTop: "7px" }}>
                                                            <ArrowImgRoom src={typeActive ? "Icones/Fleche black haut.png" : " Icones/Arrow - black.png"} />
                                                        </ArrowRoom>
                                                        {
                                                            roomTypes.map((room, i) => {
                                                                return (
                                                                    <Room onClick={() => { onTypeChange(room.categoryLabel, i) }}>
                                                                        {room.categoryLabel}
                                                                    </Room>
                                                                )
                                                            })
                                                        }
                                                    </RoomMenu>
                                                ) : null
                                                }
                                            </RoomSelected>
                                            {typeActive ? null :
                                                <ArrowRoom onClick={() => { setTypeActive(!typeActive) }}>
                                                    <ArrowImgRoom src={typeActive ? "/Icones/Fleche black haut.png" : " Icones/Arrow - black.png"} />
                                                </ArrowRoom>
                                            }
                                        </FormSelection>
                                    </TypeSelection>
                                    {(data.type === "room" && !typeSelected) ? <SelectInfo>Pensez à sélectionner votre type de chambre pour réserver vos prestations !</SelectInfo> : null}
                                </Fragment>
                            )
                            : null
                    }
                    {(data.type !== "room") ? 
                        <CategoryDescription slug={shortcut}>
                            <CategoryDescriptionText slug={shortcut} isActive={isActive}>
                                <CategoryDescriptionTextConcat slug={shortcut} isActive={isActive}>
                                    { (data.type !== "room") ? data.categoryDescription : (typeSelected === '' ? null : roomTypes[indexTypeSelected].categoryDescription) }
                                </CategoryDescriptionTextConcat>
                            </CategoryDescriptionText>
                        </CategoryDescription> : null
                    }
                    {isActive ? <Subcategories>
                        {
                            (data.type === "room" && !typeSelected) || data.type !== 'room' ?
                                data.subcategories.map((subItem) => {
                                    if (compareAllDate(subItem) === false) {
                                        return null
                                    }
                                    return (
                                        <Subcategory>
                                            <SubcategoryName>
                                                {
                                                    subItem.subcategoryLabel
                                                }
                                            </SubcategoryName>
                                            <SubcategoryOption>
                                                {
                                                    subItem.services.map(service => {
                                                        if (service.showDuringStay === true) {
                                                            if (isFromEndOfStay(service.deliveryTimeLimit)) {
                                                                return (
                                                                    <OptionSelection shortcut={shortcut} isInCart={isInCart} subData={service} name={data.categoryLabel} typeSelected={data.type === "room" ? "" : "other   "} roomNumber={data.type === "room" ? roomDisplayed : 0} personalisate={personalisate} updateCartElem={updateCartElem} deleteCartElem={deleteCartElem} valueArrival={valueArrival} valueDeparture={valueDeparture} />

                                                                )
                                                            }
                                                        }
                                                        if (service.showDuringStay === false) {
                                                            if (isFromStartOfStay(service.deliveryTimeLimit)) {
                                                                return (
                                                                    <OptionSelection shortcut={shortcut} isInCart={isInCart} subData={service} name={data.categoryLabel} typeSelected={data.type === "room" ? "" : "other   "} roomNumber={data.type === "room" ? roomDisplayed : 0} personalisate={personalisate} updateCartElem={updateCartElem} deleteCartElem={deleteCartElem} valueArrival={valueArrival} valueDeparture={valueDeparture} />
                                                                )
                                                            }
                                                        }
                                                        return null;

                                                    })
                                                }
                                            </SubcategoryOption>
                                        </Subcategory>
                                    )
                                }
                                ) 
                            :
                                (roomTypes[indexTypeSelected].subcategories.map((subItem) => {
                                    if (compareAllDate(subItem) === false)
                                        return null
                                    return (
                                        <Fragment>
                                            <Subcategory>
                                                <SubcategoryName>
                                                    {
                                                        subItem.subcategoryLabel
                                                    }
                                                </SubcategoryName>
                                                <SubcategoryOption>
                                                    {
                                                        subItem.services.map(service => {
                                                            if (service.showDuringStay === true) {
                                                                if (isFromEndOfStay(service.deliveryTimeLimit)) {
                                                                    return (
                                                                        <OptionSelection shortcut={shortcut} isInCart={isInCart} subData={service} cart={cart} name={roomTypes[indexTypeSelected].categoryLabel} typeSelected="typeSelected" roomNumber={roomDisplayed} personalisate={personalisate} updateCartElem={updateCartElem} deleteCartElem={deleteCartElem} valueArrival={valueArrival} valueDeparture={valueDeparture} change={change} />
                                                                    )
                                                                }
                                                            }
                                                            if (service.showDuringStay === false) {
                                                                if (isFromStartOfStay(service.deliveryTimeLimit)) {
                                                                    return (
                                                                        <OptionSelection shortcut={shortcut} isInCart={isInCart} subData={service} cart={cart} name={roomTypes[indexTypeSelected].categoryLabel} typeSelected="typeSelected" roomNumber={roomDisplayed} personalisate={personalisate} updateCartElem={updateCartElem} deleteCartElem={deleteCartElem} valueArrival={valueArrival} valueDeparture={valueDeparture} change={change} />
                                                                    )
                                                                }
                                                            }
                                                            return null;
                                                        })
                                                    }
                                                </SubcategoryOption>
                                            </Subcategory>
                                        </Fragment>
                                    )
                                }))
                        }
                    </Subcategories> : null}

                </Option>
            )
        }
        else return null
    }
}

const InfoPopover = styled.div`
    display: flex;
    font: italic normal 300 13px Poppins;
    margin: auto;
    width: 90%; 
    color: #959595;
    margin-bottom: 15px; 

    @media screen and (max-device-width:1024px){
        margin-left: 25px;
    }
    @media screen and (max-device-width:554px){
        margin-left: 30px;
        display: grid; 
        width: 100%; 
    }

`

const CategoryDescription = styled.div`
    display: flex;
    font: italic normal 300 13px Poppins;
    margin: auto;
    margin-left: 27px;
    width: 95%;

    margin-top: 6px;

    text-align: left;
    letter-spacing: 0px;

    @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
        font-size: 14px;  
    }
    @media screen and (max-device-width:1024px){
        margin-top: 6px;
        font: normal normal 600 18px Poppins;
    }
    @media screen and (max-device-width:554px){
        margin-top: 6px;
        font: normal normal 600 14px Poppins;
        margin-left: 10px;
    }



`

const CategoryDescriptionText = styled.div`
    position: relative;
    color: ${props => props.slug === "secret-de-paris" || props.slug === 'parisjadore' ? "white" : "black"};
    font: italic normal 300 13px Poppins;
    width: 85%; 

    @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
        font-size: 11px;   
    }
`

const CategoryDescriptionTextConcat = styled.span`
    position: relative;
    display: inline-block;
    overflow: hidden;
    max-height: ${props => props.isActive ? "24.0em" : "1.2em"}; /* (Number of lines you want visible) * (line-height) */
    line-height: 1.2em;
    text-align:justify;
    text-overflow: ellipsis;
    width: 100%; 
    height: ${props => props.isActive ? "100%" : "1.2em"};
    white-space: ${props => props.isActive ? "break-spaces" : "nowrap"};
`

const InfoProductPhoto = styled.figure`
    width: 198px;
    height: 103px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0px; 
}

`
const InfoProductDescription = styled.p`
width: 70%;
margin: 0px; 
padding: 0px 24px;
color: ${(props) => (props.slug === "secret-de-paris" || props.slug === 'parisjadore' ? "white" : "black")}; 

@media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
    font-size: 12px;  
}
@media screen and (max-device-width: 554px){
    width: 90%;
    padding : 8px 0px;
    font: normal normal 400 11px Poppins;
}
`
const ShowInfo = styled.img`
    display: flex;
    width:  16px;
    height: 16px;
    margin-left: 12px;
    :hover {
        border: 1px solid grey;
    }
    :focus {
        outline: 0 !important;
    }
`
const TypeContainer = styled.div`
    display: flex;
    width: 200px;
    height: 147px;
    margin-top: 170px;
    margin-left: -57px;
    background-color: white;
    z-index: 12;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #1F2224;
    border-radius: 8px;
    @media screen and (max-device-width:1024px){
        margin-left: -69px;
        margin-top: 165px;
    }
    @media (max-width:767px) and (min-width:300px) {
        position: absolute;
        margin-top: 0px;
        top: 12px;
        left: 70px;
    }
`
const QuantityContainer = styled.div`
    display: flex;
    width: 58px;
    height: 147px;
    margin-top: 170px;
    margin-left: -57px;
    justify-content: center;
    background-color: white;
    z-index: 12;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #1F2224;
    border-radius: 8px;
    align-items: center;
    @media screen and (max-device-width:1024px){
        margin-left: -69px;
        margin-top: 165px;
    }
    @media (max-width:767px) and (min-width:554px) {
        position: relative;
        margin-top: 0px;
        top: 75px;
        left: 70px;
}
    }
`
const Quantity = styled.button`
    display: flex;
    color: black;
    width: 20px;
    justify-content: center;
    background-color: transparent;
    border: none;
    margin-top: 5px;
    :hover {
        background-color: lightgray;
    }
`

const Type = styled.button`
    display: flex;
    color: black;
    background-color: transparent;
    border: none;
    margin-top: 10px;
    :hover {
        background-color: lightgray;
    }
`
const TypeSelect = styled.div`
    width: 98%;
    height: 95%;

    z-index: 15;
    background-color: white;
    overflow-y: scroll;
    margin-left: 10px;
    ::-webkit-scrollbar {
        width: 5px;
        height: 8px;
        background-color:#F4F4F4; /* or add it to the track */
        border-radius: 10px;
        height: 50%;
    }
    ::-webkit-scrollbar-thumb {
        background:#1E2224;
        border-radius: 8px;
    }
`
const QuantitySelect = styled.div`
    width: 40px;
    height: 95%;

    z-index: 15;
    background-color: white;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        width: 5px;
        height: 8px;
        background-color:#F4F4F4; /* or add it to the track */
        border-radius: 10px;
        height: 50%;
    }
    ::-webkit-scrollbar-thumb {
        background:#1E2224;
        border-radius: 8px;
    }
`
const QuantityArrowImg = styled.img`
    width: 7.01px;
    height: 4.01px;
    @media screen and (max-device-width:480px){
        width: 8.18px;
        height: 4.39px;
    }
    
`
const QuantityArrow = styled.button`
    display: flex;
    width: 7.01px;
    height: 4.01px;
    margin-left: 3px;
    border: none;
    justify-content: center;
    background-color: transparent;

    @media (max-width:767px) and (min-width:300px) {
        margin-left: 5px;
        margin-right: 10px;
        padding-right: 20px;
        width: 11.18px;
        height: 6.39px;
    }
`


const QuantityBox = styled.div`
    display: flex;
    // position: relative;
    height: 10px;
    width: 200px;
    background-color: white;
    color: #16a5a3;
    font: normal normal 300 12px/26px Poppins;
    align-items: center;
    margin-left: 15px;
    align-items: center;
    margin-bottom: 15px;

    @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
        font-size: 14px;  
    }
    @media screen and (max-device-width:1024px){
        margin-left: 25px;
    }

    
`
const SelectionTitle = styled.div`
    display: flex;
    font: normal normal normal 16px Poppins;
    margin-left: 15px;
    color: #16a5a3;

    @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
        font-size: 14px;  
    }
    @media screen and (max-device-width: 554px){
        flex-direction: column;
        font: normal normal normal 12px Poppins;
        margin-left: 5px;
    }

    
`

const Room = styled.button`
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
    color: #16a5a3;
    border: none;
    background-color: transparent;
    font-weight: normal;
    margin: auto; 
    white-space:nowrap;
    text-align: left; 
    :hover {
        font-weight: bold;
    }
    @media screen and (max-device-width:480px){
       white-space: normal; 
    }
`
const RoomMenu = styled.div`
    display: flex;
    width: fit-content;
    top: 81px;
    right: -6px;
    position: relative; 
    background-color: white;
    border: 1px solid #16a5a3;
    border-radius: 12px;
    justify-content: space-between;
    flex-direction: column;
    color:black;
    border: 1px solid #16a5a3;
    border-radius: 12px;
    padding: 5px; 
    margin-left: auto;

    @media screen and (max-device-width:480px){
        margin-left: -18px;
        margin-top: -18px;
    }

`

const FormSelection = styled.div`
    display: flex;
    width: fit-content;
    margin-right: 10px;
    height: 23px;
    border: 1px solid #16a5a3;
    border-radius: 12px;
    background-color: white;
    align-items: center;
    justify-content: space-between;
    z-index: 11;
    padding: 5px; 
    :hover {
        cursor: pointer;
    }
    @media screen and (max-device-width:480px){
        margin: 5px;
    }


`

const RoomSelected = styled.div`
    color: #16a5a3;
    font: normal normal 300 12px Poppins;
    alignItems: center;
    width: 100%;

    @media screen and (max-device-width: 554px){
        font: normal normal 300 10px Poppins;
        
    }

`

const TypeSelection = styled.div`
    display: flex;
    width: 94.5%;
    height: 44.56px;
    margin-top: 45.11px;
    background: #16a5a31f 9% 0% no-repeat padding-box;
    border: none; 
    border-radius: 10px;
    margin-left: 27px;
    justify-content: space-between;
    align-items: center;
    @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
        margin-top: 25px;
    }
    @media screen and (max-device-width:1024px){
        width: 90%;
        margin-left: 0px;
    }
    @media screen and (max-device-width:554px){
        flex-direction: column;
        width: 100%;
        font: normal normal medium 14px/25px Poppins;
        margin-left: 0px;
        height: 55px;
        margin-top: 20px;
    }

    @media screen and (max-device-width: 480px){
        padding: 5px;
        width: 100%;
    }
`

const LabelPick = styled.button`
    display: ${(props) => (props.onlyRoomDirectory ? "none" : "flex")};

    width: 15px;
    height: 15px;
    margin-right: 10px;
    background: transparent;
    justify-content: center;
    align-items: center;
    border: 1px solid lightgray;
    :hover {
        background: lightgray;
    }
    @media (max-width:767px) and (min-width:300px) {
        padding: 0;
        max-width: 15px;
        max-height: 15px;
        width: 100%;
    }
`

const Label = styled.div`
    display: flex;

    font: normal normal 300 16px Poppins;
    color: ${props => props.slug === 'secret-de-paris' || props.slug === 'parisjadore' ? "white" : "black"};
    align-items: center;
    margin-left: 15px;
    margin-bottom: 15px; 
    max-width: 400px;
    
    @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
        font-size: 12px;  
    }
    @media screen and (max-device-width:1024px){
        font-size: 14px;
        margin-left: 0px;
    }
    @media screen and (max-device-width:554px){
        font-size: 12px;
        margin-left: 0px;
        width: 70%
    }

`
const Price = styled.div`
    display: flex;
    font: normal normal 300 16px Poppins;
    color: ${props => props.slug === 'secret-de-paris' || props.slug === 'parisjadore' ? "white" : "black"};

    @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
        font-size: 12px;  
    }
    @media screen and (max-device-width:1024px){
        font-size: 14px;
    }
    @media screen and (max-device-width:554px){
        font-size: 12px;
    }
`

const OptionSelect = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    
`

const SubcategoryOption = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`
const SubcategoryName = styled.div`
    width: 100%;
    font: normal normal 200 16px Poppins;
    letter-spacing: 0px;
    margin-bottom: 15px;
    color: ${(props) => (props.slug === 'secret-de-paris' || props.slug === 'parisjadore' ? "white": "black")}; 

    @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
        font-size: 14px;  
    }
    @media screen and (max-device-width:480px){
        font-size: 16px;
    }

`
const Subcategory = styled.div`
    display: block;
    width: 95%;
    padding: 15px; 
    @media screen and (max-device-width:554px){
        flex-direction: column;
        margin-top: 10px;
        width: 100%;
        padding: 5px;
    }
`

const SelectInfo = styled.p`
    color: #DA2864; 
    font: normal italic 400 12px Poppins;
    margin-left: 27px;
    padding: 15px;

    @media screen and (max-device-width:480px){
       margin-left: 0px; 
       padding-bottom: 0px; 
       margin-bottom: 0px;
    }

`
const Subcategories = styled.div`
    display: flex;
    width: 95%;
    flex-direction: column;
    margin-left: 27px;
    color: #16a5a3;
    // background: #16a5a31f 9% 0% no-repeat padding-box;

    font: normal normal medium 16px Poppins;
    padding-bottom: 10px;
    @media screen and (max-device-width:1024px){
        width: 85%;
        margin-left: 0px;
    }
    @media screen and (max-device-width:480px){
        margin-top: 20px;
        width: 95%;
        margin-left: 0;
    }
`
const OptionHeader = styled.div`
    display : flex; 
    width: 100%;
`

const ArrowImg = styled.img`
    width: 20px;
    height: 11px;
    @media screen and (max-device-width:480px){
        width: 11.18px;
        height:  ${props => props.slug === 'secret-de-paris' || props.slug === 'parisjadore' ? "20px" : "6.8px"};
    }
`
const Arrow = styled.button`
    display: flex;
    margin-left: 60%;
    margin-top: 30.61px;
    margin-right: 20px;
    width: 20px;
    height: 11px;
    border: none;
    justify-content: center;
    background-color: transparent;
    @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
        margin-left: 50%;  
    }
    @media screen and (max-device-width:768px){
        margin-left: 50%;
        margin-right: 20px;
        margin-top: 30px;
    }
    @media screen and (max-device-width:480px){
        margin-left: 30%;
        margin-right: 10px;
        padding-right: 20px;
        margin-top: 25px;
        width: 11.18px;
        height: 6.39px;
    }
    
`

const ArrowImgRoom = styled.img`
    width: 7.91px;
    height: 4.52px;
`
const ArrowRoom = styled.button`
    display: flex;
    width: 7.91px;
    height: 4.52px;
    margin-right: 9px;
    border: none;
    justify-content: center;
    background-color: transparent;
`

const OptionName = styled.div`
    display: flex;
    margin-top: 21.5px;
    margin-left: 27px;

    width: 300px;
    color: ${props => props.slug === 'secret-de-paris' || props.slug === 'parisjadore' ? "white" : "black"};


    text-align: left;
    font: normal normal 400 18px Poppins;
    letter-spacing: 0px;
    @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
        font-size: 14px;  
    }
    @media screen and (max-device-width:1024px){
        margin-top: 12px;
        font: normal normal 600 18px Poppins;
    }
    @media screen and (max-device-width:554px){
        margin-top: 12px;
        font: normal normal 600 13px Poppins;
        margin-left: 10px;
    }


`
const Option = styled.div`
    display: flex;
    width: 50vw;
    height: ${props => props.isActive ? "none" : "75px"};
    margin-top: 19px;
    color: ${props => props.personalisate ? "#707070" : "white"};
    border:  ${props => props.slug === 'secret-de-paris' || props.slug === 'parisjadore' ? '1px solid #232323' : '1px solid lightgrey'};
    border-radius: 18px;
    box-shadow: 4px 4px 10px #00000029;
    font-family: 'Poppins, SemiBold';
    font-size: 18px;
    font-weight: 400; 
    flex-direction: column;
    opacity: ${props => props.personalisate ? "1" : "0.7"};
    background-color: ${props => props.personalisate ? "white" : "#EDEDED"};
    background-color: ${props => props.slug === 'secret-de-paris' || props.slug === 'parisjadore' ? "black" : "#EDEDED"};


    @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
        font-size: 14px;  
    }
    @media screen and (max-device-width:1024px){
        width: 90vw;
        height: ${props => props.isActive ? "none" : "56px"};
        align-items: center;
        min-width: 90%;
    }
`