import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import UserProfile from '../UserProfile/UserProfile'
import { RegisterForm } from '../RegisterForm'
import { LoginForm } from '../LoginForm'
import { ShoppingCart } from '../ShoppingCart'
import {
    isMobile
  } from "react-device-detect";

export const TopBar = ({setChange, change, cartBis, deleteCartElem, itemIn, getCartSize, drawerState}) => {
    const [inscriptionOpen, setInscriptionOpen] = useState(false);
    const [loginOpen, setLoginOpen] = useState(false);
    const [displayProfile, setDisplayProfile] = useState(false)
    const [user, setUser] = useState(JSON.parse(UserProfile.getUser()));
    const [show, setShow] = useState(false)
    const [item, setItem] = useState(false);

    const deconnect = () => {
        localStorage.removeItem('user');
        setDisplayProfile(false)
        setChange(!change)
        setUser(null)
    }

    function redirect() {
        var win = window.open(process.env.REACT_APP_MES_SEJOUR_URL + `/?token=${user.token}`, '_blank');
        win.focus();
      }

    useEffect(() => {
        setUser(JSON.parse(UserProfile.getUser()));
        if (!user) {
            if (loginOpen || inscriptionOpen) {
                document.body.style.overflow = "hidden"
            }
            if (!loginOpen && !inscriptionOpen) {
                document.body.style.overflow = "scroll"
            }
        }
    }, [inscriptionOpen, loginOpen, change]);

    useEffect(() => {
        if (show && isMobile && cartBis) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "scroll"
        }
    }, [show, change])

    useEffect(() => {
        if (cartBis && cartBis.items && cartBis.items.length <= 0) {
            setShow(false)
            setItem(false);
        } else {
            setItem(true);
        }
        
    },[cartBis, change])

    useEffect(() => {
        if ((itemIn === false || itemIn === true )&& getCartSize()) {
            setItem(true)
        } else {
            setItem(false);
        }
    }, [itemIn, cartBis, drawerState])

    return (
    <TopBarContainer key={change}>
        <TopBarContent>
            <LogoFlexibles src="/Icones/GETWELCOM NOIR.png"/>
            <Container>
            <UserContainer onClick={() => setDisplayProfile(!displayProfile)}>
                <ProfileIcone src="/Icones/avatar-perso.c9034bb5.png"/>
                <UserName>
                    { user ? 'Hi, ' + user.firstName : null}
                </UserName>
                {displayProfile && user ?
                <Profile>
                    <DeleteButton onClick={() => {setDisplayProfile(false)}}> X </DeleteButton>
                    <Name>{ user.firstName}</Name>
                    <Button onClick={redirect}> Mes Séjours </Button>
                    <Button onClick={deconnect}> Se déconnecter </Button>
                </Profile> : displayProfile ?
                <Profile>
                        <DeleteButton onClick={() => {setDisplayProfile(false)}}> X </DeleteButton>
                        <div style={{display: "flex", justifyContent: "center", width: "100%", flexDirection: "column", alignItems: "center"}}>
                        <Button onClick={setLoginOpen} cart={null} color="#20A7A4"> Me connecter </Button>
                        <Button onClick={setInscriptionOpen} cart={null}> Créer un compte </Button>
                        </div>
                </Profile> : null}
            </UserContainer>
            {isMobile ? <div style={{position: "relative"}}><BasketIcone onClick={() => { setShow(true)}}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 31.492 26.992">
  <path id="Icon_ionic-md-basket" data-name="Icon ionic-md-basket" fill="white" stroke="black" strokeWidth="2px" d="M25.46,14.428,19.188,5.1A1.422,1.422,0,0,0,18,4.5a1.4,1.4,0,0,0-1.188.612L10.54,14.428H3.684a1.433,1.433,0,0,0-1.434,1.42,1.256,1.256,0,0,0,.056.387L5.941,29.419a2.845,2.845,0,0,0,2.749,2.074H27.3a2.875,2.875,0,0,0,2.763-2.074L33.7,16.235l.042-.387a1.433,1.433,0,0,0-1.434-1.42Zm-11.756,0L18,8.17l4.3,6.258ZM18,25.812a2.848,2.848,0,1,1,2.862-2.848A2.861,2.861,0,0,1,18,25.812Z" transform="translate(-2.25 -4.5)"/>
</svg>
</BasketIcone> {item ? <Circle ></Circle> : null} </div> : null}
            </Container>
        </TopBarContent>
        {show && isMobile ? <ShoppingCart cartBis={cartBis} setShow={setShow} deleteCartElem={deleteCartElem} setChange={() => setChange(!change) }/> : null}
        <RegisterForm inscriptionOpen={inscriptionOpen} cartId={null} setInscriptionOpen={setInscriptionOpen} setChange={setChange} change={change}/>
        { loginOpen ? <LoginForm setLoginOpen={setLoginOpen} setChange={setChange} change={change} /> : null}
    </TopBarContainer>
    );
}

const Circle = styled.div`
    position: absolute;
    width: 8px;
    height: 8px;
    left: 22px;
    top: 18px;
    border-radius: 50%;
    z-index: 7;
    background-color: red;
`
const Container = styled.div`
    display: flex;
    width: 150px;
    justify-content: space-between;
    flex-direction: row-reverse;
    @media (max-width:767px) and (min-width:300px) {
        width: 80px;
    }
`

const DeleteButton = styled.div`
    position: absolute;
    width: 15px;
    height: 20px;
    color: black;
    right: 0;
    top: 0;
    :hover {
        cursor: pointer;
    }
`

const Button = styled.div`
    display: flex;

    width: 100%;
    height: 30px;
    color: #16a5a3;
    justify-content: center;
    font: normal normal normal 14px Poppins;
    :hover{
        color: white;
        background-color: #16a5a3;
        cursor: pointer;
    }

`
const Name = styled.div`
    display: flex;
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    font: normal normal normal 14px Poppins;
    color: #16a5a3;
`

const Profile = styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    width: 210px;
    background-color: white;
    margin-left: 0px;
    border: 1px solid #16a5a3;
    border-radius: 10px;
    align-items: center;
    z-index: 8;
`
const ProfileIcone = styled.img`
width: 40px;
height: auto;
margin-left: 10px;
border-radius: 50%;

    @media screen and (max-device-width:554px){
        height: 25px;
        width: auto; 
        margin-top: 5px;
    }
`

const BasketIcone = styled.div`

    width: 20px;
    margin-left: 10px;
    height: 82%;
    margin-top: 2px;
`

const UserName = styled.div`
    display: flex;
    align-items: center;
    font-size: 15px;
    color: black;

    @media screen and (max-device-width:480px){
        display: none;
    }
`

const UserContainer = styled.div`
    display: flex;
    width: 120%;
    flex-direction: row-reverse;
    @media (max-width:767px) and (min-width:300px) {
        width: 30%;
    }

`
const TopBarContent = styled.div`
    display: flex;
    width: 95%;
    justify-content: space-between;
    @media screen and (max-device-width:480px){
        width: 90vw;
    }
`

const LogoFlexibles = styled.img`
    width: 115px;
    height: 100%;
    margin-top: auto; 

    @media screen and (max-device-width: 554px){
        width: 75px; 
    }
`

const TopBarContainer = styled.div`
    display: flex;
    position: fixed;
    width: 100%;
    height: 70px;
    z-index: 1000;

    justify-content: center;
    align-items: center;
    background-color: #F8F8F8;

    // Medium devices (tablets, 1024px and up)
    @media (min-width: 1024px) {

    }

    @media screen and (max-device-width:480px){
        position: fixed;
        height: 41px;
        width: 100vw;
    }
`;