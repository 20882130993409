import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Sentry
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import 'bootstrap/dist/css/bootstrap.css';

/* Redirection vers le nouveau parcours pour certains clients */
let paragraph = window.location.href;
const regexRayzVendome = /\/lerayzvendome.*?/g;
const regexTierceBeach = /\/tiercebeachhotel.*?/g;
const found = paragraph.match(regexRayzVendome) || paragraph.match(regexTierceBeach);
if (found !== null && found.length > 0) {
	paragraph = paragraph.replace('secure', 'securev2');
  window.location.replace(paragraph);
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_SENTRY_ENV,
  
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


// ReactDOM.render(<App/>, document.querySelector('.demo-carousel'));
