import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import UserProfile from '../UserProfile/UserProfile';
import axios from 'axios';
import {TopBar} from '../TopBar'
import history from '../history'
import moment from 'moment';
import {useParams} from 'react-router-dom'

moment.locale("fr")

export const Success = () => {
    const [cart, setCart] = useState();
    const [hostName, setHostName] = useState("");
    const [user, setUser] = useState(JSON.parse(UserProfile.getUser()));
    const [arrival, setArrival] = useState("")
    const [departure, setDeparture] = useState("");
    const [category, setCategory] = useState([]);
    const [sortCart, setSortCart] = useState();
    const {shortcut} = useParams();

    const goBack = () => {
        history.push(`/${shortcut}`);
        localStorage.removeItem("lastOrder")
        return
    }
    useEffect(() => {
        if (user) {
        const config = {
            method: 'GET',
            url:  process.env.REACT_APP_API_URL + `/customerorder?order=${UserProfile.getLastOrder()}`,
            headers: {
                'Authorization': 'Bearer ' + user.token,
            },
        }
        axios(config).then(responsePut => {
                setCart(responsePut.data.data[0]);
                setArrival(getDate(responsePut.data.data[0].beginDateOfStay))
                setDeparture(getDate(responsePut.data.data[0].endDateOfStay))
            });
        }
    }, [user]);

    const getLabel = (service) => {
        if (service.selectedItem && service.selectedItem.label )
            return (service.selectedItem.label + (service.quantity > 1 ? ` (x${service.quantity})`: ""))
        else return (service.product.label + (service.quantity > 1 ? ` (x${service.quantity})`: ""));
    }
    useEffect(() => {
        if (cart) {
            var xmlHttp = new XMLHttpRequest();
            xmlHttp.open( "GET", process.env.REACT_APP_API_URL + `/hoster?id=${cart.hoster}`, false );
            xmlHttp.send( null );
            const resp = JSON.parse(xmlHttp.responseText);
            setHostName(resp.name);
        }
    }, [cart])
    const getDate = (date) => {
        const ordered = new Date(date).toLocaleDateString('fr', {
            day: '2-digit',
            month: '2-digit',
          });
        return (ordered)
    }
    const getSlot = (date) => {
        return moment(date).format("DD/MM/YY [-] HH[H]mm")
    }
    const isInCat = (cat, nb) => {
        if (!category)
            return false;
        for (let i = 0; i!==category.length; i++) {
            if (cat === category[i].cat && nb === category[i].number ) {
                return true;
            }
        }
        return false
    }

    useEffect(() => {
        if (!cart || !cart.items)
            return;
        const items = cart.items;
        for (let i = 0; i!==items.length; i++) {
            if (!isInCat(items[i].product.categoryName, items[i].roomNumber)) {
                let tmp = category;
                if (!tmp)
                    tmp = []
                tmp.push({cat: items[i].product.categoryName,number: items[i].roomNumber})
                setCategory(tmp);
            }
        }
    }, [cart])

    useEffect(() => {
        if (category) {
            let tmpcart = [];
            for (let i = 0; i!==category.length; i++) {
                tmpcart.push({"cat": category[i], "data" : cart.items.filter(item => item.product.categoryName === category[i].cat && item.roomNumber === category[i].number)})
            }
            setSortCart(tmpcart);
        }
    }, [category, cart])

    return (
        <div style={{display: "flex", width: "100%", flexDirection: "column", marginBottom: "50px"}}>
        <TopBar user={user}/>
        <Container>
            {<GoBackContainer onClick={goBack}>
                <Logo src="/Icones/Arrow - black.png" />
                <div style={{marginLeft: "5px", font: "normal normal medium 14px/21px Poppins"}}>Passer une nouvelle commande</div>
            </GoBackContainer>}
            <ThanksContainer>
                <Title>Merci pour votre commande !</Title>
                <ThanksContent>
                    <div>Vous allez recevoir un e-mail de confirmation de votre commande.  <br /></div>
                    <div style={{marginTop: "10px"}}>Nous vous souhaitons un agréable séjour au {hostName}.<br /></div>
                    <div style={{marginTop: "10px"}}>Vous avez la possibilité de modifier votre commande directement dans votre espace personnel, rubrique "Mes séjours". <br /></div>
                    <div style={{marginTop: "10px"}}>Veuillez noter que les conditions d'annulation et de modification dépendent de chaque établissement. Pour plus de renseignements, rendez-vous dans votre espace personnel rubrique "Mes séjours" ou écrivez nous à l'adresse contact@getwelcom.com, nous vous répondrons dans les meilleurs délais !
A très vite sur GetWelcom !</div>
                </ThanksContent>
            </ThanksContainer>
            <RecapContainer>
                <Title>Votre commande pour votre séjour au {hostName} <br/> du {arrival} au {departure}</Title>
                <ContainerLabel>
                     <Labels>
                         {
                         sortCart && sortCart.map((servicess) => {

                                                return  servicess.data.map((services, index) => {
                                                    return (
                                                    <LabelContainer>
                                                       <LabelCat> {services.product && services.roomNumber !== 0 && index === 0 ? `Chambre ${services.roomNumber} (${services.product.categoryName})` : null}</LabelCat>
                                                        <LabelCat> {services.product && services.roomNumber === 0 && index === 0 ? services.product.categoryName : null}</LabelCat>
                                                        {   
                                                            <Label>
                                                                <LabelDiv>
                                                                    <div>{getLabel(services)} </div>
                                                                </LabelDiv>
                                                            </Label>
                                                        }
                                                        {services.date ? <Slot>{getSlot(services.date)}</Slot> : null}
                                                    </LabelContainer>
                                                    )}
                                                )
                                            })
                            }
                     </Labels>
                </ContainerLabel>
            </RecapContainer>
        </Container>
        </div>
    )
}


const Slot = styled.div`
    display: flex;
    margin-left: 15px;
    font: normal normal 200 12px Poppins;
    letter-spacing: 0px;
    color: #1E2224;
    opacity: 1;
`

const Logo = styled.img`
    display: flex;
    width: 12px;
height: 7px;
transform: matrix(0, 1, -1, 0, 0, 0);
opacity: 1;
`

const GoBackContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 24.85px;
    width: 50%;
    @media screen and (max-device-width:480px){
        margin-top: 0px;
        width: 90%;
    }
    :hover {
        cursor: pointer;
    }
`

const LabelCat = styled.div`
    display: flex;
    width: 100%;
    font: normal normal medium 14px Poppins;
    letter-spacing: 0px;
    color: #1E2224;
`
const Label = styled.div`
    display: flex;

    width: 100%;
    color: black;
    font: normal normal 300 14px/30px Poppins;
letter-spacing: 0px;
color: #1E2224;

    justify-content: space-between;
`
const LabelDiv = styled.div`
    display: flex;
`

const Labels = styled.div `
    display: flex;
    width: 100%;
    height: fit-content;
    justify-content: center;
    font: normal normal 600 17px/26px Poppins;
    flex-direction: column;
    margin-bottom: 20px;

    @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
        font-size: 14px;   
    }
`

const ContainerLabel = styled.div`
    display: flex;
    width: 90%;
    justify-content: center;
`

const LabelContainer = styled.div`
    display: flex;
    width: 92%;
    flex-direction: column;
    margin-left: 30px;
    margin-top: 10px;
    @media screen and (max-device-width:480px){
        width: 80%;
    }
`
const ThanksContent = styled.div `
    display: flex;
    margin-left: 19px;
    margin-top: 11px;
    font: normal normal 300 14px/21px Poppins;
    letter-spacing: 0px;
    color: #1E2224;
    flex-direction: column;
    @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
        font-size: 12px;   
    }
`
const Container = styled.div`
    display: flex;
    width: 100%;
    min-height: 59vh;
    background-color: #F7F7F7;
    flex-direction: column;
    align-items: center;
    margin-top: 103px;
`

const ThanksContainer = styled.div`
    display: flex;
    width: 50%;
    height: 100%;
    margin-top: 10px;
    background-color: #B3DFE0;
    border-radius: 12px;
    flex-direction: column;
    padding: 10px; 
    @media screen and (max-device-width:480px){
        height: auto;
        width: 90%;
        margin-top: 10px;
    }
`
const Title = styled.div`
    color: #1E2224;
    font: normal normal 600 17px/26px Poppins;
    letter-spacing: 1.53px;
    padding-left: 19px;
    margin-top: 19.23px;
    @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
        font-size: 14px;   
    }
    @media screen and (max-device-width:480px){
        height: auto;
        width: 90%;
        font-size: 14px;
    }
`
const RecapContainer = styled.div`
    display: flex;
    width: 50%;
    margin-top: 10px;
    background-color:#FFFFFF;
    border-radius: 12px;
    flex-direction: column;
    box-shadow: 4px 4px 10px #00000029;
    @media screen and (max-device-width:480px){
        height: auto;
        width: 90%;
    }
`