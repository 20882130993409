import React from "react";
import moment from "moment";
import 'moment/locale/fr';
//import "./calendar.css";
import onClickOutside from "react-onclickoutside";

moment.locale('fr');

class Calendar extends React.Component {
  weekdayshort = ["D", "L", "M", "M", "J", "V", "S"];

  state = {
    selectedDayBis: null,
    showCalendarTable: true,
    showMonthTable: false,
    dateObject: moment(),
    allmonths: moment.months(),
    showYearNav: false,
    departureDay: null,
    arrivalDay: null,
    Selected: false,
    HoverDeparture: null,
    HoverArrival: null
  };
  handleClickOutside = evt => {
    this.props.setDatePickerOpen(false)
  };

  firstDayOfMonth = () => {
    let dateObject = this.state.dateObject;
    let firstDay = moment(dateObject)
                 .startOf("month")
                 .format("d");
   return firstDay;
  };

  firstDayOfMonth2 = () => {
    let dateObject = moment(this.state.dateObject).add(1, "month");
    let firstDay = moment(dateObject)
                 .startOf("month")
                 .format("d");
   return firstDay;
  };

  daysInMonth = () => {
    return this.state.dateObject.daysInMonth();
  };

  daysInMonth2 = () => {
    let dateObject = moment(this.state.dateObject).add(1, "month");
    return dateObject.daysInMonth();
  };

  month = () => {
    return this.state.dateObject.format("MMMM");
  };

  nextmonth = () => {
    let dateObject = moment(this.state.dateObject).add(1, "month");
    return dateObject.format("MMMM");
  }

  year = () => {
    return this.state.dateObject.format("Y");
  };

  currentDay = () => {
    return this.state.dateObject.format("D");
  };

  setMonth = month => {
    let monthNo = this.state.allmonths.indexOf(month);
    let dateObject = Object.assign({}, this.state.dateObject);
    dateObject = moment(dateObject).set("month", monthNo);
    this.setState({
      dateObject: dateObject,
      showMonthTable: !this.state.showMonthTable,
      showCalendarTable: !this.state.showCalendarTable
    });
  };

  onPrev = () => {
    let curr = "";
    if (this.state.showMonthTable === true) {
      curr = "year";
    } else {
      curr = "month";
    }
    this.setState({
      dateObject: this.state.dateObject.subtract(1, curr)
    });
  };
  onNext = () => {
    let curr = "";
    if (this.state.showMonthTable === true) {
      curr = "year";
    } else {
      curr = "month";
    }
    this.setState({
      dateObject: this.state.dateObject.add(1, curr)
    });
  };
  setYear = year => {
    // alert(year)
    let dateObject = Object.assign({}, this.state.dateObject);
    dateObject = moment(dateObject).set("year", year);
    this.setState({
      dateObject: dateObject,
      showMonthTable: !this.state.showMonthTable,
      showYearNav: !this.state.showYearNav
    });
  };

  onYearChange = e => {
    this.setYear(e.target.value);
  };

  onDayClick = (e) => {


    if (this.state.HoverArrival && this.state.HoverDeparture) {
      this.props.setArrival({"date": moment(this.state.HoverArrival).utc().toISOString(), "format": moment(this.state.HoverArrival).subtract(1, "day").format("DD/MM/YY")})
      this.props.setDeparture({"date": moment(this.state.HoverDeparture).utc().toISOString(), "format": moment(this.state.HoverDeparture).subtract(1, "day").format("DD/MM/YY")})
    }
   
    if (moment(this.state.arrivalDay).isAfter(e.target.id))
      this.setState({departureDay: this.state.arrivalDay, arrivalDay: e.target.id});
    else {
      if (this.state.arrivalDay === null)
        this.setState({arrivalDay: e.target.id, HoverArrival: e.target.id});
      else {
        this.setState(
          {
            departureDay: e.target.id
          },
          () => {
            if (this.state.departureDay!==null && this.state.arrivalDay!==null)
              this.setState({Selected: true});
            this.props.setArrival({"date": moment(this.state.arrivalDay).utc().subtract(1, "day").toISOString(), "format": moment(this.state.arrivalDay).subtract(1, "day").format("DD/MM/YY")})
            if (this.state.departureDay) {
              this.props.setArrival({"date": moment(this.state.arrivalDay).utc().subtract(1, "day").toISOString(), "format": moment(this.state.arrivalDay).subtract(1, "day").format("DD/MM/YY")})
              this.props.setDeparture({"date": moment(this.state.departureDay).utc().subtract(1, "day").toISOString(), "format": moment(this.state.departureDay).subtract(1, "day").format("DD/MM/YY")})
            }
            
          }
        );
      }
    }
  };

  OnHover = (e) => {
    if (this.state.arrivalDay && !this.state.departureDay) {
      if (moment(this.state.arrivalDay).isAfter(e.target.id)) {
        this.setState({HoverDeparture: this.state.arrivalDay, HoverArrival: e.target.id});
      } else
        this.setState({HoverDeparture: e.target.id, HoverArrival: this.state.arrivalDay});
    }
  }

  render() {
    if (this.state.arrivalDay === null && !this.state.departureDay === null)
      this.setState({arrivalDay: this.props.valueArrival, departureDay: this.props.valueDepature});
    let weekdayshortname = this.weekdayshort.map(day => {
      return (
        <th key={day} className="week-day">
         {day}
        </th>
      );
    });
    let blanks = [];
    let blanks2 = [];
    for (let i = 0; i < this.firstDayOfMonth(); i++) {
      blanks.push(
        <td className="calendar-day empty">{""}</td>
      );
    }
    for (let i = 0; i < this.firstDayOfMonth2(); i++) {
      blanks2.push(
        <td className="calendar-day empty">{""}</td>
      );
    }
    let daysInMonth = [];
    let daysInMonth2 = [];
    let inbetween = false;
    for (let d = 1; d <= this.daysInMonth(); d++) {
      let date = moment(this.state.dateObject).startOf("month").add(d, "day").toISOString();

      if(this.state.departureDay===null && this.state.HoverArrival===null){
        if(moment(date).format("DD/MM/YY") === moment().add(1, "day").format("DD/MM/YY") ){
          daysInMonth.push(
            <td key={d} className={`calendar-day`} id="today">
              <div id={`${date}`}
                onClick={this.onDayClick}
              >
                {d}
              </div>
            </td>
          );
        }
        // else if (moment().isAfter(date)) {
        //   daysInMonth.push(
        //     <td key={d} className={`calendar-day PastDay`}>
        //       <div id={`${date}`} onClick={this.onDayClick} >
        //         {d}
        //       </div>
        //     </td>
        //   );
        // }
        else {
          daysInMonth.push(
            <td key={d} className={`calendar-day`}>
              <div id={`${date}`}
                onClick={this.onDayClick} onMouseOver={(e) => this.OnHover(e)}
              >
                {d}
              </div>
            </td>
          );
        }
      }
      
      else if (this.state.HoverArrival!==null && (this.state.departureDay!==null || this.state.HoverDeparture!==null)) {

        if (date === this.state.HoverArrival) {
          inbetween = true;
          daysInMonth.push(
            <td key={d} className={`calendar-day selectedF`}>
              <div id={`${date}`}
                onClick={this.onDayClick}
              >
                {d}
              </div>
            </td>
          );
        }
        
        else if (((date === this.state.departureDay) || (!this.state.departureDay && date === this.state.HoverDeparture && this.state.HoverDeparture !== this.state.HoverArrival)) && (this.state.departureDay !== this.state.HoverArrival || this.state.HoverDeparture !== this.state.HoverArrival)) {
          inbetween = false;
          daysInMonth.push(
            <td key={d} className={`calendar-day selectedS`}>
              <div id={`${date}`}
                onClick={this.onDayClick}
              >
                {d}
              </div>
            </td>
          );
        }
         else if (date === this.state.departureDay && date === this.state.HoverArrival) {
          daysInMonth.push(
            <td key={d} className={`calendar-day selected`}>
              <div id={`${date}`}
                onClick={this.onDayClick}
              >
                {d}
              </div>
            </td>
          );
        } else {
          // if (inbetween === true && (this.state.departureDay !== this.state.HoverArrival)) {
            if (inbetween === true) {
            daysInMonth.push(
              <td key={d} className={`calendar-day between`}>
                <div id={`${date}`}
                  onClick={this.onDayClick} onMouseOver={(e) => this.OnHover(e)}
                >
                  {d}
                </div>
              </td>
            );
          } 
          else {
            
          //   if (moment().isAfter(date)) {
          //     daysInMonth.push(
          //       <td key={d} className={`calendar-day PastDay`}>
          //         <div id={`${date}`}>
          //           {d}
          //         </div>
          //       </td>
          //     );
          //   }
           
            // else {
              daysInMonth.push(
                <td key={d} className={`calendar-day`}>
                  <div id={`${date}`}
                    onClick={this.onDayClick} onMouseOver={(e) => this.OnHover(e)}
                  >
                    {d}
                  </div>
                </td>
              );
            // }
          }
        }
      } else {
        if (this.state.HoverArrival!==null && this.state.departureDay===null) {
          if (date === this.state.HoverArrival) {
            daysInMonth.push(
              <td key={d} className={`calendar-day selected`}>
                <div id={`${date}`}
                  onClick={this.onDayClick} onMouseOver={(e) => this.OnHover(e)}
                >
                  {d}
                </div>
              </td>
            );
          } else {
           
              daysInMonth.push(
                <td key={d} className={`calendar-day`}>
                  <div id={`${date}`}
                    onClick={this.onDayClick} onMouseOver={(e) => this.OnHover(e)}
                  >
                    {d}
                  </div>
                </td>
              );
            
          }
        } else {
         
            daysInMonth.push(
              <td key={d} className={`calendar-day`}>
                <div id={`${date}`}
                  onClick={this.onDayClick} onMouseOver={(e) => this.OnHover(e)}
                >
                  {d}
                </div>
              </td>
            );
          
        }
      }
    }
    for (let d = 1; d <= this.daysInMonth2(); d++) {
      let date = moment(this.state.dateObject).add(1, "month").startOf("month").add(d, "day").toISOString();
      
      if(this.state.departureDay===null && this.state.HoverArrival===null){
        if(moment(date).format("DD/MM/YY") === moment().add(1, "day").format("DD/MM/YY") ){
          daysInMonth2.push(
            <td key={d} className={`calendar-day`} id="today">
              <div id={`${date}`}
                onClick={this.onDayClick}
              >
                {d}
              </div>
            </td>
          );
        }
        // else if (moment().isAfter(date)) {
        //   daysInMonth.push(
        //     <td key={d} className={`calendar-day PastDay`}>
        //       <div id={`${date}`} onClick={this.onDayClick} >
        //         {d}
        //       </div>
        //     </td>
        //   );
        // }
        else {
          daysInMonth2.push(
            <td key={d} className={`calendar-day`}>
              <div id={`${date}`}
                onClick={this.onDayClick} onMouseOver={(e) => this.OnHover(e)}
              >
                {d}
              </div>
            </td>
          );
        }
      }

      else if (this.state.HoverArrival!==null && (this.state.departureDay!==null || this.state.HoverDeparture!==null)) {
        if (date === this.state.HoverArrival && (this.state.HoverDeparture !== this.state.HoverArrival)) {
          inbetween = true;
          daysInMonth2.push(
            <td key={d} className={`calendar-day selectedF`}>
              <div id={`${date}`}
                onClick={this.onDayClick}
              >
                {d}
              </div>
            </td>
          );
        } else if (((date === this.state.departureDay) || (!this.state.departureDay && date === this.state.HoverDeparture && this.state.HoverDeparture !== this.state.HoverArrival)) && (this.state.departureDay !== this.state.HoverArrival || this.state.HoverDeparture !== this.state.HoverArrival)) {
          inbetween = false;
          daysInMonth2.push(
            <td key={d} className={`calendar-day selectedS`}>
              <div id={`${date}`}
                onClick={this.onDayClick}
              >
                {d}
              </div>
            </td>
          );
        } else if (date === this.state.departureDay && date === this.state.HoverArrival) {
          daysInMonth2.push(
            <td key={d} className={`calendar-day selected`}>
              <div id={`${date}`}
                onClick={this.onDayClick}
              >
                {d}
              </div>
            </td>
          );
        } else {
          if (inbetween === true) {
              daysInMonth2.push(
                <td key={d} className={`calendar-day between`}>
                <div id={`${date}`}
                  onClick={this.onDayClick} onMouseOver={(e) => this.OnHover(e)}
                >
                  {d}
                </div>
              </td>
            );
          }
          
            else {
              daysInMonth2.push(
                <td key={d} className={`calendar-day`}>
                  <div id={`${date}`}
                    onClick={this.onDayClick} onMouseOver={(e) => this.OnHover(e)}
                  >
                    {d}
                  </div>
                </td>
              );
            }
          
        }
      } else {
        if (this.state.HoverArrival!==null && this.state.departureDay===null) {
          if (date === this.state.HoverArrival) {
            daysInMonth2.push(
              <td key={d} className={`calendar-day selected`}>
                <div id={`${date}`}
                  onClick={this.onDayClick} onMouseOver={(e) => this.OnHover(e)}
                >
                  {d}
                </div>
              </td>
            );
          } else {
              daysInMonth2.push(
                <td key={d} className={`calendar-day`}>
                  <div id={`${date}`}
                    onClick={this.onDayClick} onMouseOver={(e) => this.OnHover(e)}
                  >
                    {d}
                  </div>
                </td>
              );
          }
        } else {
            daysInMonth2.push(
              <td key={d} className={`calendar-day`}>
                <div id={`${date}`}
                  onClick={this.onDayClick} onMouseOver={(e) => this.OnHover(e)}
                >
                  {d}
                </div>
              </td>
            );
          
        }
      }
    }

    var totalSlots = [...blanks, ...daysInMonth];
    var totalSlots2 = [...blanks2, ...daysInMonth2];
    let rows = [];
    let rows2 = [];
    let cells = [];
    let cells2 = [];

    totalSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
      if (i === totalSlots.length - 1) {
        // let insertRow = cells.slice();
        rows.push(cells);
      }
    });

    totalSlots2.forEach((row2, i) => {
      if (i % 7 !== 0) {
        cells2.push(row2);
      } else {
        rows2.push(cells2);
        cells2 = [];
        cells2.push(row2);
      }
      if (i === totalSlots2.length - 1) {
        // let insertRow = cells.slice();
        rows2.push(cells2);
      }
    });

    let daysinmonth = rows.map((d, i) => {
      return <tr>{d}</tr>;
    });

    let daysinmonth2 = rows2.map((d, i) => {
      return <tr>{d}</tr>;
    });

    return (
      <div className="tail-datetime-calendar">
        <div className="calendar-navi">
          <div class="calendar-year">
            {moment(this.state.dateObject).utc().format("YYYY")}
          </div>
        </div>
        <div className="calendar-navi">
          <span
            onClick={e => {
              this.onPrev();
            }}
            class="calendar-button button-prev"
          />
          <span class="calendar-label">
            {this.month()}
          </span>
          <span className="calendar-label2">
            {this.nextmonth()}
          </span>
          <span
            onClick={e => {
              this.onNext();
            }}
            className="calendar-button button-next"
          />
        </div>
        <div className="calendar-date">
          {this.state.showYearNav && <this.YearTable props={this.year()} />}
          {this.state.showMonthTable && (
            <this.MonthList data={moment.months()} />
          )}
        </div>
        <div className="dates">

        {this.state.showCalendarTable && (
          <div className="calendar-date">
            <table className="calendar-day">
              <thead>
                <tr key={`day1-${weekdayshortname}`}>{weekdayshortname}</tr>
              </thead>
              <tbody>{daysinmonth}</tbody>
            </table>
          </div>
        )}
        {this.state.showCalendarTable && (
          <div className="calendar-date2">
            <table className="calendar-day2">
              <thead>
                <tr>{weekdayshortname}</tr>
              </thead>
              <tbody>{daysinmonth2}</tbody>
            </table>
          </div>
        )}
        </div>
      </div>
    );
  }
}

export default onClickOutside(Calendar);