import React from "react";
import moment from "moment";
import 'moment/locale/fr';
import styled from 'styled-components'
import axios from 'axios';

export default class Agenda extends React.Component {
  weekdayshort = ["Lun.", "Mar.", "Mer.", "Jeu.", "Ven.", "Sam.", "Dim."];
  weekdaymobile = ["L.", "M.", "M.", "J.", "V.", "S.", "D."];

  state = {
    Creneaux: null,
    Weekdayshort: this.weekdayshort,
    arrival: moment(this.props.arrival),
    departure: moment(this.props.departure),
    deliveryLimit: this.props.deliveryLimit,
    chosen_dates: [],
    quantities: [],
    scrollTop: window.scrollY,
    days_tab: []
  };

  setCreneaux = () => {
    axios.get(process.env.REACT_APP_API_URL + `/agenda/${this.props.labelId}/availability?serviceId=${this.props.serviceId}&start=${this.props.arrival}&end=${this.props.departure}`)
    .then(res => {
      this.setState({Creneaux: res.data.availabilities});   
      return(res.data.availabilities);
    })
  };
  creneaux = this.setCreneaux();

  Select = (e, dates) => {
    let is_found = false;
    let poped = this.state.chosen_dates;
    let quanpoped = this.state.quantities;
    for (let i = 0; i <= this.state.chosen_dates.length; i++) {
      if (this.state.chosen_dates[i] !== undefined && this.state.chosen_dates[i].time === dates.time) {
        poped.splice(i, 1);
        quanpoped.splice(i, 1);
        is_found = true;
      }
    }
    if (is_found === true) {
      this.setState({chosen_dates: poped, quantities: quanpoped});
    } else {
      let tmp = this.state.quantities;
      this.setState({chosen_dates: [...this.state.chosen_dates, dates], quantities: [...tmp, 1]});
    }
  }

  onPrev = () => {
    var diff = moment(this.state.arrival).diff(moment(this.props.arrival), "days")
    var remainingDays = Math.min(diff, 7)
      if(remainingDays > 0){
        this.setState({arrival: moment(this.state.arrival).subtract(remainingDays, "days")})
      }
  };

  onNext = () => {
    
    let diff = moment(this.state.departure).diff(moment(this.state.arrival), "days")
    let remainingDays = Math.min(diff, 7)

    if(remainingDays >= 0){
      if(moment(this.state.arrival).add(remainingDays, "days").isSameOrBefore(this.state.departure, 'day')){
        this.setState({arrival: moment(this.state.arrival).add(remainingDays, "days")})
      }
    }
  };

  QuantitySelect = (e, i) => {
    let tmp = this.state.quantities;
    tmp[i] = parseInt(e.target.value);
    this.setState({quantities: tmp});
  };

  submit = () => {
    let arr = [];
    for (let i = 0; i !== this.state.chosen_dates.length; i++) {
      arr.push({
        date: new Date(this.state.chosen_dates[i].time),
        quantity: this.state.quantities[i]
      })
    }
    this.props.setSlot(arr);
    this.props.submitAgenda();
  };
  
  componentDidMount(){
    let indexx = (this.state.departure.diff(this.state.arrival, "days"))
    let tmp_daysTab = []

    for (let i = 0; i <= indexx; i++) {
      let days_shortname = moment(this.state.arrival).add(i, "day").format("ddd")
      tmp_daysTab.push(days_shortname)
    }
    this.setState({days_tab: tmp_daysTab})
  }
  
  displayQuantity = (nb) => {
    const quantities = [];
    for (let i = 0; i < nb; i++) {
      quantities.push(
        <option value={i + 1}>{i + 1}</option>
      );
    }
    return quantities;
  };

  render() {

    if (window.innerWidth <= 370 && this.state.Weekdayshort === this.weekdayshort) {
      this.setState({Weekdayshort: this.weekdaymobile})
    }
      let diff = moment(this.state.departure).diff(this.state.arrival, "days") + 1
      let weekdayshortname = this.state.days_tab.slice(0, Math.min(diff, 7)).map((day, index) => {  
        return (
          <DaysName>
            {moment(this.state.arrival).add(index, "day").format("ddd")}
          {<DaysDate>
          
            {moment(this.state.arrival).add(index, "day").format("DD/MM")}
            <div style={{marginTop: "25px"}}>
              {this.state.Creneaux && this.state.Creneaux.map(dates => {
                if (moment(this.state.arrival).add(index, "day").isSame(dates.time, "day")) {
                  let is_found = false;
                  for (let i = 0; this.state.chosen_dates[i]; i++) {
                    if (this.state.chosen_dates[i].time === dates.time) {
                      is_found = true;
                      return(
                        <SelectedHours onClick={(e) => this.Select(e, dates)}>
                          {moment.utc(dates.time).format("HH:mm")}
                        </SelectedHours>
                      );
                    }
                  }
                  if (is_found === false) {
                    var ServiceAvailable = moment().add(this.props.deliveryLimit, 'h')

                    if (ServiceAvailable.isAfter(this.props.arrival) && moment.utc(dates.time).isSameOrBefore(moment(ServiceAvailable), "day")) {
                      return (
                        <UnselectableHours>
                              {moment.utc(dates.time).format("HH:mm")}
                        </UnselectableHours>)
                    }
                    if (moment(dates.time).utc().isBefore(this.props.arrival) && (moment.utc(dates.time)).isSame(moment(this.props.arrival).add(1, "day"), "day") === false) {
                      return (
                        <UnselectableHours>
                              {moment.utc(dates.time).format("HH:mm")}
                        </UnselectableHours>)
                    } else if (moment.utc(dates.time).isAfter(moment(this.state.departure).add(1, "day"))) {
                      return (
                        <UnselectableHours>
                            {moment.utc(dates.time).format("HH:mm")}
                      </UnselectableHours>)
                    }  else {
                      return (
                        <Hours onClick={(e) => this.Select(e, dates)}>
                            {moment.utc(dates.time).format("HH:mm")}
                        </Hours>
                      )
                    }
                  }
                } else
                  return (null);
              })}
            </div>
            </DaysDate>}
        </DaysName> 
      );
    });

    let orders = [];
    let chosen_date = null;

    for (let i = 0; i !== this.state.chosen_dates.length; i++) {
      if (this.state.chosen_dates[i])
        chosen_date = moment.utc(this.state.chosen_dates[i].time).format("[Le] dddd DD MMMM [à] HH[h]mm");
      else
        chosen_date = "Choisir une date";
      orders.push(
        <Order>
          <OrderInfos>
            <OrderTitle>
              {this.props.OrderLabels}
            </OrderTitle>
            <OrderDescription>
              {chosen_date}
            </OrderDescription>
          </OrderInfos>
          <OrderQuantity>
            <OrderQuantityBox>
              <OrderQuantityLabel>
                Choisissez votre quantité :
              </OrderQuantityLabel>
              <OrderQuantitySelect value={this.state.quantities[i]} onChange={(e) => this.QuantitySelect(e, i)}>
                { this.displayQuantity(this.state.chosen_dates[i].nbSlotsAvailable) }
              </OrderQuantitySelect>
            </OrderQuantityBox>
          </OrderQuantity>
        </Order>
      )
    }
    return (
      <CardFormModal>
        <AgendaBox>
          <TitleCategory>
              {this.props.category}
          </TitleCategory>
          <TitleLabel>{this.props.label}</TitleLabel>
          <DeleteButton onClick={() => {this.props.submitAgenda()}}> X </DeleteButton>
          <Description>
          Choisissez vos créneaux pendant votre séjour ainsi que les quantités associées :
          </Description>
          <TimesBox>
            <Weekdays>
                <CalendarDays>
                  {/* <DaysName> */}
                    <Prev src="Icones/left.png" onClick={e => this.onPrev()}/>
                  {/* </DaysName> */}
                    {weekdayshortname}
                  {/* <DaysName> */}
                    <Next src="Icones/right.png" onClick={e => this.onNext()}/>
                  {/* </DaysName> */}
                </CalendarDays>
            </Weekdays>
          </TimesBox>
          <OrderBox>
            {orders}
          </OrderBox>
          <ValidateBox>
            <Validate onClick={this.submit}>
              Valider
            </Validate>
          </ValidateBox>
        </AgendaBox>
      </CardFormModal>
    );
  }
}

const AgendaBox = styled.div`
  position: absolute;
  width: 647px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 10px #00000029;
  border-radius: 18px;
  opacity: 1;
  height: 80%;
  overflow: scroll; 


  @media screen and (max-device-width: 480px) {
    left: 2.5vw;
    top: 10vh;
    width: 95%;
    max-height: 450px;
    overflow-y: scroll;
  }
`;

const TitleCategory = styled.h1`
  margin-top: 23px;
  width: 80%;
  height: 33px;
  text-align: left;
  font: normal normal 600 24px/20px Poppins;
  letter-spacing: 2.16px;
  color: #484848;
  margin-left: 36px;
  opacity: 1;
  margin-top: 35px;
`;

const TitleLabel = styled.h3 `
  font: normal normal 600 18px Poppins;
  margin-left: 36px;
  text-align: left;
  color: #16a5a3;

`
const Description = styled.p`
  width: auto;
  // height: 24px;
  text-align: left;
  font: normal normal 400 14px Poppins;
  letter-spacing: 0px;
  color: #484848;
  margin: 15px 15px 15px 36px;
  opacity: 1;
`;

const TimesBox = styled.div`
display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  width: 575px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #20A7A3;
  border-radius: 17px;
  opacity: 1;
  @media screen and (max-device-width: 480px) {
    width: 92%;
    height: auto;
  }
`;

const Weekdays = styled.div`
  width: 100%;
  @media screen and (max-device-width: 480px) {
    width: 20%;
  }
`;

const CalendarDays = styled.div`
  display: flex;
  width: 100%;
  margin-top: 17px;
  text-align: center;
  font: normal normal medium 11px/16px Poppins;
  color: #16a5a3;
  @media screen and (max-device-width: 480px) {
    width: 80%;
    font: normal normal medium 5px/9px Poppins;
  }
`;

const DaysName = styled.div`
  width: 100%;
  font-weight: bold;
  color: #16a5a3;
  font: normal normal 600 16px Poppins;
  @media screen and (max-device-width: 480px) {
    font: normal normal medium 5px/9px Poppins;
    font-size: 12px;
    margin-right: 9px;
  }
  @media screen and (max-device-width: 370px) {
    font: normal normal medium 5px/9px Poppins;
    font-size: 10px;
    margin-right: 5px;
  }
`;

const DaysDate = styled.div`
  width: 100%;
  font-weight: bold;
  color: #16a5a3;
  margin-bottom: 25px;
  font: normal normal 300 14px Poppins;
  @media screen and (max-device-width: 480px) {
    font: normal normal 500 5px/9px Poppins;
    font-size: 10px;
  }
`;

/*const CalendarDates = styled.div`
  display: flex;
  width: 100%;
  margin-top: 17px;
  text-align: center;
  font: normal normal medium 11px/16px Poppins;
  letter-spacing: 0px;
  color: #16a5a3;
`;*/

const Hours = styled.div`
  color: #484848;
  font: normal normal 300 12px/30px Poppins;
  cursor: pointer;
  @media screen and (max-device-width: 480px) {
    font: normal normal medium 5px/9px Poppins;
    font-size: 11px;
  }
`;

const UnselectableHours = styled.div`
  color: #484848;
  opacity: 40%;
  font: normal normal 300 12px/30px Poppins;
  cursor: not-allowed;
  @media screen and (max-device-width: 480px) {
    font: normal normal medium 5px/9px Poppins;
    font-size: 11px;
  }
`;

const SelectedHours = styled.div`
  color: #484848;
  font: normal normal 300 12px/30px Poppins;
  cursor: pointer;
  border: 1px solid #16a5a3;
  border-radius: 200px;
  @media screen and (max-device-width: 480px) {
    border-radius: 10px;
    font: normal normal medium 5px/9px Poppins;
    font-size: 11px;
  }
`;

const Next = styled.img`
  width: 5px;
  height: 10px;
  // padding: 0 0 5px 0;
  margin-right: 25px;
  margin-top: 13px;
  margin-left: 25px;
  cursor: pointer;
  @media screen and (max-device-width: 480px) {
    margin : 10px;

  }
`;

const Prev = styled.img`
  width: 5px;
  height: 10px;
  // padding: 0 0 5px 0;
  margin-top: 13px;
  margin-left: 25px;
  cursor: pointer;
  @media screen and (max-device-width: 480px) {
    margin : 10px;

  }
`;

const OrderBox = styled.div`
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 88%;
`;

const Order = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  width: 100%;

  @media screen and (max-device-width: 554px){
    flex-direction: column
  }
`;

const OrderInfos = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  @media screen and (max-device-width: 554px){
    margin: 0px;
    width: 100%;
  }
`;

const OrderQuantity = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  font: normal normal 400 12px Poppins;
  @media screen and (max-device-width: 554px) {
    margin: 0px;
    width: 100%;
  }
`;

const OrderQuantityBox = styled.div`
  display: flex;
  margin-top: 10px;
  width: 100%;
  justify-content: end;
  @media screen and (max-device-width: 554px) {
    justify-content: space-between;
  }
`;

const OrderQuantityLabel = styled.div`
  font: normal normal medium 14px/20px Poppins;
  letter-spacing: 0px;
  color: #484848;
  @media screen and (max-device-width: 554px) {
    font-size: 15px;
    margin-right: 5px; 
  }
`;

const OrderQuantitySelect = styled.select`
  border: none;
  outline: none;
  background-color: #FFF;
`;

const OrderTitle = styled.h1`
  text-align: left;
  font: normal normal 400 16px Poppins;
  color: #484848;
  @media screen and (max-device-width: 480px) {
    font-size: 17px;
  }
`;

const OrderDescription = styled.p`
  text-align: left;
  font: normal normal 400 14px Poppins;
  color: #484848;

  @media screen and (max-device-width: 554px) {
    font: normal normal 400 14px Poppins;

  }
`;

const ValidateBox = styled.div`
  width: 100%;
  height: 47px;
  margin-bottom: 27px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
`;

const Validate = styled.button`
  cursor: pointer;
  align-items: center;
  text-align: center;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 10px #00000029;
  border: none;
  border-radius: 23px;
  width: 100px;
  font: normal normal 600 12px Poppins;
  letter-spacing: 1.53px;
  color: #20A7A3;
`;

const CardFormModal = styled.div`
  position: fixed;
  display: flex;

  width: 100vw;
  height: 100%;
  top: 0px;
  left: 0px !important;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  z-index: 30234;
  background: 0% 0% no-repeat padding-box padding-box rgba(92, 92, 92, 0.384);
  overflow-y: hidden;
`

const Close = styled.img`
  cursor: pointer;
  top: 5px;
  right: 5px;
`

const DeleteButton = styled.div `
  position: absolute;
  width: 15px;
  color: #16a5a3;
  height: 20px;
  right: 10px;
  top: 10px;
  :hover {
      cursor: pointer;
  }
  border: none;
`