import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { Document } from 'react-pdf';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import PageList from './PageList';
import PdfDocument from './PdfDocument';
import PdfControls from './PdfControls';
import './DisplayPdf.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

const noop = () => {};

export default class DisplayPdf extends React.Component {
    static propTypes = {
        ...Modal.propTypes,
        fitTo: PropTypes.oneOf(['page', 'width']),
    };
    static defaultProps = {
        fitTo: 'page',
        open: false,
    };

    state = {
        scale: 1,
        loadedPages: {},
        open: false,
    };

    constructor(props) {
        super(props);
        this.state.fitTo = props.fitTo;
        this.state.open = props.open;
    }

    handleDocumentLoaded = ({ numPages: pages }) => {
        this.setState({ page: 1, pages });
    };

    handlePageChange = page => this.setState({ page });

    render() {
        const { fitTo, scale, pages: pageCount, open } = this.state;
        return (
            <div>
                <Modal
                    className={`document-preview-modal fit-to-${fitTo}`}
                    isOpen={this.state.open}
                    toggle={this.props.toggle}
                    fullscreen={true}
                >
                  <ModalHeader toggle={this.props.toggle} 
                  close={<button type="button" className="close" onClick={this.props.toggle}  ><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>}
                  >
                    <div>
                        {this.props.title}
                        {/* {pageCount && (
                            <div className="page-counter">
                                Page {this.state.page} of {pageCount}
                            </div>
                        )} */}
                    </div>
                  </ModalHeader>
                  <ModalBody>
                    <PdfDocument
                        file={this.props.file}
                        scale={scale}
                        fitTo={fitTo}
                        onLoad={this.handleDocumentLoaded}
                        onPageChange={this.handlePageChange}
                    />
                  </ModalBody>
                  <PdfControls
                      fitTo={fitTo}
                      onZoomToFit={fitTo =>
                          this.setState({ scale: 1, fitTo })
                      }
                      onZoomIn={() => this.setState({ scale: scale * 1.1 })}
                      onZoomOut={() =>
                          this.setState({ scale: Math.max(0.1, scale * 0.9) })
                      }
                  />
                    {this.props.footer && (
                        <ModalFooter>{this.props.footer}</ModalFooter>
                    )}
                </Modal>
            </div>
        );
    }
}
