import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import axios from 'axios';
import QueryString from 'qs';
import UserProfile from '../UserProfile/UserProfile';



const CardForm = ({setCardInfos, setAddCard}) => {
    const [monthOpen, setMonthOpen] = useState(false)
    const [month, setMonth] = useState("")
    const [year, setYear] = useState("")
    const [yearOpen, setYearOpen] = useState(false)
    const [name, setName] = useState("");
    const [cvc, setCvc] = useState("");
    const [number, setNumber] = useState("")
    const [error, setError] = useState();

    const handleSubmit = () => {
        if (checkDate(year)) {
            setCardInfos({
                "cvc": cvc,
                "name": name,
                "date": month.toString() + year.toString(),
                "cardNumber": number,
            })
        }
    }
    const getDate = (date) => {
        const ordered = new Date(date).toLocaleDateString('fr', {
            month: '2-digit',
            year: "2-digit"
        });
        return (ordered)
    }
    const checkDate =  (yearSelect) => {
        const today = new Date();
        const dateSelect= (getDate(today).split("/"))
        if (parseInt(dateSelect[1]) === parseInt(yearSelect)) {
            if (parseInt(month) < parseInt(dateSelect[0])) {
                setError("Date expirée")
                return false
            }
        }
        return true
    }
    const getQuantity = (start,end) => {
        let tmp = []
        for (let i = start; i !== end; i++) {
            tmp.push(
            <Quantity
                    type="submit"
                    name={end === 12 ? "month" : "year"}
                    onClick={() => {
                        if (end === 12) {
                        setMonthOpen(false)
                        if (i + 1 >= 10) {
                            setMonth(i + 1)
                        } else {
                            setMonth("0" + (i+1))
                        }
                        } else {
                            setYearOpen(false)
                        if (i >= 10) {
                            setYear(i)
                        } else {
                            setYear("0" + (i))
                        }
                        }
                    }
                }>
                {end === 12 ? i + 1 : i}
            </Quantity>)
        }
        return tmp;
    }
    return (
      <div style={{marginLeft: "29.95px", display: "flex", flexDirection: "column", width: "100%"}}>
        <DeleteButton onClick={() => {setAddCard(false)}}> X </DeleteButton>
        <InputLabel htmlFor="string">Numéro de carte</InputLabel>
        <NameInput
             value={number}
             onChange={(e) => {setNumber(e.target.value)}}
        />
        <InputLabel htmlFor="string">Date d'expiration</InputLabel> 
        <Error>{error} </Error>
        <div style={{display: "flex", justifyContent: "space-between", width: "20%"}}>
        <MonthInput
          value={month}
        >
        {monthOpen ? (
                <QuantityContainer>
                <QuantitySelect> 
                    {getQuantity(0, 12)}
                </QuantitySelect>
                </QuantityContainer>
            ) : null}
        <div style={{display: "flex", alignItems: "center", width: "100%",  height: "15px",justifyContent: "flex-end"}}>
            {month ? month : null}
            { !monthOpen ?<Arrow src="/Icones/Arrow - black.png"  onClick={() => {setMonthOpen(true); setMonth(null)}}/> : null}
        </div>
        </MonthInput>
        <YearInput
            value={year}
        >      {yearOpen ? (
            <QuantityContainer>
            <QuantitySelect> 
                {getQuantity(parseInt(new Date().getFullYear().toString().substr(-2)), parseInt(new Date().getFullYear().toString().substr(-2)) + 30)}
            </QuantitySelect>
            </QuantityContainer>
        ) : null}
    <div style={{display: "flex", alignItems: "center", width: "100%", height: "15px", justifyContent: "flex-end"}}>
        {year ? year : null}
        {!yearOpen ? <Arrow src="/Icones/Arrow - black.png"  onClick={() => {setYearOpen(true); setYear(null)}}/> : null }
    </div>
        </YearInput>
        </div>
        <InputLabel htmlFor="string">CCV</InputLabel>
        <NameInput
             value={cvc}
             onChange={(e) => {setCvc(e.target.value)}}
        />
  
        <ButtonSubmit onClick={handleSubmit}>Valider</ButtonSubmit>
      </div>
    );
  };

export const CardManager = ({setSelectedCard, selectedCard, userId, setError}) => {
    const [addCard, setAddCard] = useState(false);
    const [resp, setResp] = useState();
    const [availableCards, setAvailableCard] = useState([]);
    const [CardInfos, setCardInfos] = useState()
    const [cardsChange, setCardChange] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const initCardRegistration = async () => {
        var xmlHttp = new XMLHttpRequest();
        xmlHttp.open( "POST", process.env.REACT_APP_API_URL + `/checkout/init-card-registration`, false );
        xmlHttp.setRequestHeader(
            'Authorization','Bearer ' + JSON.parse(UserProfile.getUser()).token
          )
        xmlHttp.send(null);
        setResp(JSON.parse(xmlHttp.responseText));
        setAddCard(true)
    }

    const sendCardInfo = async () => {
        const dataSend = QueryString.stringify({
            'accessKeyRef': resp.AccessKey,
            'data': resp.PreregistrationData,
            'cardNumber': CardInfos.cardNumber,
            'cardExpirationDate': CardInfos.date,
            'cardCvx': CardInfos.cvc 
        })
        const config = {
            method: 'post',
            url: resp.CardRegistrationURL,
            headers: { 
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            data : dataSend
          };
          axios(config)
            .then(function (response) {
                const newconfig = {
                    method: 'POST',
                    url:  process.env.REACT_APP_API_URL + `/checkout/finalize-card-registration`,
                    headers: { 
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + JSON.parse(UserProfile.getUser()).token,
                    },
                    data : {"registrationData": response.data, "registrationId": resp.Id}
                  };
                  axios(newconfig)
                    .then(function (responsePut) {
                        if (responsePut.data)
                            listAvailableCard();
                        setCardChange(!cardsChange)
                    }).catch(function (errorPut) {
                        if (errorPut)
                            setError(errorPut.response.data)
                        console.log(errorPut)
                    })
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    
    const listAvailableCard = async () => {
        var config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + `/checkout/cards`,
            headers: {
                'Authorization': 'Bearer ' + JSON.parse(UserProfile.getUser()).token
            },
        };
        
        axios(config)
        .then(async function (response) {
            let cards = await response.data;
            if (cards) {
                setAvailableCard(cards)
            }
        })
        .catch(function (error) {
            console.log(error);
        });
        
    }

    useEffect(() => {
        if (CardInfos) {
            sendCardInfo();
            setCardChange(!cardsChange);
            setAddCard(false)
        } 
    }, [CardInfos])

    useEffect(() => {
        listAvailableCard();
    },[])

    const handleSelect = (Id, index) => {
        if (index === selectedIndex) {
            setSelectedCard("");
            setSelectedIndex(-1)
        } else {
            setSelectedCard(Id);
            setSelectedIndex(index)
        }
    }

    const formatedDate = (str) => {
        return str.substring(0, 2) + '/' + str.substring(2, str.length);
    }
    return (
        <CardsContainer>
            <div style={{display: "flex", flexDirection: "column"}}>
                {availableCards.map((card, index) => {
                    return (
                        <CardContainer key={index}>
                            <AddCardButton addCard={selectedIndex === index ? true : false} onClick={() => handleSelect(card.cardId, index)}/>
                            <Card>
                                Utiliser CB  {card.Alias}
                                <div>
                                    Exp. {formatedDate(card.ExpirationDate)}
                                </div>
                            </Card>
                        </CardContainer>
                    )
            })}
            </div>
            <AddCard>
                <AddCardButton addCard={addCard} onClick={initCardRegistration}/>
                <ButtonTitle >
                    Ajouter une nouvelle carte
                </ButtonTitle>
                { addCard === true ?
                    <CardFormModal>
                        <CardFormContainer>
                            <Title>Ajouter une nouvelle carte bleue</Title>
                            <CardForm setCardInfos={setCardInfos} setAddCard={setAddCard}/>
                        </CardFormContainer>
                    </CardFormModal> : null
                }

            </AddCard>
        </CardsContainer>
    )
}

const DeleteButton = styled.div`
    position: absolute;
    width: 15px;
    height: 20px;
    top: 5px;
    right: 5px;
    :hover {
        cursor: pointer;
    }
    border: none;
`

const Error = styled.div`
    display: flex;
    font: normal normal 600 12px Poppins;
    color: red;
`

const ButtonTitle = styled.div`
    display: flex;
    font: normal normal bold 17px/26px Poppins;
    letter-spacing: 1.53px;
    color: #1E2224;
    margin-left: 10px;
    @media (max-width:767px) and (min-width:300px) {
            font-size: 14px;
    }
`
const Card = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    font: normal normal 300 17px/24px Poppins;
    letter-spacing: 1.53px;
    margin-top: -5px;
    color: #1E2224;
    @media (max-width:767px) and (min-width:300px) {
       font-size: 14px;
    }
`

const CardContainer = styled.div`
    display: flex;
    margin-top: 30px;
    @media (max-width:767px) and (min-width:300px) {
        margin-top: 0px;
    }
`
const QuantitySelect = styled.div`
    width: 40px;
    height: 95%;
    z-index: 15;
    background-color: white;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        width: 5px;
        height: 8px;
        background-color:#F4F4F4; /* or add it to the track */
        border-radius: 10px;
        height: 50%;
    }
    ::-webkit-scrollbar-thumb {
        background:#1E2224;
        border-radius: 8px;
    }
`
const QuantityContainer = styled.div`
    display: flex;
    width: 25px;
    height: 147px;
    justify-content: center;
    background-color: white;
    z-index: 12;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #1F2224;
    align-items: center;
    margin-bottom: -147px;
    @media screen and (max-device-width:1024px){
        margin-left: -69px;
        margin-top: 165px;
    }
    @media screen and (max-device-width:480px){
        margin-left: 0px;
        //margin-top: 170px;
    }
`

const Quantity = styled.button`
    display: flex;
    color: black;
    width: 20px;
    justify-content: center;
    background-color: transparent;
    border: none;
    margin-top: 5px;
    :hover {
        background-color: lightgray;
    }
`

const Arrow = styled.img`
    display: flex;
    width: auto;
    height: 5px;
    margin-bottom: 5px;
    padding-left: 10px;
    margin-top: 7px;
`
const MonthInput = styled.div`
    display: flex;
    border: none;
    border-bottom: ${props => props.value ? "none": '1px solid black'};
    width: 40%;
    outline: none;
    height: 20px;
    font-size: 12px;
    flex-direction: column-reverse;
    :hover {
        cursor: pointer;
    }
`

const YearInput = styled.div`
    display: flex;
    border: none;
    border-bottom: ${props => props.value ? "none": '1px solid black'};
    width: 40%;
    outline: none;
    height: 20px;
    flex-direction: column-reverse;
    font-size: 12px;
    :hover {
        cursor: pointer;
    }
`
const InputLabel = styled.label`
    display: flex;
    margin-top: 15px;
    font: normal normal bold 14px Poppins;
    color: #1E2224;
`
const NameInput = styled.input`
    display: flex;
    border: none;
    border-bottom: ${props => props.value.length > 0 ? "none": '1px solid black'};
    width: 40%;
    outline: none;
`

const ButtonSubmit = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 2Opx;
    color: #16B7AB;
    margin-left: 43%;
    margin-top: 60px;
    border: none;
    background-color: white;
    border-radius: 15px;
    box-shadow: 3px 3px 10px #00000029;;
    font: normal normal 600 17px Poppins;
    @media screen and (max-device-width:480px){
        font-size: 14px
    }
    :hover {
        box-shadow: 3px 3px 10px grey;
    }
`
const Title = styled.div`
    display: flex;
    width: 100%;

    justify-content: center;
    align-items: center;
    margin-top: 15.54px;
    font: normal normal medium 17px Poppins;
`

const CardFormContainer = styled.div`
    display: flex;
    position: relative;
    width: 489px;
    flex-direction: column;
    height: 386px;
    box-shadow: 4px 4px 10px #00000029;
    border-radius: 18px;
    background-color: white;
    align-items: flex-start;
    @media screen and (max-device-width:480px){
        width: 90%;
    }
`
const CardFormModal = styled.div`
    position: absolute;
    display: flex;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    z-index: 99;
    background: #5C5C5C62 0% 0% no-repeat padding-box;

`
const AddCard = styled.div`
    display: flex;
    align-items: center;
    margin-top: 20px;
`
const AddCardButton = styled.button`
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: ${props => props.addCard ? "#20A7A3": "white"};
    border: 1px solid black;
    @media (max-width:767px) and (min-width:300px) {
        color: white;
        padding: 6px;
        display: inline-block;
        font-size: 8px;
        border: 1px solid black;
        border-radius: 50%;
    }

`
const CardsContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    height: auto;
`