import React from 'react'
import styled from 'styled-components'
import UserProfile from "../UserProfile/UserProfile";
import history from '../history'
import axios from 'axios';


export const ValidationButton = ({Title, ButtonSize, border, onClick, goTo= "default", cart, color, shortcut}) => {
    const checkRedirect = () => {
        if (goTo === "connexion") {
            if (UserProfile.getName()) {
                history.push("/panier");
                return
            } else {
                //UserProfile.setCart(JSON.stringify(cart));
                const config = {
                    method: 'POST',
                    url:  process.env.REACT_APP_API_URL + `/shopping-cart`,
                    headers: { 
                      'Content-Type': 'application/json',
                    },
                    data : cart
                  };
                  axios(config)
                    .then(function (responsePut) {
                        UserProfile.setCartId(responsePut.data._id);
                        history.push(`/${shortcut}/connexion`);
                    }).catch(function (errorPut) {
                        console.log(errorPut)
                    })
                return
            }
        }
        if (goTo === "register") {
            onClick(true);
            return;
        }
        if (goTo === "regi") {
            onClick();
            return;
        }
        if (goTo === "login") {
            onClick(true);
            return;
        }
        if (goTo === "payment") {
            onClick();
            return;
        }
        onClick();
    };
    return (
        <Validation ButtonSize={ButtonSize} border={border} onClick={() => {checkRedirect();}} color={color}>
            {Title}    
        </Validation>
    )
}

const Validation = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.color ? props.color : "#16a5a31f 9% 0% no-repeat padding-box"};
    width: ${props => props.ButtonSize.width};
    height: ${props => props.ButtonSize.height};
    color: #000000;
    border: none; 
    border-radius: 15px;
    box-shadow: 3px 3px 10px #00000029;;
    font: normal normal 400 14px Poppins;
    padding: 10px;

    @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
        font: normal normal 400 12px Poppins !important; 
    }

    @media screen and (max-device-width:1440px){
        font-size: 14px;
    }
    @media screen and (max-device-width:554px){
        font-size: 13px

    }
    
    @media (max-width: 575.98px) {
        // display: none; 
        width: unset; 
    }
    :hover {
        box-shadow: 3px 3px 10px grey;
        cursor: pointer;
    }
    
`