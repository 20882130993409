import React from "react";
import logo from '../CLEF_BLANCHE.png'
export const SpinnerLoader = () => {
 
  return (
    <div>
      <div id="loading">
        <div class="loader-container">
      <img src={logo} alt="getwelcom"></img>
          <div class="loader"></div>
        </div>
      </div>
    </div>
  );
};
