import styled from 'styled-components';
import React from "react";

export const Footer = () => {
    function Redirection(url) {
        var win = window.open(url, '_blank');
        win.focus();
      }

   return ( <BottomContainer>
    <BottomContainerFlexBox>
    <UnderBox>
        <Title>
            GetWelcom
        </Title>
        <div style={{display: "flex", flexDirection: "column", height: "90%", justifyContent: "space-between"}}>
        <SubTitle  onClick={() => {Redirection(" https://getwelcom.com/")}} >Découvrir GetWelcom</SubTitle>
        <SubTitle onClick={() => {Redirection(" https://getwelcom.com/apropos")}}>À propos</SubTitle>
        <SubTitle onClick={() => {Redirection("https://getwelcom.com/legal")}}>Mentions légales</SubTitle>
        </div>
    </UnderBox>
    
    <UnderBox>
        <Title>
            Réseaux sociaux
        </Title>
        <div style={{display: "flex", flexDirection: "column", height: "90%", justifyContent: "space-between"}}>
        <SubTitle  onClick={() => {Redirection("https://www.instagram.com/getwelcom/")}}>Instagram</SubTitle>
        <SubTitle onClick={() => {Redirection("https://www.facebook.com/GetWelcom-143916861135156")}}>Facebook</SubTitle>
        <SubTitle onClick={() => {Redirection("https://www.linkedin.com/company/getwelcom/")}}>Linkedin</SubTitle>
        </div>
    </UnderBox>
    </BottomContainerFlexBox>
    </BottomContainer>
    )
}


const BottomContainerFlexBox = styled.div`
  display: flex;
   width: 60%;
   height: 100%;
   flex-direction: row;
    justify-content: space-between;
    align-items: center;
  @media screen and (max-device-width:1024px){
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
    }
  @media screen and (max-device-width: 554px){
    width: 100%; 
  }
`
const SubTitle = styled.div`
  color: var(--unnamed-color-1e2224);
text-align: left;
font: normal normal medium 17px/26px Poppins;
letter-spacing: 1.53px;
color: #1E2224;
opacity: 1;
:hover {
  cursor: pointer;
}
@media screen and (max-device-width: 554px){
  font: normal normal 400 12px Poppins;
}
`
const Title = styled.div`
  color: var(--unnamed-color-1e2224);
  text-align: left;
  font: normal normal bold 20px/30px Poppins;
  letter-spacing: 1.8px;
  color: #1E2224;
  margin-bottom: 20px; 
  @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
  font-size: 14px; 
  }
  @media screen and (max-device-width:1024px){
        font: normal normal bold 14px Poppins;
    }

  @media screen and (max-device-width: 554px){
    margin-bottom: 5px;
  }
  
`
const UnderBox = styled.div`
  display: flex;
  height: 40%;
  flex-direction: column;
  margin-top: 10px;
`
const BottomContainer = styled.div`
  display: flex;
  background-color: #EDEDED;
  width: 100%;
  min-height: 30vh ;
  justify-content: center;
  align-items: center;
  @media screen and (max-device-width:1024px){
        justify-content: flex-start;
        margin-top: 40px;
        height: 400px;
    }
    @media screen and (max-device-width:554px){
        padding: 15px; 
        justify-content: flex-start;
        margin-top: 40px;
        // min-height: 55vh ;
        height: 100%;
    }

`

