import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';
import PageList from './PageList';
import './PdfDocument.css';
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default class PdfDocument extends React.Component {
    static propTypes = {
        file: PropTypes.string.isRequired,
        scale: PropTypes.number,
        fitTo: PropTypes.oneOf(['page', 'width']),
        onLoad: PropTypes.func,
        onPageChange: PropTypes.func
    };
    static defaultProps = {
        scale: 1,
        fitTo: 'page'
    };
    state = {
        document: {}
    };
    handleDocumentLoaded = document => {
      console.log(document, "ONLOAD");
        this.setState({ document });
        this.props.onLoad(document);
    };
    render() {
        console.log(this.state, "PdfDocument");
        const { numPages } = this.state.document;
        const { file, scale, fitTo, onPageChange } = this.props;
        return (
          <Fragment>
            <div className="document-container" ref={e => (this.container = e)}>
                <Document
                    file={file}
                    // options={{ workerSrc: 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.0.305/pdf.worker.min.js' }}
                    options={{
                        cMapUrl: 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.0.305/pdf.worker.min.js',
                        cMapPacked: true,
                      }}
                    onLoadSuccess={this.handleDocumentLoaded}
                >
                  <PageList
                      container={this.container}
                      pageCount={numPages}
                      scale={scale}
                      fitTo={fitTo}
                      onPageChange={onPageChange}
                  />
                </Document>
            </div>
          </Fragment>
        );
    }
}