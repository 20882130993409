import React from "react";
import styled from "styled-components";

import Carousel, { autoplayPlugin, slidesToShowPlugin } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";


export const Carousels = (props) => {  
    const TopCarousel = props => {
    if (props.hostdata !== undefined && !Array.isArray(props.hostdata)) {
      const result = props.hostdata.slideshow.map(x => {
        return (
          <HostFigureCarousel key={x} style={{backgroundImage: `url(${x})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
          {/* <HostImageCarousel alt={x} src={x} key={x} id={x} /> */}
          </HostFigureCarousel>
        )
      })
      return (
        <Carousel 
          plugins={[
            'infinite',
            {
              resolve: autoplayPlugin,
              options: {
                interval: 9000,
              },
            },
            {
              resolve: slidesToShowPlugin,
              options: {
               numberOfSlides: 3
              }
            },
          ]}
          animationSpeed={4000}
          >
          { result }
        </Carousel>
      )
    }
    return null
  }

  const printFullAddress = (address) => {
    let result = "";
    if (address === null || address === undefined) return "";
    if (address?.line1 !== null && address?.line1 !== undefined) result += address?.line1;
  
    return result;
  }

 return(
    <HostInfosContainer className="container-fluid" slug={props.slug}>

    <HostContainer>
      <HeaderHostInfos slug={props.slug}>
        <div>
          <HostName slug={props.slug}>{props.hostdata.name}</HostName>
          <HostAdress slug={props.slug}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15px"
              height="15px"
              viewBox="0 0 9.516 11.408"
              style={{ marginRight: "10px"}}
            >
              <g
                id="Icon_feather-map-pin"
                data-name="Icon feather-map-pin"
                transform="translate(-4 -1)"
              >
                <path
                  id="Tracé_30"
                  data-name="Tracé 30"
                  d="M13.016,5.758c0,3.312-4.258,6.15-4.258,6.15S4.5,9.069,4.5,5.758a4.258,4.258,0,1,1,8.516,0Z"
                  fill="none"
                  stroke={props.slug === "secret-de-paris" ? "#6a4962" : props.slug === 'parisjadore' ? "#d0b13d" :"#16a5a3"}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  id="Tracé_31"
                  data-name="Tracé 31"
                  d="M16.339,11.919A1.419,1.419,0,1,1,14.919,10.5a1.419,1.419,0,0,1,1.419,1.419Z"
                  transform="translate(-6.161 -6.161)"
                  fill="none"
                  stroke={props.slug === "secret-de-paris" ? "#6a4962" : props.slug === 'parisjadore' ? "#d0b13d" : "#16a5a3"}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
              </g>
            </svg>
            {printFullAddress(props.hostdata.address)}
          </HostAdress>
          <HostPhone slug={props.slug}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15px"
              height="15px"
              viewBox="0 0 9.766 9.784"
              style={{ marginRight: "10px" }}
            >
              <path
                id="Icon_feather-phone"
                data-name="Icon feather-phone"
                d="M11.933,9.575V10.9a.881.881,0,0,1-.961.881,8.722,8.722,0,0,1-3.8-1.353A8.594,8.594,0,0,1,4.524,7.782,8.722,8.722,0,0,1,3.171,3.961.881.881,0,0,1,4.048,3H5.371a.881.881,0,0,1,.881.758A5.659,5.659,0,0,0,6.561,5a.881.881,0,0,1-.2.93l-.56.56A7.051,7.051,0,0,0,8.447,9.13l.56-.56a.881.881,0,0,1,.93-.2,5.659,5.659,0,0,0,1.238.309A.881.881,0,0,1,11.933,9.575Z"
                transform="translate(-2.668 -2.5)"
                fill="none"
                stroke={props.slug === "secret-de-paris" ? "#6a4962" : props.slug === 'parisjadore' ? "#d0b13d" : "#16a5a3"}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </svg>
            {props.hostdata.genericPhone}
          </HostPhone>
          <HostEmail slug={props.slug}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15px"
              height="15px"
              viewBox="0 0 10.158 8.012"
              style={{ marginRight: "10px" }}
            >
              <g
                id="Icon_feather-mail"
                data-name="Icon feather-mail"
                transform="translate(-2.304 -5.5)"
              >
                <path
                  id="Tracé_32"
                  data-name="Tracé 32"
                  d="M3.877,6h7.012a.879.879,0,0,1,.877.877v5.259a.879.879,0,0,1-.877.877H3.877A.879.879,0,0,1,3,12.136V6.877A.879.879,0,0,1,3.877,6Z"
                  fill="none"
                  stroke={props.slug === "secret-de-paris" ? "#6a4962" : props.slug === 'parisjadore' ? "#d0b13d" : "#16a5a3"}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  id="Tracé_33"
                  data-name="Tracé 33"
                  d="M11.765,9,7.383,12.068,3,9"
                  transform="translate(0 -2.123)"
                  fill="none"
                  stroke={props.slug === "secret-de-paris" ? "#6a4962" : props.slug === 'parisjadore' ? "#d0b13d" : "#16a5a3"}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
              </g>
            </svg>
            {props.hostdata.genericEmail}
          </HostEmail>
          { props.hostdata.website !== ("https://" || "http://")  ? <HostWebsite slug={props.slug} href={props.hostdata.website}>Voir le site internet</HostWebsite> : null }
          <HostReseaux>
          {props.hostdata.instagram ? <LinkA  href={props.hostdata.instagram}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14.278"
              height="14.278"
              viewBox="0 0 14.278 14.278"
            >
              <g
                id="Icon_feather-instagram"
                data-name="Icon feather-instagram"
                transform="translate(-2.5 -2.5)"
              >
                <path
                  id="Tracé_34"
                  data-name="Tracé 34"
                  d="M6.319,3h6.639a3.319,3.319,0,0,1,3.319,3.319v6.639a3.319,3.319,0,0,1-3.319,3.319H6.319A3.319,3.319,0,0,1,3,12.958V6.319A3.319,3.319,0,0,1,6.319,3Z"
                  fill="none"
                  stroke={props.slug === "secret-de-paris" ? "#6a4962" : props.slug === 'parisjadore' ? "#d0b13d" : "#16a5a3"}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  id="Tracé_35"
                  data-name="Tracé 35"
                  d="M17.347,14.2a2.656,2.656,0,1,1-2.237-2.237A2.656,2.656,0,0,1,17.347,14.2Z"
                  transform="translate(-5.053 -4.98)"
                  fill="none"
                  stroke={props.slug === "secret-de-paris" ? "#6a4962" : props.slug === 'parisjadore' ? "#d0b13d" : "#16a5a3"}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  id="Tracé_36"
                  data-name="Tracé 36"
                  d="M26.25,9.75h0"
                  transform="translate(-12.96 -3.763)"
                  fill="none"
                  stroke={props.slug === "secret-de-paris" ? "#6a4962" : props.slug === 'parisjadore' ? "#d0b13d" : "#16a5a3"}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
              </g>
            </svg>
          </LinkA> : null }

          {props.hostdata.facebook ? <LinkA  href={props.hostdata.facebook}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8.303"
              height="14.277"
              viewBox="0 0 8.303 14.277"
            >
              <path
                id="Icon_feather-facebook"
                data-name="Icon feather-facebook"
                d="M17.8,3H15.811a3.319,3.319,0,0,0-3.319,3.319V8.311H10.5v2.655h1.992v5.311h2.655V10.966h1.992L17.8,8.311H15.147V6.319a.664.664,0,0,1,.664-.664H17.8Z"
                transform="translate(-10 -2.5)"
                fill="none"
                stroke={props.slug === "secret-de-paris" ? "#6a4962" : props.slug === 'parisjadore' ? "#d0b13d" : "#16a5a3"}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </svg>
          </LinkA> : null }  
          
          {props.hostdata.twitter ? <LinkA href={props.hostdata.twitter}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.278"
              height="14.418"
              viewBox="0 0 17.278 14.418"
            >
              <path
                id="Icon_feather-twitter"
                data-name="Icon feather-twitter"
                d="M17.778,4.492a8.065,8.065,0,0,1-2.323,1.132,3.315,3.315,0,0,0-5.816,2.22v.74A7.888,7.888,0,0,1,2.98,5.232s-2.96,6.659,3.7,9.619A8.613,8.613,0,0,1,1.5,16.331c6.659,3.7,14.8,0,14.8-8.509a3.33,3.33,0,0,0-.059-.614A5.712,5.712,0,0,0,17.778,4.492Z"
                transform="translate(-1 -3.931)"
                fill="none"
                stroke={props.slug === "secret-de-paris" ? "#6a4962" : props.slug === 'parisjadore' ? "#d0b13d" : "#16a5a3"}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </svg>
          </LinkA> : null}
          {props.hostdata.tripadvisor ? (
                      <LinkA href={props.hostdata.tripadvisor}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.635"
                          height="9.855"
                          viewBox="0 0 16.635 9.855"
                        >
                          <path
                            id="Icon_simple-tripadvisor"
                            data-name="Icon simple-tripadvisor"
                            d="M16.1,10.552a5.415,5.415,0,0,1,.814-1.675H14.133A10.118,10.118,0,0,0,8.579,7.335,10.308,10.308,0,0,0,2.886,8.9H.279a5.343,5.343,0,0,1,.811,1.654,4.162,4.162,0,0,0,6.6,5.067l.884,1.328L9.471,15.6A4.058,4.058,0,0,0,11,16.808a4.165,4.165,0,0,0,5.647-5.23,4.035,4.035,0,0,0-.561-1.029Zm-2.2,5.595a3.3,3.3,0,0,1-3.922-1.3,3.174,3.174,0,0,1-.349-.673,3.329,3.329,0,1,1,4.282,1.968H13.9ZM7.2,14.876a3.324,3.324,0,1,1,.572-1.86,3.008,3.008,0,0,1-.033.332A3.331,3.331,0,0,1,7.2,14.887Zm-4.867-1.9a2.058,2.058,0,1,0,2.061-2.054,2.062,2.062,0,0,0-2.061,2.054Zm8.351,0a2.056,2.056,0,1,0,2.057-2.054,2.058,2.058,0,0,0-2.057,2.054h0Zm-7.639,0a1.347,1.347,0,1,1-.01,0Zm8.34,0a1.346,1.346,0,1,1-.01,0ZM8.576,8.063a9.721,9.721,0,0,1,4.035.805,4.168,4.168,0,0,0-1.321.25,4.191,4.191,0,0,0-2.714,3.6A4.175,4.175,0,0,0,4.5,8.849a10.26,10.26,0,0,1,4.046-.786Z"
                            transform="translate(-0.279 -7.335)"
                            fill={
                              props.slug === "secret-de-paris"
                                ? "#6a4962"
                                : props.slug === "parisjadore"
                                ? "#d0b13d"
                                : "#16a5a3"
                            }
                          />
                        </svg>
                      </LinkA>
                    ) : null}
          </HostReseaux>
        </div>
      </HeaderHostInfos>
      <div className="col-md-4"></div>
      <HeaderHostInfos className="col-md-4" slug={props.slug}>
        <HostFigureLogo>
          <HostLogo src={props.hostdata.logo} />
        </HostFigureLogo>
        <HostDescription>{props.hostdata.description}</HostDescription>
      </HeaderHostInfos>
    </HostContainer>

    <TopCarousel hostdata={props.hostdata} />

  </HostInfosContainer>
 
 )
}
  const HostFigureCarousel = styled.figure`
  width: 100%;
  margin: 0px;
  padding: 0px;
  height: 370px;
  line-height: 370px;
  display: flex; 
  align-items : center;

  @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
    height: 467px;
    line-height : 270px;   
  }

  @media screen and (max-device-width: 554px){
    height: 180px; 
  }
`;

const HostContainer = styled.div`
  display: flex;
  width: 100%;
  z-index: 144 !important; 
  position: relative;
  left: 0; 
  right: 0; 
  top: 0;
  bottom: 0;
  margin: 0; 
  padding: 0px;  

  @media screen and (max-device-width: 1200px) {
    margin-top: 32.85px;
    width: 90%;
  }
  @media screen and (max-device-width: 554px) {
    width: 90%;
    display: grid; 
    margin-top: 12.85px;

  }
`;
const HostLogo = styled.img`
  display: flex;
  height: 100%;

  @media screen and (max-device-width: 554px) {
    width: auto; 
    height: 100%;
  }
`;

const HostFigureLogo = styled.figure`
  height: 131px;
  margin: auto;
  width: auto;
  @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
    height: 75px;   
  }
  @media screen and (max-device-width : 554px){
    height: 115px;
    width: 115px; 
    overflow: hidden; 
    padding: 0px; 
    background-color: white;
    border-radius: 50%; 
    box-shadow : 1px 1px 0.3em 0.1em grey;
    text-align: center; 
  }
`;

const HostAdress = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.slug === 'secret-de-paris' || props.slug === 'parisjadore' ? "white" : "black"};
  font-size: 14px;
  font-weight: 400;

  @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
    font-size: 12px;  
  }
  @media screen and (max-device-width:554px) {
    font-size: 11px;
  }
  @media screen and (max-device-width:350px) {
  }
`;
const HostPhone = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  color: ${props => props.slug === 'secret-de-paris' || props.slug === 'parisjadore' ? "white" : "black"};
  font-size: 14px;
  font-weight: 400;
  @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
    font-size: 12px;  
  }
  @media screen and (max-device-width:554px) {
    font-size: 11px;
  }
`;
const HostEmail = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  color:  ${props => props.slug === 'secret-de-paris' || props.slug === 'parisjadore' ? "white" : "black"};
  font-size: 14px;
  font-weight: 400;

  @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
    font-size: 12px;  
  }
  @media screen and (max-device-width:554px) {
    font-size: 11px;
  }
`;
const HostWebsite = styled.a`
  display: flex;
  text-decoration: none;
  color: ${props => props.slug === 'secret-de-paris' ? "#6a4962" : props.slug === 'parisjadore' ? "#d0b13d" : "#16a5a3"};
  font-weight: 500;
  align-items: center;
  margin-top: 15px;
  font-size: 14px;

  @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
    font-size: 12px;
  }
  @media screen and (max-device-width:554px) {
    font-size: 11px;
  }
`;
const HostReseaux = styled.div`
  display: flex;
`;

const LinkA = styled.a`
  margin: 15px; 
  color: #16a5a3 !important; 
`

const HostDescription = styled.p`
  display: flex;
  font-size: 14px;
  font: normal italic 400 14px Poppins;
  height: 17ch;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 45px 0px 45px;

  @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
    font-size: 11px;  
  }

  @media screen and (max-device-width:554px) {
    font-size: 11px;
    padding: 0px;
    width: 100%; 
    text-align: center;
    height: auto; 
    margin: auto; 
  }
`;
const HostName = styled.h4`
  display: flex;
  text-transform: uppercase;
  font-weight: 500;
  color: ${(props) => props.slug === 'secret-de-paris' || props.slug === 'parisjadore' ? "white" : "black"};
  font-size: 18px; 
  @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
    font-size: 16px;  
  }
`;


const HostInfosContainer = styled.div`
  display: block;
  height: 370px;
  width: 100%; 
  overflow: hidden; 
  margin: 0px; 
  padding: 0px; 
  margin-top: ${(props) => (props.slug === 'parisjadore' ? "0px" : props.slug === 'secret-de-paris' ? "0px" : "70px")}; 

  @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
    height: 270px;   
  }

  @media screen and (max-device-width:1200px) {
    height: 100px;
    background-color: #f7f7f7;
  }
  @media screen and (max-device-width: 554px){
    padding: 0px; 
    margin: 0; 
    height: fit-content; 
    display: none; 
  }


`;



const HeaderHostInfos = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 370px;
  padding: 15px;
  justify-content: center;
  align-items: center;

  background-color : ${(props) => (props.slug === 'secret-de-paris' || props.slug === 'parisjadore' ? "rgb(21 21 21 / 85%)" : "rgba(255,255,255,0.85)")}; 
  box-shadow : ${(props) => (props.slug === 'secret-de-paris'|| props.slug === 'parisjadore' ? "1px 1px 1em 0.2em rgb(9 9 9 / 85%)" : "1px 1px 1em 0.2em rgba(255,255,255,0.85)")}; 
  color: ${(props) => (props.slug === 'secret-de-paris' || props.slug === 'parisjadore' ? "white" : "black")};

  @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
    height: 270px;   
  }

  @media screen and (max-device-width: 554px){
    padding: 0px; 
    display: flex;
    flex-direction: row; 
    width: 100%; 
    align-items: center;
    margin: auto;

  }
`;

const svg = styled.a`
color: #16a5a3
`