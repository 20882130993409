import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ValidationButton } from '../ValidationButton';
import moment from "moment";
import { isMobile } from 'react-device-detect';
moment.locale('fr');
export const ShoppingCart = ( {cart, cartBis, setShow, deleteCartElem, setChange, shortcut} ) => {
    const [category, setCategory] = useState([]);
    const [sortCart, setSortCart] = useState();
    const [scrollTop, setScrollTop] = useState(window.scrollY);
    let totalPrice = 0;
    if (cartBis && cartBis.items) {
                cartBis.items.map((services) => {
                    if (services.selectedItem && services.selectedItem.price) {
                return totalPrice +=  (parseFloat(services.selectedItem.price) * services.quantity);
                }
                return totalPrice +=  (parseFloat(services.product.price) * services.quantity);
        })
    }

    const setNumber = (price) => {
            price = price.toString();
            price = price.replace('.', ',')
            return price
        }
    const getLabel = (service) => {
        if (service.selectedItem && service.selectedItem.label )
            return (service.selectedItem.label + (service.quantity > 1 ? ` (x${service.quantity})`: ""))
        else return (service.product.label + (service.quantity > 1 ? ` (x${service.quantity})`: ""));
    }
    const getPrice = (service) => {
        if (service.selectedItem.price && service.selectedItem.price === 0) {
            return "Gratuit"
        }
        if (service.selectedItem.price)
            return (parseFloat(service.selectedItem.price).toFixed(2) * service.quantity) + "€"
        if (!service.product.price  || service.product.price === 0)
            return "Gratuit"
        else {
            return service.product.price.toFixed(2) * service.quantity + "€"
        }
    }

    const saveCart = () => {
        ///var xmlHttp = new XMLHttpRequest();
        ///xmlHttp.open( "POST", process.env.REACT_APP_API_URL + `/shopping-cart/`, false );
        ///xmlHttp.setRequestHeader('Content-Type', 'application/json');
        ///xmlHttp.send(JSON.stringify(cartBis));
    }

    const getSlot = (date) => {
        return moment(date).format("DD/MM/YY [-] HH[H]mm")
    }
    
    const isInCat = (cat, nb) => {
        if (!category)
            return false;
        for (let i = 0; i!==category.length; i++) {
            if (cat === category[i].cat && nb === category[i].number ) {
                return true;
            }
        }
        return false
    }

    useEffect(() => {
        if (!cartBis || !cartBis.items)
            return;
        const items = cartBis.items;
        for (let i = 0; i!==items.length; i++) {
            if (!isInCat(items[i].product.categoryName, items[i].roomNumber)) {
                let tmp = category;
                if (!tmp)
                    tmp = []
                tmp.push({cat: items[i].product.categoryName,number: items[i].roomNumber})
                setCategory(tmp);
            }
        }
    }, [cartBis])

    useEffect(() => {
        if (category) {
            let tmpcart = [];
            for (let i = 0; i!==category.length; i++) {
                tmpcart.push({"cat": category[i], "data" : cartBis.items.filter(item => item.product.categoryName === category[i].cat && item.roomNumber === category[i].number)})
            }
            setSortCart(tmpcart);
        }
    }, [category, cartBis])

    
    useEffect(() => {
        const onScroll = e => {
          setScrollTop(e.target.documentElement.scrollTop);
        };
        window.addEventListener("scroll", onScroll);
    
        return () => window.removeEventListener("scroll", onScroll);
      }, [scrollTop]);
      useEffect(() => {
          if (cartBis && cartBis.items && cartBis.items.length === 0)
                setSortCart(null)

      }, [cartBis])

    return (
        <ShoppingCartContainer className='cart' onlyRoomDirectory={(shortcut === "secret-de-paris" || shortcut === "parisjadore")}>
                {
                    sortCart && sortCart.length > 0 ?
                    <Labels>
                        {isMobile ? <DeleteButton onClick={() => {setShow(false)}}> X </DeleteButton> : null }
                     <TitleBox>
                        Votre panier
                        <Icone src="Icones/Basket.png"/> 
                     </TitleBox>
                     
                     {
                        sortCart.map((servicess) => {
                                            return servicess.data.map((services, index) => {
                                                return (
                                                    <div style={{display: "flex", flexDirection: "column", marginTop:"10px"}}>
                                                    <LabelCat> {services.product && services.roomNumber !== 0 && index === 0 ? `Chambre ${services.roomNumber} (${services.product.categoryName})` : null}</LabelCat>
                                                    <LabelCat> {services.product && services.roomNumber === 0 && index === 0 ? services.product.categoryName : null}</LabelCat>
                                                    {
                                                        <Label>
                                                                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                                                    <DelButton onClick={(e) =>{if (setChange) setChange(); deleteCartElem(services.product.originalProductId, services.product.categoryName, services.roomNumber)}} src="Icones/IMG-0137.PNG" />
                                                                    {getLabel(services)}
                                                                    </div>
                                                                <div>
                                                                {
                                                                    getPrice(services)
                                                                }
                                                                </div>
                                                                
                                                                </Label>
                                                                
                                                    }
                                                    {services.date ? <Slot>{getSlot(services.date)}</Slot> : null}
                                                </div>
                                            )})
                                        })}
                    <TotalContainer>
                        <Separation />
                        <TotalBox>
                            <div>Total</div>
                            <div>
                                {
                                    setNumber(totalPrice.toFixed(2)) + " €"
                                }
                            </div>
                        </TotalBox>
                        <ValidationButton Title="Valider ma commande" ButtonSize={{width: "80%", height:"35px"}}  goTo="connexion"  cart={cartBis} save={saveCart} shortcut={shortcut} />
                    </TotalContainer>
                 </Labels>
                 : <EmptyCart>  {isMobile ? <DeleteButton onClick={() => {setShow(false)}}> X </DeleteButton> : null } Votre panier est vide<Icone src="Icones/Basket.png"/></EmptyCart>
                }
        </ShoppingCartContainer>
    );
}

const DelButton = styled.img`
    display: flex;
    width: 10px;
    height: 12px;
    margin-right: 10px;
    :hover {
        cursor: pointer;
    }
    @media screen and (max-device-width:480px){
        width: 8px;
        height: 9px;
    }
`
const DeleteButton = styled.div`
    position: absolute;
    width: 15px;
    height: 20px;
    color: #16a5a3;
    :hover {
        cursor: pointer;
    }
    border: none;
    top: 10px;
    right: 10px;
`

const Slot = styled.div`
    display: flex;
    margin-left: 15px;
    font: normal normal 200 17px/30px Poppins;
    letter-spacing: 0px;
    color: #1E2224;
    opacity: 1;
    @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
        font-size: 14px;   
    }
`
const Icone = styled.img`
    width: auto;
    height: 20px;
    padding-right: 10px;
    margin-left: 11.83px;
`;

const TotalBox = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 18.68px;
    padding-bottom: 22.32px;
    @media screen and (max-device-width: 554px){
        font: normal normal 400 14px Poppins;

    }

`
const Separation = styled.div`
    display: flex;

    width: 100%;
    height: 1px;
    background-color: #16a5a3;
`
const TotalContainer = styled.div`
    display: flex;
    width: 97%;
    flex-direction: column;
    align-items: center;
    margin-top: 19px;
    padding-bottom: 27px;
`
const LabelCat = styled.div`
    display: flex;
    font: normal normal 400 14px Poppins;
    

    @media screen and (max-device-width: 554px){
        font: normal normal 400 12px Poppins;

    }
`
const Label = styled.div`
    display: flex;
    width: 97%;
    color: black;
    font: normal normal normal 15px Poppins;
    justify-content: space-between;
    @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
        font-size: 14px;   
    }
    @media screen and (max-device-width: 554px){
        font: normal normal 400 11px Poppins;

    }
`
const Labels = styled.div `
    display: flex;
    position: sticky;
    top: 30%; 
    width: 497px;
    height: fit-content; 
    margin-top: 25.54px;
    background: white;
    color: #16a5a3;
    border: 1px solid lightgrey;
    border-radius: 18px;
    box-shadow: 4px 4px 10px #00000029;
    opacity: 1;
    font: normal normal 600 17px/26px Poppins;
    flex-direction: column;
    padding-left: 22.93px;

    @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
        font-size: 14px;   
    }
    @media (max-device-width: 768) {
        margin-top: 30%;
        width: 60%;
    }
    @media (max-width:767px) and (min-width:300px) {
        margin-top: 20%;
        width: 90%;
        max-height: 500px;
        overflow-y: scroll;
    }
`
const EmptyCart = styled.div`
    display: flex;
    position: sticky;
    top: 30%; 
    width: 497px;
    height: 105px;
    margin-top: 19px;
    align-items: center;
    background: white;
    justify-content: center;
    color: #16a5a3;
    border: 1px solid lightgrey;
    border-radius: 18px;
    box-shadow: 4px 4px 10px #00000029;
    opacity: 1;
    font: normal normal 600 17px/26px Poppins;
    @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
        font-size: 14px;    
    }
    @media (max-width:1024px) and (min-width:300px) {
        display: flex;
        position: relative;
        flex-direction: column;
        margin-top: 60px;
        width: 90%;
        height: 105px;
        border-radius: 10px;
        align-items: center;
    }
`
const ShoppingCartContainer = styled.div`
    display: ${(props) => (props.onlyRoomDirectory ? "none" : "flex")};
    flex-direction: row-reverse;
    width: 30%;
    // margin-top: ${props => props.scroll >= 610 ? 30 + (props.scroll - 627) : 0}px;
    @media (max-width: 575.98px) {
        // width: 100%; 
        display: none; 
      }

    @media (max-width:1024px) and (min-width:300px) {
        display: ${(props) => (props.onlyRoomDirectory ? "none" : "flex")};
        position: relative;
        flex-direction: column;
        width: 100vw;
        height: 100vh;
        background: #5C5C5C62 0% 0% no-repeat padding-box;
        border-radius: 10px;
        align-items: center;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); 
        margin-top: 0px;
    
        
    }
`



const TitleBox = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 25.54px;
    @media screen and (max-device-width: 554px){
        font: normal normal 400 17px Poppins;
        margin-bottom: 10px;
    }

`