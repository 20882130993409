import React, {useEffect, useState }from "react"
import styled from "styled-components"
import { ValidationButton } from "../ValidationButton";
import axios from "axios";
import UserProfile from "../UserProfile/UserProfile";
import QueryString from 'qs';
import jwt_decode from 'jwt-decode';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import {Container, Form, FormText, Button, Input, Label, FormGroup, Row, Col, Modal, ModalBody, ModalHeader} from 'reactstrap'

export const RegisterForm = ({inscriptionOpen, setInscriptionOpen, setChange, change}) => {
    const [name, setName] = useState("")
    const [firstName, setFirstName] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [mail, setMail] = useState("");
    const [password, setPassword] = useState("");
    const [verifPassword, setVerifPassord] = useState("");
    const [error, setError] = useState("");
    const [profile, setProfile] = useState();
    const [refreshToken, setRefreshToken] = useState();
    const [token, setToken] = useState();
    
    const validateToken = () => {
        const dataSend = QueryString.stringify({
            'grant_type': 'password',
            'username': mail,
            'password': password,
            'client_id' : process.env.REACT_APP_CLIENTID
        })
        var config = {
            method: 'POST',
            url: process.env.REACT_APP_AUTH_URL,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: dataSend
        };
        
        axios(config)
        .then(async function (response) {
            setProfile((jwt_decode(response.data.access_token)));
            setToken(response.data.access_token);
            setRefreshToken(response.data.refresh_token)
            setChange(!change)
        })
        .catch(function (error) {
            console.log(error);
            return false;
        });

    }

    useEffect(() => {
        if (profile && token && refreshToken) {
            UserProfile.setUser(JSON.stringify({
                'firstName': profile.given_name,
                'mail': profile.email,
                token,
                refreshToken,
                
            }))
            setInscriptionOpen(false);
        }
    }, [profile, token, refreshToken])
    
    const checkFullInfo = () => {
        if (!name || !firstName || !phone || !mail || !password || !verifPassword)
            return false;
        return true;
    }

    const checkPassword = () => {
        if (password !== verifPassword)
            return ("Mots de passe différents.")
        return (true);
    }

    const checkPhone = () => {
        return true
    }

    const checkEmail = () => {
        return String(mail)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }

    const validateRegister = () => {
        var config = {
            method: 'POST',
            url: process.env.REACT_APP_API_URL + `/customer`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                "firstname": firstName,
                "lastname": name,
                "phone": '+'+phone,
                "email" : mail,
                "address": address !== undefined ? address.label : "",
                "photo": "test",
                "password": password,
            }
        };
        axios(config)
        .then(async function (response) {
            validateToken();
        })
        .catch(function (error) {
            setError(error.response.data.message)
            document.getElementById("createAccount").firstChild.style.cursor= 'grab';
            document.getElementById("createAccount").firstChild.disabled = false;
            document.getElementById("createAccount").firstChild.style.opacity = '1';
            return false;
        });

    }
    const handleRegister = () => {
        if (!checkFullInfo()) {
            return false;
        }
        if (checkPassword() === "Mots de passe différents.") {
            setError("Mots de passe différents.")
            return false;
        }
        if (!checkEmail()) {
            setError("Votre adresse email est incorrecte")
            return false;
        }
        if (!checkPhone())
            return false;
        else {
            validateRegister();
            document.getElementById("createAccount").firstChild.style.cursor= 'not-allowed';
            document.getElementById("createAccount").firstChild.disabled = true;
            document.getElementById("createAccount").firstChild.style.opacity = '0.3';
        }
    }
    return (
        <Modal isOpen={inscriptionOpen} toggle={() => {setInscriptionOpen(!inscriptionOpen)}} centered size="lg">
            <ModalHeader toggle={() => {setInscriptionOpen(!inscriptionOpen); console.log(inscriptionOpen)}}  className={"modalStyle"}>
                Inscription
            </ModalHeader>
            <ModalBody className={"modalStyle"}>
                <Form className={"fullWidth"}>
                <Container>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label for="firstname">
                                    Prénom*
                                </Label>
                                <Input
                                    id="firstname"
                                    name="firstname"
                                    placeholder="Prénom"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label for="lastname">
                                    Nom*
                                </Label>
                                <Input
                                    id="lastname"
                                    name="lastname"
                                    placeholder="Nom"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <FormGroup>
                            <Label for="address">
                                Adresse postale
                            </Label>
                            <GooglePlacesAutocomplete 
                                id="address"
                                apiKey={process.env.REACT_APP_GOOGLE_PUBLIC_API_KEY} 
                                debounce={200} 
                                selectProps={
                                    { 
                                    address, 
                                    onChange: setAddress,
                                    placeholder: "Renseigner votre adresse"
                                    }
                                }
                            />
                            <FormText>
                                Commencer à taper votre adresse puis sélectionner la dans la liste proposée
                            </FormText>
                        </FormGroup>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label for="email">
                                    Adresse email*
                                </Label>
                                <Input
                                    id="email"
                                    name="email"
                                    placeholder="Addresse Email"
                                    value={mail}
                                    type="email"
                                    onChange={(e) => {setMail(e.target.value); setError(null)}}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                        <FormGroup>
                            <Label for="phone">
                                Téléphone*
                            </Label>
                            <PhoneInput
                                id="phone"
                                name="phone"
                                placeholder="Téléphone"
                                country={'fr'}
                                value={phone}
                                onChange={setPhone}
                                inputClass={"fullWidth"}
                            />
                        </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label for="pwd">
                                    Mot de passe*
                                </Label>
                                <Input
                                    id="pwd"
                                    name="pwd"
                                    placeholder="Mot de passe"
                                    type="password"
                                    value={password}
                                    onChange={(e) => {setPassword(e.target.value); setError(null)}}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label for="pwdVerif">
                                    Confirmer votre mot de passe*
                                </Label>
                                <Input
                                    id="pwdVerif"
                                    name="pwdVerif"
                                    placeholder="Confirmer votre mot de passe*"
                                    type="password"
                                    value={verifPassword}
                                    onChange={(e) => {setVerifPassord(e.target.value); setError(null)}}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <ErrorMessage>
                        {error ? error : null}
                    </ErrorMessage>
                </Container>
                </Form>

                <div style={{display: "flex", marginBottom: "5px", width: "100%", justifyContent: "center"}} id="createAccount">
                    <ValidationButton Title="Créer un compte" ButtonSize={{width: "50%", height:"35px"}} color="white" goTo="regi" onClick={handleRegister}/>
                </div>
            </ModalBody>
        </Modal>
    )
}
const DeleteButton = styled.div`
    position: absolute;
    width: 15px;
    height: 20px;
    color: white;
    :hover {
        cursor: pointer;
    }
    border: none;
    top: 10px;
    right: 10px;
`
   
const ErrorMessage = styled.div`
    display: flex;
    font: normal normal 600 17px/26px Poppins;
    color: red;
    margin-top: 30px;
`
const Title = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    font: normal normal 600 17px Poppins;
    letter-spacing: 1.53px;
    color: #FFFFFF;
    margin-top: 20px;
    margin-bottom: 20px;
    @media screen and (max-device-width:480px){
        margin-top: 10px;
        font: normal normal 600 14px Poppins;
    }
`

const LabelForm = styled.label`
    display: flex;
    margin-top: 20px;
    font: normal normal medium 14px Poppins;
letter-spacing: 0px;
color: #FFFFFF;
@media screen and (max-device-width:480px){
        margin-top: 10px;
    }
`

const InputPassword = styled.input`
    display: flex;
    background-color: transparent;
    border: none;
    width: 90%;
    border-bottom: ${props => props.border ? "none" : "1px solid white"};
    outline: none;
    color: white;
    text-align: left;
    resize: none;
    outline: none;
    font: italic normal 300 14px/30px Poppins;
    height: 20px;
    overflow: hidden
`;
const InputForm = styled.input`
    display: flex;
    //background-color: transparent;
    border: none;
    width: 90%;
    border-bottom: ${props => props.border ? "none" : "1px solid white"};
    outline: none;
    color: hsl(0, 0%, 50%);
    text-align: left;
    resize: none;
    outline: none;
    font: italic normal 300 12px Poppins;
    height: 25px;
    overflow: hidden;
    white-space: nowrap; 
`;
const CardForm = styled.form`
    width: 100%;
    margin-left: 20px;
    margin-top: 30px;
    @media screen and (max-device-width:480px){
        margin-top: 0px;
    }
    
`
const CardFormContainer = styled.div`
    display: flex;
    position: relative;
    width: 40%;
    height: auto;
    flex-direction: column;
    box-shadow: 4px 4px 10px #00000029;
    border-radius: 18px;
    background-color: #20A7A4;
    align-items: center;
    margin-top: -100px;
    padding: 5px; 
        padding-bottom: 10px; 
    @media screen and (max-device-width:554px){
        margin-top: 20px;
        width: 90%;
        height: auto;
        padding: 5px; 
        padding-bottom: 10px; 
    }
`

const CardFormModal = styled.div`
    position: absolute;
    display: flex;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    z-index: 99;
    background: #5C5C5C62 0% 0% no-repeat padding-box;

`