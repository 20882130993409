import React, { useState, useEffect, Fragment} from "react";
import styled from "styled-components";
import UserProfile from "../UserProfile/UserProfile";
import { TopBar } from "../TopBar";
import { CustomStay } from "../CustomStay";
import { Carousels } from "../Carousel"
import { SpinnerLoader } from "../SpinnerLoader"
import moment from "moment";
import { useParams } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

import DisplayPdf from "../DisplayPdf";
import PdfDocument from "../DisplayPdf/PdfDocument";
import { Footer } from "../Footer";
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

require("dotenv").config();

moment.locale("fr");

export const Home = () => {
 
  const [host, setHost] = useState([]);
  const [cartBis, setCartBis] = useState();
  const [user, setUser] = useState(JSON.parse(UserProfile.getUser()));
  const [itemIn, setItemIn] = useState(false);
  const { shortcut } = useParams();
  const [drawerState, setDrawerState] = useState(false);
  const [change, setChange] = useState();
  const [isOpen, setIsOpen] = useState(true);

  const makeRequest = (method, url) => {
    return new Promise(function (resolve, reject) {
        let xhr = new XMLHttpRequest();
        xhr.open(method, url);
        xhr.onload = function () {
            if (this.status >= 200 && this.status < 300) {
                resolve(xhr.response);
            } else {
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
            }
        };
        xhr.onerror = function () {
            reject({
                status: this.status,
                statusText: xhr.statusText
            });
        };
        xhr.send();
    });
  }

  const getHosterInformation = async () => {
    let result = await makeRequest("GET", process.env.REACT_APP_API_URL + `/hoster?shortcut=${shortcut}`);
    setHost(JSON.parse(result));
  }

  const deleteCartElem = (id, name, nb) => {
    // to delete cart item
    let tmp = cartBis
    for (let i = 0; i !== tmp.items.length; i++) {
      if (
        tmp.items[i].product.originalProductId === id &&
        tmp.items[i].product.categoryName === name &&
        tmp.items[i].roomNumber === nb
      ) {
        tmp.items.splice(i, 1);
        setCartBis(tmp);
        setDrawerState(!drawerState);
        if (tmp.items.length === 0) {
          setItemIn(false);
        }
        return;
      }
    }
  };

  useEffect(() => {
    // load hoster info
    try {
      getHosterInformation();
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {}, [host]);


  const getCartSize = () => {
    if (cartBis && cartBis.items && cartBis.items.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }


  return (
    <Container className="container-fluid" slug={shortcut}>
        <SpinnerLoader host={host}/>
        {shortcut === 'parisjadore' ? null : shortcut === 'secret-de-paris' ? null :
           <TopBar change={change}
          setChange={setChange}
          user={user ? user : null}
          getCartSize={getCartSize}
          deleteCartElem={deleteCartElem}
          cartBis={cartBis}
          itemIn={itemIn}
          drawerState={drawerState}/>
         }
          <Carousels hostdata={host} slug={shortcut}/>
          {shortcut !== 'parisjadore' ? 
          <Starter id="start_here"></Starter>
         : null}

        <CustomStay />
        {shortcut === 'parisjadore' ? null : shortcut === 'secret-de-paris' ? null :
        <Footer />}
    </Container>
  ) 
};

const Starter = styled.div`
  width: 0px; 
`;

const Container = styled.div`
  margin: 0px; 
  padding: 0px;  
  background-color: ${(props) => (props.slug === 'parisjadore' || props.slug === 'secret-de-paris' ? "black" : "#F7F7F7")};
`;